import styled from "styled-components";
import Waveform from '../assets/svgs/Waveform.svg';
const colorMap = {
    "customer_positive": "#ffd5b0",
    "customer_negative": "#ff485f",
    "customer_neutral": "#ffd5b0",
    "agent_positive": "#d8d8d8",
    "agent_negative": "#ff485f",
    "agent_neutral": "#d8d8d8"
};
const WavBar = styled.div`
    //height: 50px;
    background: url(${Waveform});
    background-size: cover;
    overflow: hidden;
    //  background-color: ${props => props.sentiment === "negative" ? colorMap[props.type+"_negative"] : props.sentiment === "positive" ? colorMap[props.type+"_positive"] : colorMap[props.type+"_neutral"]};
    grid-row: ${props => props.startr} / ${props => props.endr};
    grid-column: ${props => props.start} / ${props => props.end};
    objectFit: cover;
  `;
export default WavBar;