import "./../App_Speech.css";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  PieController,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  BarElement,
  Title,
} from "chart.js";
import { Bar, Line } from "react-chartjs-2";
import Paper from "@mui/material/Paper";
import "react-h5-audio-player/lib/styles.css";
import "react-circular-progressbar/dist/styles.css";
import "./Toolbar.css";
import "./loader.css";
import Axios from "axios";
import FormControl from "@mui/material/FormControl";
import moment from "moment";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import ChartDataLabels from "chartjs-plugin-datalabels";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Input from "@mui/material/Input";
import AdretTranscriptTable from "./AdretTranscriptTable";
import * as _ from "lodash";
import { Pie } from "react-chartjs-2";
import Adret1AuditTable from "./Adret1AuditTable";
import Adret1CallsTable from "./Adret1CallsTable";
import { MultiSelect } from "react-multi-select-component";
import DropDown from "../assets/svgs/arrow_drop_down.svg";
import DropUp from "../assets/svgs/arrow_drop_up.svg";
import ClearIcon from "../assets/svgs/clear.svg";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  BarElement,
  Title,
  PieController
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const languageCodeMap = {
  "en-IN": "English",
  "ta-IN": "Tamil",
  "ml-IN": "Malayalam",
  "mr-IN": "Marathi",
  "hi-IN": "Hindi",
  "te-IN": "Telugu",
  "kn-IN": "Kannada",
  "gu-IN": "Gujarati",
  // Add more language codes and names as needed
};
const langVariant = [
  { label: "English", value: "en-IN" },
  { label: "Tamil", value: "ta-IN" },
  { label: "Malayalam", value: "ml-IN" },
  { label: "Marathi", value: "mr-IN" },
  { label: "Hindi", value: "hi-IN" },
  { label: "Telugu", value: "te-IN" },
  { label: "Kannada", value: "kn-IN" },
  { label: "Gujarati", value: "gu-IN" },
];
let variants = [
  {
    id: 1,
    label: "Empty",
    value: "empty",
  },
  {
    id: 2,
    label: "Not Empty",
    value: "not empty",
  }  
];
const scores = ["None", ">", "<"];
const openAuditFiltersUrl = `${process.env.REACT_APP_BASE_URL}/adret1/openSearchAuditFilters`;
const openSearchAuditDataUrl = `${process.env.REACT_APP_BASE_URL}/adret1/getOpenAuditData`;
const openSearchCallsDataUrl = `${process.env.REACT_APP_BASE_URL}/adret1/getCallsData`;
const VOCTranscriptUrl = `${process.env.REACT_APP_BASE_URL}/adret/vocTranscriptReport`;
const vocDataURL = `${process.env.REACT_APP_BASE_URL}/adret/getVOCData`;
const vocWordCluster = `${process.env.REACT_APP_BASE_URL}/api/wordCloud`;
const vocTranscriptFilter = `${process.env.REACT_APP_BASE_URL}/adret1/vocDashBoardReportFilters`;

const axios = require("axios");
axios.defaults.headers.common["user"] = localStorage.getItem("user");
var objFilter = {};
var campaignFinalFilter = [];
var languageFinalFilter = [];
var skillFinalFilter = [];
var agentFinalFilter = {};
var dispositionFinalFilter = {};

export function logoutUser(ele) {
  localStorage.clear();
  sessionStorage.clear();
  const temp = window;
  temp.location = "/#/login";
}

const Adret1DashBoard = () => {
  // alert("check");
  const [dashboardAuditData, setDashboardAuditData] = useState({});
  const [dashboardVocData, setDashboardVocData] = useState({});
  const [wordClusterData, setWordClusterData] = useState(null);
  const [jsonOpenAuditData, setJsonOpenAuditData] = useState({});
  const [vocAggrObj, setVocAggrObj] = useState({});
  const [jsonOpenCallsData, setJsonOpenCallsData] = useState({});
  const [load, setIsload] = useState(true);
  const startDateAuditVal = String(moment().format("YYYY-MM-DD"));
  const emdDateAuditVal = String(moment().format("YYYY-MM-DD"));
  const [startDateAudit, setStartDateAudit] = useState(startDateAuditVal);
  const [endDateAudit, setEndDateAudit] = useState(emdDateAuditVal);
  const [campaignAuditVal, setCampaignAuditVal] = useState([]);
  const [dispositionAuditVal, setDispositionAuditVal] = useState([]);
  const [languageAuditVal, setLanguageAduitVal] = useState([]);
  const [skillAuditVal, setSkillAuditVal] = useState([]);
  const [scoreAuditVal, setScoreAuditVal] = useState([]);
  const [agentAuditOptions, setAgentAuditOptions] = useState([]);
  const [campaignAuditOptions, setCampaignAuditOptions] = useState([]);
  const [languageAuditOptions, setLanguageAuditOptions] = useState([]);
  const [skillAuditOptions, setSkillAuditOptions] = useState([]);
  const [dispositionAuditOptions, setDispositionAuditOptions] = useState([]);
  const [value, setValue] = useState("1");
  const [audGraphVal, setAudGraphVal] = useState("campaign");
  const [audPieVal, setAudPieVal] = useState("language");
  const [scoreInputVal, setScoreInputVal] = useState([]);
  const [checkCampaign, setCheckCampaign] = useState(true);
  const [checkLanguage, setCheckLanguage] = useState(true);
  const [scoreSelected, setScoreSelected] = useState(false);
  const [agentAuditVal, setAgentAuditVal] = useState([]);
  const [ucidAuditVal, setUcidAuditVal] = useState([]);
  const [jsonVocDataTranscript, setJsonVocDataTranscript] = useState({});
  const [vocTopicFilter, setVocTopicFilter] = useState([]);
  const [vocCallTypeFilter, setVocCallTypeFilter] = useState([]);
  const [vocCampaignFilter, setVocCampaignFilter] = useState([]);
  const [vocAgentFilter, setVocAgentFilter] = useState([]);
  const [vocSkillFilter, setVocSkillFilter] = useState([]);
  const [vocProductAcceptedFilter, setVocProductAcceptedFilter] = useState([]);
  const [vocProductRejectedFilter, setVocProductRejectedFilter] = useState([]);
  const [vocSaleMadeFilter, setVocSaleMadeFilter] = useState([]);
  const [vocDispositionFilter, setVocDispositionFilter] = useState([]);

  const [selectedTrxTopicFilter, setSelectedTrxTopicFilter] = useState([]);
  const [selectedTrxSkillFilter, setSelectedTrxSkillFilter] = useState([]);
  const [selectedTrxPrAcceptFilter, setSelectedTrxPrAcceptFilter] = useState([]);
  const [selectedTrxPrRejectFilter, setSelectedTrxPrRejectFilter] = useState([]);
  const [selectedTrxSalesMadeFilter, setSelectedTrxSalesMadeFilter] = useState([]);
  const [selectedTrxAgentFilter, setSelectedTrxAgentFilter] = useState([]);
  const [selectedTrxFeedbackFilter, setSelectedTrxFeedbackFilter] = useState([]);
  const [selectedTrxDispositionFilter, setSelectedTrxDispositionFilter] =
    useState([]);

  const [selectedTrxCallTypeFilter, setSelectedTrxCallTypeFilter] = useState(
    []
  );
  const [selectedTrxCampaignFilter, setSelectedTrxCampaignFilter] = useState(
    []
  );
  const [selectedSkillFilter, setSelectedSkillFilter] = useState([]);
  const [selectedProductAcceptedFilter, setSelectedProductAcceptedFilter] = useState([]);
  const [selectedProductRejectedFilter, setSelectedProductRejectedFilter] = useState([]);
  const [selectedSalesMadeFilter, setSelectedSalesMadeFilter] = useState([]);
  const [selectedTopicFilter, setSelectedTopicFilter] = useState([]);
  const [selectedAgentFilter, setSelectedAgentFilter] = useState([]);
  const [selectedDispositionFilter, setSelectedDispositionFilter] = useState(
    []
  );
  const [ucidVal, setUcidVal] = useState([]);
  const [selectedCampaignFilter, setSelectedCampaignFilter] = useState([]);
  const [vocFromDate, setVocFromDate] = useState(moment().format("YYYY-MM-DD"));
  const [vocToDate, setVocTODate] = useState(moment().format("YYYY-MM-DD"));

  const handleChangeAudGraph = (event) => {
    if (event.target.value !== "campaign") setCheckCampaign(false);
    else setCheckCampaign(true);
    setAudGraphVal(event.target.value);
  };
  const handleChangeAudPie = (event) => {
    if (event.target.value !== "language") setCheckLanguage(false);
    else setCheckLanguage(true);
    setAudPieVal(event.target.value);
  };
  const handleChangeDispositionAudit = (selectedOptions) => {
    const fil = handleFilters(selectedOptions);
    setDispositionAuditVal(fil);
  };
  const handleChangeLanguageAudit = (event) => {
    const fil = handleFilter(event);
    setLanguageAduitVal(fil);
  };
  const handleChangeCampaignAudit = (selectedOptions) => {
    const fil = handleFilters(selectedOptions);
    setCampaignAuditVal(fil);
  };
  const handleChangeSkillAudit = (selectedOptions) => {
    const fil = handleFilters(selectedOptions);
    setSkillAuditVal(fil);
  };
  const handleChangeScoreAudit = (event) => {
    const {
      target: { value },
    } = event;
    setScoreAuditVal(event.target.value);
    if (
      event.target.value === "<" ||
      event.target.value === ">" ||
      event.target.value === "="
    ) {
      setScoreSelected(true);
    } else {
      setScoreSelected(false);
    }
  };
  const handleChangeScoreInput = (event) => {
    setScoreInputVal(event.target.value);
  };

  const handlechangeUcidVal = (event) => {
    const regex = /^[0-9]*$/;
    if (regex.test(event.target.value) || event.target.value === "") {
      setUcidAuditVal(event.target.value);
    }
  };

  const handleChangeAgentAudit = (selectedOptions) => {
    console.log(selectedOptions);
    const fil = handleFilters(selectedOptions);
    setAgentAuditVal(fil);
  };

  const handleFilters = (selectedOptions) => {
    let map = {};
    for (let item of selectedOptions) {
      map[item.value] = item;
    }
    const duplicateRemoved = Object.values(map);
    return duplicateRemoved;
  };
  const handleFilter = (event) => {
    const {
      target: { value },
    } = event;
    let map = {};
    for (let list of value) {
      map[Object.values(list).join("")] = list;
    }
    let duplicateRemoved = [];
    value.forEach((item) => {
      if (duplicateRemoved.findIndex((o) => o.id === item.id) > 0) {
        duplicateRemoved = duplicateRemoved.filter((x) => x.id === item.id);
      } else {
        duplicateRemoved.push(item);
      }
    });
    return duplicateRemoved;
  };
  const getSearchData = (intent) => {
    let searchArray = [];
    for (const element of intent) {
      if (element.trim() !== "") {
        searchArray.push({ label: element, value: element });
        // searchArray.push({ label: element.length > 40 ? element.substr(0, 40) + "...." : element, value: element.length > 40 ? element.substr(0, 40) + "...." : element });
      }
    }
    return searchArray;
  };

  const ArrowRenderer = ({ expanded }) => (
    <>{expanded ? <img src={DropUp}></img> : <img src={DropDown}></img>}</>
  );

  const CustomClearIcon = () => (
    <div>
      <img src={ClearIcon} className="clear-icon"></img>
    </div>
  );

  const handleVocDates = (e, name) => {
    if (name === "startDate") {
      setVocFromDate(String(moment(e).format("YYYY-MM-DD")));
    } else {
      setVocTODate(String(moment(e).format("YYYY-MM-DD")));
    }
  };

  const handleVOCfilter = (e, name) => {
    if (name === "topic") {
      setSelectedTopicFilter(e);
    } else if (name === "campaign") {
      setSelectedCampaignFilter(e);
    } else if (name === "skill") {
      setSelectedSkillFilter(e);
    } else if (name === "agent") {
      setSelectedAgentFilter(e);
    } else if (name === "disposition") {
      setSelectedDispositionFilter(e);
    } else if(name === "prAccept") {
      setSelectedProductAcceptedFilter(e);
    } else if(name === "prReject") {
      setSelectedProductRejectedFilter(e);
    } else if(name === "salesMade") {
      setSelectedSalesMadeFilter(e);
    }
  };

  const handleVOCUcidFilter = (e) => {
    const regex = /^[0-9]*$/;
    if (regex.test(e.target.value) || e.target.value === "") {
      setUcidVal(e.target.value);
    }
  };

  const handleVOCTranscriptFilter = (e, name) => {
    if (name === "topic") {
      setSelectedTrxTopicFilter(e);
    } else if (name === "skill") {
      setSelectedTrxSkillFilter(e);
    } else if (name === "call-type") {
      setSelectedTrxCallTypeFilter(e);
    } else if (name === "campaign") {
      setSelectedTrxCampaignFilter(e);
    } else if (name === "agent") {
      setSelectedTrxAgentFilter(e);
    } else if (name === "disposition") {
      setSelectedTrxDispositionFilter(e);
    } else if(name === "salesMade") {
      setSelectedTrxSalesMadeFilter(e);
    } else if (name === "prFeedback") {
      setSelectedTrxFeedbackFilter(e);
    } else if (name === "prAccept"){
      setSelectedTrxPrAcceptFilter(e);
    } else if(name === "prReject"){
      setSelectedTrxPrRejectFilter(e);
    }
  };

  if (window.localStorage.getItem("user") === "adret1") {
    useEffect(() => {
      vocdownloadFilter();
    }, []);
  }

  const vocdownloadFilter = async () => {
    try {
      const filterResponse = await axios.get(vocTranscriptFilter);
      if (!_.isEmpty(filterResponse)) {
        let formatedTopics = convertToSelectBoxFormat(
          filterResponse.data.aggregations.topics
        );
        if (!_.isEmpty(formatedTopics)) {
          setVocTopicFilter(formatedTopics);
        }
        let formatedCallTypes = convertToSelectBoxFormat(
          filterResponse.data.aggregations.call_types
        );
        if (!_.isEmpty(formatedCallTypes)) {
          setVocCallTypeFilter(formatedCallTypes);
        }
        let formatedCampaigns = convertToSelectBoxFormat(
          filterResponse.data.aggregations.campaigns
        );
        if (!_.isEmpty(formatedCampaigns)) {
          setVocCampaignFilter(formatedCampaigns);
        }
        let formatedSkills = convertToSelectBoxFormat(
          filterResponse.data.aggregations.skill
        );
        if (!_.isEmpty(formatedSkills)) {
          setVocSkillFilter(formatedSkills);
        }
        let formatedAgents = convertToSelectBoxFormat(
          filterResponse.data.aggregations.agent
        );
        if (!_.isEmpty(formatedAgents)) {
          setVocAgentFilter(formatedAgents);
        }
        let formatedDispositions = convertToSelectBoxFormat(
          filterResponse.data.aggregations.disposition
        );
        if (!_.isEmpty(formatedDispositions)) {
          setVocDispositionFilter(formatedDispositions);
        }
        let formatedProductAccepts = convertToSelectBoxFormat(
          filterResponse.data.aggregations.productAccepted
        );
        if (!_.isEmpty(formatedProductAccepts)) {
          setVocProductAcceptedFilter(formatedProductAccepts);
        }
        let formatedProductRejects = convertToSelectBoxFormat(
          filterResponse.data.aggregations.productRejected
        );
        if (!_.isEmpty(formatedProductRejects)) {
          setVocProductRejectedFilter(formatedProductRejects);
        }
        let formatedSaleMade = convertToSelectBoxFormat(
          filterResponse.data.aggregations.salesMade
        );
        if (!_.isEmpty(formatedSaleMade)) {
          setVocSaleMadeFilter(formatedSaleMade);
        }
      }
    } catch (err) {
      setIsload(false);
      window.alert("Error Calling Api");
    }
  };

  const getWordCloudImg = async (sentences) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        sentences,
        width: "1150",
        height: "525",
        background_color: "white",
        // min_font_size: 20,
      }),
      redirect: "follow",
    };

    await fetch(vocWordCluster, requestOptions)
      .then((response) => response.blob())
      .then((result) => {
        console.log(result);
        setWordClusterData(URL.createObjectURL(result));
      })
      .catch((error) => {
        setIsload(false);
        window.alert("Error Calling Api");
        console.error("Error:", error);
      });
  };

  const fetchVocData = async (
    vocFromDate,
    vocToDate,
    selectedTopicFilter,
    selectedSkillFilter,
    selectedSalesMadeFilter,
    selectedProductAcceptedFilter,
    selectedProductRejectedFilter,
    selectedCampaignFilter,
    selectedAgentFilter,
    selectedDispositionFilter,
    size,
    from
  ) => {
    try {
      setIsload(!load);
      if (vocFromDate) {
        const response = await axios.get(vocDataURL, {
          params: {
            startDate: String(vocFromDate),
            endDate: String(vocToDate),
            campaignVal: selectionList(selectedCampaignFilter),
            agentVal: selectionList(selectedAgentFilter),
            dispositionVal: selectionList(selectedDispositionFilter),
            skillVal: selectionList(selectedSkillFilter),
            topicVal: selectionList(selectedTopicFilter),
            prAcceptVal: selectionList(selectedProductAcceptedFilter),
            prRejectVal: selectionList(selectedProductRejectedFilter),
            salesMadeVal: selectionList(selectedSalesMadeFilter),
            size: size ? size : 10000,
            from: from ? from : 0,
          },
        });
        if (response) {
          setVocAggrObj(
            response &&
              response.data &&
              response.data.Results &&
              response.data.Results.Aggregations &&
              response.data.Results.Aggregations
          );

          let data = [];
          response &&
            response.data &&
            response.data.Results &&
            response.data.Results.WordCloudData &&
            response.data.Results.WordCloudData.map(
              (item) =>
                item &&
                item.product_feedback &&
                !_.isEmpty(item.product_feedback) &&
                data.push(item.product_feedback)
            );
          const wordCloudSentences = data;
          if (!_.isEmpty(wordCloudSentences)) {
            getWordCloudImg(wordCloudSentences);
          }
          console.log(vocAggrObj);
          if (!_.isEmpty(vocAggrObj)) {
            setDashboardVocData(getVOCGraphData());
          }
          setIsload(false);
        }
      } else {
        const response = await axios.get(vocDataURL);
        if (response) {
          setVocAggrObj(
            response &&
              response.data &&
              response.data.Results &&
              response.data.Results.Aggregations &&
              response.data.Results.Aggregations
          );

          let data = [];
          response &&
            response.data &&
            response.data.Results &&
            response.data.Results.WordCloudData &&
            response.data.Results.WordCloudData.map(
              (item) =>
                item &&
                item.product_feedback &&
                !_.isEmpty(item.product_feedback) &&
                data.push(item.product_feedback)
            );
          const wordCloudSentences = data;
          if (!_.isEmpty(wordCloudSentences)) {
            getWordCloudImg(wordCloudSentences);
          }
          if (!_.isEmpty(vocAggrObj)) {
            setDashboardVocData(getVOCGraphData());
          }
          setIsload(false);
        }
      }
    } catch (error) {
      setIsload(false);
      console.log(error);
      window.alert("Error Calling Api");
    }
  };

  if (window.localStorage.getItem("user") === "adret1") {
    useEffect(() => {
      fetchVocTranscriptData(vocFromDate);
    }, []);
  }
  const fetchVocTranscriptData = async (
    vocFromDate,
    vocToDate,
    selectedTrxTopicFilter,
    ucidVal,
    selectedTrxSkillFilter,
    selectedTrxCallTypeFilter,
    selectedTrxCampaignFilter,
    selectedTrxAgentFilter,
    selectedTrxDispositionFilter,
    selectedTrxSalesMadeFilter,
    selectedTrxPrAcceptFilter,
    selectedTrxPrRejectFilter,
    selectedTrxFeedbackFilter,
    size,
    from
  ) => {
    try {
      setIsload(!load);
      if (vocFromDate) {
        const response = await axios.get(VOCTranscriptUrl, {
          params: {
            startDate: String(vocFromDate),
            endDate: String(vocToDate),
            topics: selectionList(selectedTrxTopicFilter),
            ucid: !_.isEmpty(ucidVal) ? String(ucidVal) : "0",
            callType: selectionList(selectedTrxCallTypeFilter),
            disposition: selectionList(selectedTrxDispositionFilter),
            campaign: selectionList(selectedTrxCampaignFilter),
            skill: selectionList(selectedTrxSkillFilter),
            agent: selectionList(selectedTrxAgentFilter),
            salesMade: selectionList(selectedTrxSalesMadeFilter),
            likes: selectionList(selectedTrxPrAcceptFilter),
            dislikes: selectionList(selectedTrxPrRejectFilter),
            productFeedback: selectionList(selectedTrxFeedbackFilter),
            size: size ? size : 20,
            from: from ? from : 0,
          },
        });
        if (response) {
          setJsonVocDataTranscript(response.data);
          setIsload(false);
        }
      } else {
        const response = await axios.get(VOCTranscriptUrl);
        if (response) {
          setJsonVocDataTranscript(response.data);
          setIsload(false);
        }
      }
    } catch (error) {
      setIsload(false);
      console.log(error);
      window.alert("Error Calling Api");
    }
  };
  if (window.localStorage.getItem("user") === "adret1") {
    useEffect(() => {}, [jsonVocDataTranscript]);
  }
  const fetchVocTranscriptDataCallBack = async (
    vocFromDate,
    vocToDate,
    selectedTrxTopicFilter,
    ucidVal,
    selectedTrxCallTypeFilter,
    selectedTrxDispositionFilter,
    selectedTrxCampaignFilter,
    selectedTrxSkillFilter,
    selectedTrxAgentFilter,
    selectedTrxSalesMadeFilter,
    selectedTrxPrAcceptFilter,
    selectedTrxPrRejectFilter,
    selectedTrxFeedbackFilter,
    size,
    from
  ) => {
    try {
      setIsload(!load);
      if (vocFromDate) {
        const response = await axios.get(VOCTranscriptUrl, {
          params: {
            startDate: String(vocFromDate),
            endDate: String(vocToDate),
            topics: selectedTrxTopicFilter,
            ucid: !_.isEmpty(ucidVal) ? String(ucidVal) : "0",
            callType: selectedTrxCallTypeFilter,
            disposition: selectedTrxDispositionFilter,
            campaign: selectedTrxCampaignFilter,
            skill: selectedTrxSkillFilter,
            agent: selectedTrxAgentFilter,
            salesMade: selectedTrxSalesMadeFilter,
            likes: selectedTrxPrAcceptFilter,
            dislikes: selectedTrxPrRejectFilter,
            productFeedback: selectedTrxFeedbackFilter,
            size: size ? size : 20,
            from: from ? from : 0,
          },
        });
        if (response) {
          setJsonVocDataTranscript(response.data);
          setIsload(false);
        }
      } else {
        const response = await axios.get(VOCTranscriptUrl);
        if (response) {
          setJsonVocDataTranscript(response.data);
          setIsload(false);
        }
      }
    } catch (error) {
      window.alert("Error Calling Api");
      setIsload(false);
      console.log(error);
    }
  };

  const convertToSelectBoxFormat = (arrayElement) => {
    if (arrayElement) {
      arrayElement.map((ele) => {
        ele.label = ele.key;
        ele.value = ele.key;
        delete ele.doc_count;
        delete ele.key;
      });
    }
    return arrayElement;
  };
  if (window.localStorage.getItem("user") === "adret1") {
    useEffect(() => {
      if (!_.isEmpty(vocAggrObj)) {
        setDashboardVocData(getVOCGraphData());
      }
    }, [vocAggrObj]);
  }
  if (window.localStorage.getItem("user") === "adret1") {
    useEffect(() => {
      fetchSobhaAuditFiltersData();
    }, []);
  }

  if (window.localStorage.getItem("user") === "adret1") {
    useEffect(() => {
      fetchOpenSearchAuditData(startDateAudit, endDateAudit);
    }, []);
  }

  if (window.localStorage.getItem("user") === "adret1") {
    useEffect(() => {
      fetchOpenSearchCallsData(startDateAudit, endDateAudit);
    }, []);
  }

  if (window.localStorage.getItem("user") === "adret1") {
    useEffect(() => {
      if (
        jsonOpenAuditData &&
        Object.keys(jsonOpenAuditData).length !== 0 &&
        jsonOpenAuditData["Hits"] !== undefined &&
        jsonOpenAuditData["Hits"] !== null &&
        jsonOpenAuditData["Hits"].length !== 0
      ) {
        setDashboardAuditData(getOpenAuditGraphData());
      } else {
        setDashboardAuditData({});
      }
    }, [jsonOpenAuditData]);
  }

  const findOpenAuditDataName = (name) => {
    //console.log(name, jsonData);
    for (const element of jsonOpenAuditData["aggregations"]) {
      if (element["name"] === name) {
        return element;
      }
    }
  };

  const findFilters = (name) => {
    for (const element of objFilter) {
      // console.log("Element filter is",element)
      if (element["name"] === name) {
        return element;
      }
    }
  };
  const findAggr = (name) => {
    for (const element of vocAggrObj) {
      // console.log("Element filter is",element)
      if (element["name"] === name) {
        return element;
      }
    }
  };
  function getLanguageNames(languageCodes) {
    return languageCodes.map(
      (code) => languageCodeMap[code] || "Unknown Language"
    );
  }

  const getOpenAuditGraphData = () => {
    const languagePieAggregation = jsonOpenAuditData["Language count"];
    let languagePieLabel = [];
    let languagePieData = [];
    if (languagePieAggregation.length !== 0) {
      languagePieLabel = getLanguageNames(languagePieAggregation[0].labels);
      languagePieData = languagePieAggregation[0].data;
    }
    const languagePieChartData = {
      labels: languagePieLabel,
      datasets: [
        {
          label: "# of calls in language",
          data: languagePieData,
          backgroundColor: [
            "#1876D1",
            "#9AEAA7",
            "#ECC1C9",
            "#FFBF69",
            "#CCD6EB",
          ], // Customize the colors
          hoverBackgroundColor: ["#1876D1", "#ECC1C9", "#FFBF69"], // Customize the hover colors
          hoverOffset: 4,
        },
      ],
    };
    const pieDispositionOptions = {
      responsive: true,
      plugins: {
        title: {
          display: true,
          position: "left",
          align: true,
          text: "Disposition Analysis",
        },
        legend: {
          symbolWidth: "4px",
          symbolHeight: "5px",
          display: true,
          labels: {
            color: "rgb(255, 99, 132)",
          },
          position: "right",
          align: true,
        },
        tooltip: {
          callbacks: {
            label: function (tooltipItem) {
              const label = tooltipItem.label;
              const value = tooltipItem.dataset.data[tooltipItem.dataIndex];
              return `${label} :: ${value}`;
            },
          },
        },
        datalabels: {
          display: true,
        },
      },
    };
    const pieChartsOptions = {
      responsive: true,
      plugins: {
        title: {
          display: true,
          position: "left",
          align: true,
          text: "Language Analysis",
        },
        legend: {
          symbolWidth: "4px",
          symbolHeight: "5px",
          display: true,
          labels: {
            color: "rgb(255, 99, 132)",
          },
          position: "right",
          align: true,
        },
        tooltip: {
          callbacks: {
            label: function (tooltipItem) {
              const label = tooltipItem.label;
              const value = tooltipItem.dataset.data[tooltipItem.dataIndex];
              return `${label} :: ${value}`;
            },
          },
        },
        datalabels: {
          display: true,
        },
      },
    };
    const dispositionPieAggregation = jsonOpenAuditData["dispostion count"];
    let dispositionPieLabel = [];
    let dispositionPieData = [];
    if (dispositionPieAggregation.length !== 0) {
      dispositionPieLabel = dispositionPieAggregation[0].labels;
      dispositionPieData = dispositionPieAggregation[0].data;
    }
    const dispositionPieChartData = {
      labels: dispositionPieLabel,
      datasets: [
        {
          label: "# of call dispositions",
          data: dispositionPieData,
          backgroundColor: [
            "#1876D1",
            "#9AEAA7",
            "#ECC1C9",
            "#FFBF69",
            "#CCD6EB",
          ], // Customize the colors
          hoverBackgroundColor: ["#1876D1", "#ECC1C9", "#FFBF69"], // Customize the hover colors
          hoverOffset: 4,
        },
      ],
    };
    const timeScoreAggregation = jsonOpenAuditData["TimeScore Histogram"];
    let timeScoreAggrLabelPer = [];
    let timeScoreAggrDataPer = [];
    let timeScoreAggregationObj = {};
    if (timeScoreAggregation.length !== 0 || !_.isEmpty(timeScoreAggregation)) {
      timeScoreAggregation[0].labels.forEach((element, index) => {
        timeScoreAggregationObj[element] = timeScoreAggregation[0].data[index];
      });
      Object.entries(timeScoreAggregationObj).forEach(([label, val]) => {
        timeScoreAggrLabelPer.push(label);
        timeScoreAggrDataPer.push(val.toFixed(1));
      });
    }

    const timeScoreData = {
      datasets: [
        {
          data: timeScoreAggrDataPer,
          backgroundColor: "#90ED7D",
          // borderColor: "#000000",
          borderWidth: 1,
          label: "Trends of Score",
          fill: false,
          lineTension: 0.2,
          borderColor: "#90ED7D",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "#90ED7D",
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "#90ED7D",
          pointHoverBorderColor: "rgba(220,220,220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
        },
      ],
      labels: timeScoreAggrLabelPer,
    };

    const timeScoreOptions = {
      plugins: {
        legend: {
          display: true,
          position: "top",
        },
        title: {
          display: true,
          text: "Trends Over Time",
        },
        scrollbar: {
          enabled: true,
        },
        tooltip: {
          callbacks: {
            label: function (tooltipItem) {
              const label = tooltipItem.label;
              const value = tooltipItem.dataset.data[tooltipItem.dataIndex];
              return `${label} :: ${value}`;
            },
          },
        },
      },
      interaction: {
        mode: "nearest",
        axis: "x",
        intersect: false,
      },
      responsiveAnimationDuration: 0, // Disables animation for better performance
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    };

    const parameterAggregation = jsonOpenAuditData["Parameter-wise Adherence"];
    let parameterAggrLabelPer = [];
    let parameterAggrDataPer = [];
    let parameterAggregationObj = {};
    if (parameterAggregation.length !== 0) {
      parameterAggregation[0].labels.forEach((element, index) => {
        parameterAggregationObj[element] = parameterAggregation[0].data[index];
      });
      Object.entries(parameterAggregationObj).forEach(([label, val]) => {
        parameterAggrLabelPer.push(label);
        parameterAggrDataPer.push(val.toFixed(1));
      });
    }
    const parameterScoreOptions = {
      plugins: {
        datalabels: {
          display: true,
          color: "black",
          formatter: function (value) {
            return value;
          },
        },
        title: {
          display: true,
          text: "Parameter-wise Adherence",
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
          ticks: {
            callback: function (value) {
              return this.getLabelForValue(value).length > 15
                ? this.getLabelForValue(value).substr(0, 15) + "..."
                : this.getLabelForValue(value);
            },
          },
        },
        y: {
          stacked: true,
        },
      },
    };

    const parameterScoreData = {
      datasets: [
        {
          data: parameterAggrDataPer,
          backgroundColor: "#90ED7D",
          label: "Parameter-wise Score Data in %",
          stack: 1,
        },
      ],
      labels: transformArray(parameterAggrLabelPer),
    };

    const scoreAggregation = jsonOpenAuditData["Score Histogram"];
    let scoreAggregationLabelPer = [];
    let scoreAggregationDataPer = [];
    let scoreAggregationAggregationObj = {};
    if (!_.isEmpty(scoreAggregation)) {
      scoreAggregation[0].labels.forEach((element, index) => {
        scoreAggregationAggregationObj[element] =
          scoreAggregation[0].data[index];
      });
      Object.entries(scoreAggregationAggregationObj).forEach(([label, val]) => {
        scoreAggregationLabelPer.push(label);
        scoreAggregationDataPer.push(val.toFixed(1));
      });
    }

    const scoreOptions = {
      plugins: {
        datalabels: {
          display: true,
          color: "black",
          formatter: function (value) {
            return value;
          },
        },
        title: {
          display: true,
          text: "Score Distribution",
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true,
        },
      },
    };

    const scoreData = {
      datasets: [
        {
          data: scoreAggregationDataPer.slice(0, 12),
          backgroundColor: "#90ED7D",
          label: "Score Data",
          stack: 1,
        },
      ],
      labels: scoreAggregationLabelPer.slice(0, 12),
    };

    const campaignScoreAggregation = findOpenAuditDataName(
      "Score By Campaign",
      jsonOpenAuditData
    );

    let campaignScoreAggregationLabelPer = [];
    let campaignScoreAggregationDataPer = [];
    let campaignScoreAggregationAggregationObj = {};
    if (!_.isEmpty(campaignScoreAggregation)) {
      campaignScoreAggregation["labels"].forEach((element, index) => {
        campaignScoreAggregationAggregationObj[element] =
          campaignScoreAggregation["data"][index];
      });
      Object.entries(campaignScoreAggregationAggregationObj).forEach(
        ([label, val]) => {
          campaignScoreAggregationLabelPer.push(label);
          campaignScoreAggregationDataPer.push(val.toFixed(1));
        }
      );
    }

    const campaignScoreOptions = {
      plugins: {
        datalabels: {
          display: true,
          color: "black",
          formatter: function (value) {
            return value;
          },
        },
        title: {
          display: true,
          text: "Campaign Score Distribution",
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
          ticks: {
            callback: function (value) {
              return this.getLabelForValue(value).length > 15
                ? this.getLabelForValue(value).substr(0, 15) + "..."
                : this.getLabelForValue(value);
            },
          },
        },
        y: {
          stacked: true,
        },
      },
    };

    const campaignScoreData = {
      datasets: [
        {
          data: campaignScoreAggregationDataPer.slice(0, 12),
          backgroundColor: "#90ED7D",
          label: "Campaign Score Data in %",
          stack: 1,
        },
      ],
      labels: campaignScoreAggregationLabelPer.slice(0, 12),
    };

    const skillScoreAggregation = findOpenAuditDataName(
      "Score By Skill",
      jsonOpenAuditData
    );

    let skillScoreAggregationLabelPer = [];
    let skillScoreAggregationDataPer = [];
    let skillScoreAggregationAggregationObj = {};
    if (!_.isEmpty(skillScoreAggregation)) {
      skillScoreAggregation["labels"].forEach((element, index) => {
        skillScoreAggregationAggregationObj[element] =
          skillScoreAggregation["data"][index];
      });
      Object.entries(skillScoreAggregationAggregationObj).forEach(
        ([label, val]) => {
          skillScoreAggregationLabelPer.push(label);
          skillScoreAggregationDataPer.push(val.toFixed(1));
        }
      );
    }

    const skillScoreOptions = {
      plugins: {
        datalabels: {
          display: true,
          color: "black",
          formatter: function (value) {
            return value;
          },
        },
        title: {
          display: true,
          text: "Skill Score Distribution",
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
          ticks: {
            callback: function (value) {
              return this.getLabelForValue(value).length > 15
                ? this.getLabelForValue(value).substr(0, 15) + "..."
                : this.getLabelForValue(value);
            },
          },
        },
        y: {
          stacked: true,
        },
      },
    };

    const skillScoreData = {
      datasets: [
        {
          data: skillScoreAggregationDataPer.slice(0, 12),
          backgroundColor: "#90ED7D",
          label: "Skill Score Data in %",
          stack: 1,
        },
      ],
      labels: skillScoreAggregationLabelPer.slice(0, 12),
    };

    const dispositionScoreAggregation = findOpenAuditDataName(
      "Score By Disposition",
      jsonOpenAuditData
    );

    let dispositionScoreAggregationLabelPer = [];
    let dispositionScoreAggregationDataPer = [];
    let dispositionScoreAggregationAggregationObj = {};
    if (!_.isEmpty(dispositionScoreAggregation)) {
      dispositionScoreAggregation["labels"].forEach((element, index) => {
        dispositionScoreAggregationAggregationObj[element] =
          dispositionScoreAggregation["data"][index];
      });
      Object.entries(dispositionScoreAggregationAggregationObj).forEach(
        ([label, val]) => {
          dispositionScoreAggregationLabelPer.push(label);
          dispositionScoreAggregationDataPer.push(val.toFixed(1));
        }
      );
    }

    const dispositionScoreOptions = {
      plugins: {
        datalabels: {
          display: true,
          color: "black",
          formatter: function (value) {
            return value;
          },
        },
        title: {
          display: true,
          text: "Disposition Score Distribution",
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
          ticks: {
            callback: function (value) {
              return this.getLabelForValue(value).length > 15
                ? this.getLabelForValue(value).substr(0, 15) + "..."
                : this.getLabelForValue(value);
            },
          },
        },
        y: {
          stacked: true,
        },
      },
    };

    const dispositionScoreData = {
      datasets: [
        {
          data: dispositionScoreAggregationDataPer.slice(0, 12),
          backgroundColor: "#90ED7D",
          label: "Disposition Score Data in %",
          stack: 1,
        },
      ],
      labels: dispositionScoreAggregationLabelPer.slice(0, 12),
    };
    //add all defined variables into one object
    return {
      scoreData: scoreData,
      scoreOptions: scoreOptions,
      campaignScoreData: campaignScoreData,
      campaignScoreOptions: campaignScoreOptions,
      skillScoreData: skillScoreData,
      skillScoreOptions: skillScoreOptions,
      dispositionScoreData: dispositionScoreData,
      dispositionScoreOptions: dispositionScoreOptions,
      languagePieChartData: languagePieChartData,
      dispositionPieChartOptions: pieDispositionOptions,
      dispositionPieChartData: dispositionPieChartData,
      pieChartsOptions: pieChartsOptions,
      parameterScoreOptions: parameterScoreOptions,
      parameterScoreData: parameterScoreData,
      timeScoreData: timeScoreData,
      timeScoreOptions: timeScoreOptions,
    };
  };

  const getVOCGraphData = () => {
    const topicAggregation = findAggr("Topics Count", vocAggrObj);
    console.log(topicAggregation);
    let topicAggregationLabelPer = [];
    let topicAggregationDataPer = [];
    if (!_.isEmpty(topicAggregation)) {
      topicAggregation["labels"].forEach((element, index) => {
        const label = element;
        const value = topicAggregation["data"][index].toFixed(1);
        topicAggregationLabelPer.push(label);
        topicAggregationDataPer.push(value);
      });
    }

    const acceptAggregation = findAggr("Acceptance Count", vocAggrObj);
    console.log(acceptAggregation);
    let acceptAggregationLabelPer = [];
    let acceptAggregationDataPer = [];
    if (!_.isEmpty(acceptAggregation)) {
      acceptAggregation["labels"].forEach((element, index) => {
        const label = element;
        const value = acceptAggregation["data"][index].toFixed(1);
        acceptAggregationLabelPer.push(label);
        acceptAggregationDataPer.push(value);
      });
    }

    const rejectAggregation = findAggr("Rejected Count", vocAggrObj);
    console.log(rejectAggregation);
    let rejectAggregationLabelPer = [];
    let rejectAggregationDataPer = [];
    if (!_.isEmpty(rejectAggregation)) {
      rejectAggregation["labels"].forEach((element, index) => {
        const label = element;
        const value = rejectAggregation["data"][index].toFixed(1);
        rejectAggregationLabelPer.push(label);
        rejectAggregationDataPer.push(value);
      });
    }

    const topicScoreOptions = {
      plugins: {
        datalabels: {
          display: true,
          color: "black",
          formatter: function (value) {
            return value;
          },
        },
        title: {
          display: true,
          text: "Topic Distribution",
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
          ticks: {
            callback: function (value) {
              return this.getLabelForValue(value).length > 15
                ? this.getLabelForValue(value).substr(0, 15) + "..."
                : this.getLabelForValue(value);
            },
          },
        },
        y: {
          stacked: true,
        },
      },
    };

    const topicScoreData = {
      datasets: [
        {
          data: topicAggregationDataPer.slice(0, 12),
          backgroundColor: "#90ED7D",
          label: "Topic Count",
          stack: 1,
        },
      ],
      labels: topicAggregationLabelPer.slice(0, 12),
    };

    const acceptScoreOptions = {
      plugins: {
        datalabels: {
          display: true,
          color: "black",
          formatter: function (value) {
            return value;
          },
        },
        title: {
          display: true,
          text: "Product Likes",
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
          ticks: {
            callback: function (value) {
              return this.getLabelForValue(value).length > 15
                ? this.getLabelForValue(value).substr(0, 15) + "..."
                : this.getLabelForValue(value);
            },
          },
        },
        y: {
          stacked: true,
        },
      },
    };

    const acceptScoreData = {
      datasets: [
        {
          data: acceptAggregationDataPer.slice(0, 12),
          backgroundColor: "#90ED7D",
          label: "Likes Count",
          stack: 1,
        },
      ],
      labels: acceptAggregationLabelPer.slice(0, 12),
    };

    const rejectScoreOptions = {
      plugins: {
        datalabels: {
          display: true,
          color: "black",
          formatter: function (value) {
            return value;
          },
        },
        title: {
          display: true,
          text: "Product Dislikes",
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
          ticks: {
            callback: function (value) {
              return this.getLabelForValue(value).length > 15
                ? this.getLabelForValue(value).substr(0, 15) + "..."
                : this.getLabelForValue(value);
            },
          },
        },
        y: {
          stacked: true,
        },
      },
    };

    const rejectScoreData = {
      datasets: [
        {
          data: rejectAggregationDataPer.slice(0, 12),
          backgroundColor: "#90ED7D",
          label: "Dislike Count",
          stack: 1,
        },
      ],
      labels: rejectAggregationLabelPer.slice(0, 12),
    };
    return {
      topicScoreOptions: topicScoreOptions,
      topicScoreData: topicScoreData,
      acceptScoreOptions: acceptScoreOptions,
      acceptScoreData: acceptScoreData,
      rejectScoreOptions: rejectScoreOptions,
      rejectScoreData: rejectScoreData,
    };
  };

  const getIntentData = (intent) => {
    let intentArray = [];
    for (const element of intent) {
      intentArray.push({ label: element, value: element });
    }
    return intentArray;
  };

  const selectionList = (arrOfObj) => {
    let selectionString = "";
    let selectionArr = [];
    if (arrOfObj !== undefined && arrOfObj.length > 0) {
      for (const ele of arrOfObj) {
        selectionArr.push(ele["value"]);
      }
      selectionString = String(selectionArr);
    } else {
      selectionString = "0";
    }
    return selectionString;
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function transformArray(arr) {
    if (arr.length !== 0) {
      return arr.map((item) => {
        // Split the string by underscores
        const words = item.split("_");
        // Capitalize the first letter of each word
        const capitalizedWords = words.map((word) => {
          return word.charAt(0).toUpperCase() + word.slice(1);
        });
        // Join the words with spaces and return the transformed string
        return capitalizedWords && capitalizedWords.join(" ");
      });
    } else {
      return [];
    }
  }

  const fetchOpenSearchAuditData = async (
    startDateAudit,
    endDateAudit,
    campaignAuditVal,
    agentAuditVal,
    dispositionAuditVal,
    languageAuditVal,
    skillAuditVal,
    scoreInputVal,
    scoreAuditVal,
    size,
    from
  ) => {
    setIsload(!load);
    let langVal =
      !_.isEmpty(languageAuditVal) && !_.isNull(languageAuditVal)
        ? replaceLanguageNames(languageAuditVal)
        : [];
    if (startDateAudit) {
      const response = await axios.get(openSearchAuditDataUrl, {
        params: {
          startDateAudit: String(startDateAudit),
          endDateAudit: String(endDateAudit),
          campaignAuditVal: selectionList(campaignAuditVal),
          agentAuditVal: selectionList(agentAuditVal),
          dispositionAuditVal: selectionList(dispositionAuditVal),
          languageAuditVal: selectionList(langVal),
          skillAuditVal: selectionList(skillAuditVal),
          scoreInputVal: scoreInputVal ? scoreInputVal : 0,
          scoreAuditVal: scoreAuditVal ? scoreAuditVal : 0,
          size: size ? size : 25,
          from: from ? from : 0,
        },
      });
      setJsonOpenAuditData(response.data);
      if (_.isEmpty(response.data)) {
        setJsonOpenAuditData({});
      }
      setIsload(false);
    } else {
      const response = await Axios(openSearchAuditDataUrl);
      setJsonOpenAuditData(response.data);
      if (_.isEmpty(response.data)) {
        setJsonOpenAuditData({});
      }
      setIsload(false);
    }
  };

  function replaceLanguageNames(inputArray) {
    // Create an output array by mapping over the input array
    if (
      _.isArray(inputArray) &&
      !_.isEmpty(inputArray) &&
      !_.isNull(inputArray) &&
      !_.isUndefined(inputArray)
    ) {
      const outputArray = inputArray
        .map((inputItem) => {
          const matchingLang = langVariant.find(
            (langItem) => langItem.label === inputItem.value
          );
          if (matchingLang) {
            return { label: matchingLang.value, value: matchingLang.value };
          } else {
            return null; // Handle cases where no match is found
          }
        })
        .filter((item) => item !== null);
      return outputArray;
    } else {
      return [];
    }
  }

  const fetchOpenSearchAuditDataCallBack = async (
    startDateAudit,
    endDateAudit,
    campaignAuditVal,
    agentAuditVal,
    dispositionAuditVal,
    languageAuditVal,
    skillAuditVal,
    scoreInputVal,
    scoreAuditVal,
    size,
    from
  ) => {
    setIsload(!load);
    let langVal =
      !_.isEmpty(languageAuditVal) &&
      !_.isNull(languageAuditVal) &&
      !_.isEqual("0")
        ? replaceLanguageNames(languageAuditVal)
        : [];
    let langSelect = selectionList(langVal);
    if (startDateAudit) {
      const response = await axios.get(openSearchAuditDataUrl, {
        params: {
          startDateAudit: startDateAudit,
          endDateAudit: endDateAudit,
          campaignAuditVal: campaignAuditVal,
          agentAuditVal: agentAuditVal,
          dispositionAuditVal: dispositionAuditVal,
          languageAuditVal: langSelect,
          skillAuditVal: skillAuditVal,
          scoreInputVal: scoreInputVal ? scoreInputVal : 0,
          scoreAuditVal: scoreAuditVal ? scoreAuditVal : 0,
          size: size ? size : 25,
          from: from ? from : 0,
        },
      });
      setJsonOpenAuditData(response.data);
      setIsload(false);
    } else {
      const response = await Axios(openSearchAuditDataUrl);
      setJsonOpenAuditData(response.data);
      setIsload(false);
    }
  };

  const fetchOpenSearchCallsData = async (
    startDateAudit,
    endDateAudit,
    campaignAuditVal,
    agentAuditVal,
    dispositionAuditVal,
    languageAuditVal,
    skillAuditVal,
    ucidAuditVal,
    scoreInputVal,
    scoreAuditVal,
    size,
    from
  ) => {
    setIsload(!load);
    setJsonOpenCallsData([]);
    let langVal =
      !_.isEmpty(languageAuditVal) && !_.isNull(languageAuditVal)
        ? replaceLanguageNames(languageAuditVal)
        : [];
    if (startDateAudit) {
      const response = await axios.get(openSearchCallsDataUrl, {
        params: {
          startDateAudit: String(startDateAudit),
          endDateAudit: String(endDateAudit),
          campaignAuditVal: selectionList(campaignAuditVal),
          agentAuditVal: selectionList(agentAuditVal),
          dispositionAuditVal: selectionList(dispositionAuditVal),
          languageAuditVal: selectionList(langVal),
          skillAuditVal: selectionList(skillAuditVal),
          ucidAuditVal: !_.isEmpty(ucidAuditVal) ? String(ucidAuditVal) : "0",
          scoreInputVal: scoreInputVal ? scoreInputVal : 0,
          scoreAuditVal: scoreAuditVal ? scoreAuditVal : 0,
          size: size ? size : 50,
          from: from ? from : 0,
        },
      });
      setJsonOpenCallsData(response.data);
      setIsload(false);
    } else {
      const response = await Axios(openSearchCallsDataUrl);
      setJsonOpenCallsData(response.data);
      setIsload(false);
    }
  };

  if (window.localStorage.getItem("user") === "adret1") {
    useEffect(() => {}, [jsonOpenCallsData]);
  }

  const fetchOpenSearchCallsDataCallBack = async (
    startDateAudit,
    endDateAudit,
    campaignAuditVal,
    agentAuditVal,
    languageAuditVal,
    dispositionAuditVal,
    skillAuditVal,
    ucidAuditVal,
    scoreInputVal,
    scoreAuditVal,
    size,
    from
  ) => {
    setIsload(!load);
    setJsonOpenCallsData([]);
    let langVal =
      !_.isEmpty(languageAuditVal) &&
      !_.isNull(languageAuditVal) &&
      !_.isEqual("0")
        ? replaceLanguageNames(languageAuditVal)
        : [];
    let langSelect = selectionList(langVal);
    if (startDateAudit) {
      const response = await axios.get(openSearchCallsDataUrl, {
        params: {
          startDateAudit: startDateAudit,
          endDateAudit: endDateAudit,
          campaignAuditVal: campaignAuditVal,
          languageAuditVal: langSelect,
          agentAuditVal: agentAuditVal,
          dispositionAuditVal: dispositionAuditVal,
          skillAuditVal: skillAuditVal,
          ucidAuditVal: !_.isEmpty(ucidAuditVal) ? String(ucidAuditVal) : "0",
          scoreInputVal: scoreInputVal ? scoreInputVal : 0,
          scoreAuditVal: scoreAuditVal ? scoreAuditVal : 0,
          size: size ? size : 50,
          from: from ? from : 0,
        },
      });
      setJsonOpenCallsData(response.data);
      setIsload(false);
    } else {
      const response = await Axios(openSearchCallsDataUrl);
      setJsonOpenCallsData(response.data);
      setIsload(false);
    }
  };

  const fetchSobhaAuditFiltersData = async () => {
    const filtersAudit = await Axios(openAuditFiltersUrl);
    // console.log(filtersAudit.data.aggregation);
    sessionStorage.setItem(
      "filtersData",
      JSON.stringify(filtersAudit.data.aggregation)
    );
    if (typeof sessionStorage.filtersData !== "undefined") {
      objFilter = JSON.parse(sessionStorage.filtersData);
    }
    const campaignFilters = findFilters("AuditCampaignCount", objFilter);
    sessionStorage.setItem(
      "campaignFilters",
      JSON.stringify(campaignFilters["labels"])
    );
    if (typeof sessionStorage.campaignFilters !== "undefined") {
      campaignFinalFilter = JSON.parse(sessionStorage.campaignFilters);
    }
    const campaignArr = getIntentData(campaignFinalFilter);
    setCampaignAuditOptions(campaignArr);

    const languageFilters = findFilters("AuditLanguageCount", objFilter);
    sessionStorage.setItem(
      "languageFilters",
      JSON.stringify(languageFilters["labels"])
    );
    if (typeof sessionStorage.languageFilters !== "undefined") {
      languageFinalFilter = JSON.parse(sessionStorage.languageFilters);
    }
    const languageNames = getLanguageNames(languageFinalFilter);
    const languageArr = getIntentData(languageNames);
    setLanguageAuditOptions(languageArr);

    const agentFilters = findFilters("AuditCallsPerAgent", objFilter);
    sessionStorage.setItem(
      "agentFilters",
      JSON.stringify(agentFilters["labels"])
    );
    if (typeof sessionStorage.agentFilters !== "undefined") {
      agentFinalFilter = JSON.parse(sessionStorage.agentFilters);
    }
    const agentArr = getIntentData(agentFinalFilter);
    setAgentAuditOptions(agentArr);

    const dispositionFilters = findFilters("AuditDispositionCount", objFilter);
    sessionStorage.setItem(
      "dispositionFilters",
      JSON.stringify(dispositionFilters["labels"])
    );
    if (typeof sessionStorage.dispositionFilters !== "undefined") {
      dispositionFinalFilter = JSON.parse(sessionStorage.dispositionFilters);
    }
    const dispositionArr = getIntentData(dispositionFinalFilter);
    setDispositionAuditOptions(dispositionArr);

    const skillFilters = findFilters("AuditSkillCount", objFilter);
    sessionStorage.setItem(
      "skillFilters",
      JSON.stringify(skillFilters["labels"])
    );
    if (typeof sessionStorage.skillFilters !== "undefined") {
      skillFinalFilter = JSON.parse(sessionStorage.skillFilters);
    }
    const skillArr = getIntentData(skillFinalFilter);
    setSkillAuditOptions(skillArr);

    return {
      agentAuditOptions: agentAuditOptions,
      campaignAuditOptions: campaignAuditOptions,
      dispositionAuditOptions: dispositionAuditOptions,
      languageOptions: languageAuditOptions,
      skillAuditOptions: skillAuditOptions,
    };
  };

  return (
    <Container maxWidth="xl">
      <Grid container spacing={2}>
        <Grid item xs={10}>
          <div className="logo-container">
            <h1>Conversational Intelligence</h1>
          </div>
        </Grid>
        <Grid item xs={2}>
          <span className="loggedInuser font-user">USER:</span>
          <span className="font-user">
            {" "}
            {window.localStorage.getItem("user")} |{" "}
          </span>
          <span className="logout-title font-user" onClick={logoutUser}>
            Logout
          </span>
        </Grid>
        <Box
          sx={{
            width: "100%",
            typography: "body1",
            pointerEvents: load ? "none" : "auto",
          }}
          className={load ? "hidden-cus" : ""}
        >
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                {window.localStorage.getItem("user") === "adret1" ? (
                  <Tab label="Audit" value="1" />
                ) : null}
                {window.localStorage.getItem("user") === "adret1" ? (
                  <Tab label="Calls" value="2" />
                ) : null}
                {window.localStorage.getItem("user") === "adret1" ? (
                  <Tab label="VOC" value="3" />
                ) : null}
                {window.localStorage.getItem("user") === "adret1" ? (
                  <Tab label="Transcripts" value="4" />
                ) : null}
              </TabList>
            </Box>

            {window.localStorage.getItem("user") !== "mindtree" &&
            window.localStorage.getItem("user") === "adret1" ? (
              <TabPanel
                value="1"
                style={{ pointerEvents: load ? "none" : "auto" }}
              >
                <Grid container item spacing={2}>
                  <div className="date-container">&nbsp;</div>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      renderInput={(props) => (
                        <TextField {...props} style={{ width: "124px" }} />
                      )}
                      disableFuture
                      hideTabs
                      showTodayButton
                      maxDate={moment().toDate()}
                      todayText="now"
                      label="Start Date"
                      value={startDateAudit}
                      onChange={(newValue) => {
                        setStartDateAudit(
                          String(moment(newValue).format("YYYY-MM-DD"))
                        );
                      }}
                    />
                  </LocalizationProvider>
                  <div></div>
                  <div className="date-container">&nbsp;</div>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      renderInput={(props) => (
                        <TextField {...props} style={{ width: "124px" }} />
                      )}
                      label="End Date"
                      value={endDateAudit}
                      disableFuture
                      hideTabs
                      showTodayButton
                      maxDate={moment().toDate()}
                      todayText="now"
                      onChange={(newValue) => {
                        setEndDateAudit(
                          String(moment(newValue).format("YYYY-MM-DD"))
                        );
                      }}
                    />
                  </LocalizationProvider>
                  <div className="date-container">&nbsp;</div>
                  <FormControl variant="outlined" style={{ width: "125px" }}>
                    <MultiSelect
                      options={campaignAuditOptions}
                      value={campaignAuditVal}
                      onChange={handleChangeCampaignAudit}
                      labelledBy="Camapaign"
                      overrideStrings={{ selectSomeItems: "Campaigns" }}
                      hasSelectAll={true}
                      searchable={true} // Enable search functionality
                      ArrowRenderer={ArrowRenderer}
                      ClearIcon={<CustomClearIcon />}
                      ClearSelectedIcon={<CustomClearIcon />}
                      // className="CI-dashboaed-filters"
                    />
                  </FormControl>
                  <div className="date-container">&nbsp;</div>
                  <FormControl variant="outlined" style={{ width: "125px" }}>
                    <MultiSelect
                      options={agentAuditOptions}
                      value={agentAuditVal}
                      onChange={handleChangeAgentAudit}
                      labelledBy="Agent"
                      hasSelectAll={true}
                      overrideStrings={{ selectSomeItems: "Agents" }}
                      searchable={true} // Enable search functionality
                      ArrowRenderer={ArrowRenderer}
                      ClearIcon={<CustomClearIcon />}
                      ClearSelectedIcon={<CustomClearIcon />}
                    />
                  </FormControl>
                  <div className="date-container">&nbsp;</div>
                  <div
                    variant="outlined"
                    style={{ width: "125px", position: "relative" }}
                  >
                    <MultiSelect
                      id="demo-multisearch-disposition"
                      options={dispositionAuditOptions}
                      value={dispositionAuditVal}
                      onChange={handleChangeDispositionAudit}
                      labelledBy="Disposition"
                      hasSelectAll={true}
                      overrideStrings={{ selectSomeItems: "Dispositions" }}
                      searchable={true} // Enable search functionality
                      ArrowRenderer={ArrowRenderer}
                      ClearIcon={<CustomClearIcon />}
                      ClearSelectedIcon={<CustomClearIcon />}
                    />
                  </div>
                  <div className="date-container">&nbsp;</div>
                  <FormControl variant="outlined" style={{ width: "125px" }}>
                    <MultiSelect
                      options={skillAuditOptions}
                      value={skillAuditVal}
                      onChange={handleChangeSkillAudit}
                      labelledBy="Skill"
                      hasSelectAll={true}
                      overrideStrings={{ selectSomeItems: "Skills" }}
                      searchable={true} // Enable search functionality
                      ArrowRenderer={ArrowRenderer}
                      ClearIcon={<CustomClearIcon />}
                      ClearSelectedIcon={<CustomClearIcon />}
                    />
                  </FormControl>
                  <div className="date-container">&nbsp;</div>
                  <FormControl style={{ width: "125px" }}>
                    <InputLabel id="demo-multiple-language">
                      Language
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-language"
                      id="demo-multiple-checkbox"
                      multiple
                      value={languageAuditVal}
                      onChange={handleChangeLanguageAudit}
                      input={<OutlinedInput label="LanguageAudit" />}
                      renderValue={(selected) =>
                        selected.map((x) => x.value).join(", ")
                      }
                      MenuProps={MenuProps}
                    >
                      {languageAuditOptions.map((m) => (
                        <MenuItem key={m.label} value={m}>
                          <Checkbox
                            checked={
                              languageAuditVal.findIndex(
                                (item) => item.label === m.label
                              ) >= 0
                            }
                          />
                          <ListItemText primary={m.value} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <div className="date-container">&nbsp;</div>
                  <FormControl style={{ width: "125px" }}>
                    <InputLabel id="demo-multiple-score">Score</InputLabel>
                    <Select
                      labelId="demo-score"
                      id="demo-checkbox"
                      value={scoreAuditVal}
                      // multiple
                      onChange={handleChangeScoreAudit}
                      input={<OutlinedInput label="Score" />}
                      renderValue={(selected) => selected}
                      MenuProps={MenuProps}
                    >
                      {scores.map((m) => (
                        <MenuItem key={m} value={m}>
                          <Checkbox checked={scoreAuditVal.indexOf(m) > -1} />
                          <ListItemText primary={m} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <div className="date-container">&nbsp;</div>
                  {scoreSelected ? (
                    <div>
                      <Box
                        component="form"
                        sx={{
                          "& > :not(style)": { m: 1, width: 98 },
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <Input
                          style={{ fontSize: "0.8rem" }}
                          placeholder="Enter Score"
                          value={scoreInputVal}
                          onChange={handleChangeScoreInput}
                        />
                      </Box>
                    </div>
                  ) : (
                    <Grid></Grid>
                  )}
                  <div className="date-container">&nbsp;</div>
                  <FormControl>
                    <Button
                      variant="contained"
                      style={{ padding: "13px 8px", fontSize: "0.8rem" }}
                      onClick={() => {
                        fetchOpenSearchAuditData(
                          startDateAudit,
                          endDateAudit,
                          campaignAuditVal,
                          agentAuditVal,
                          dispositionAuditVal,
                          languageAuditVal,
                          skillAuditVal,
                          scoreInputVal,
                          scoreAuditVal
                        );
                      }}
                    >
                      Search
                    </Button>
                  </FormControl>
                  {load ? (
                    <div className="loader_digital" id="loader_digital"></div>
                  ) : null}
                </Grid>
                <br></br>
                <div className="col-md-12 row">
                  <div className="col-md-4">
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        marginLeft: -2,
                        "& > :not(style)": {
                          m: 0.5,
                          width: 115,
                          height: 60,
                        },
                      }}
                    >
                      <Paper className="aupage">
                        <b>Total Calls</b>
                        <br></br>
                        <div className="dapage">
                          {" "}
                          {jsonOpenAuditData["Total Calls"]}
                        </div>
                      </Paper>
                      <Paper className="aupage">
                        <b>Calls above 90%</b>
                        <br></br>
                        <div className="dapage">
                          {jsonOpenAuditData["Threshold Above 90"]}
                        </div>
                      </Paper>
                      <Paper className="aupage">
                        <b>Calls below 60%</b>
                        <br></br>
                        <div className="dapage">
                          {jsonOpenAuditData["Threshold Below 60"]}
                        </div>
                      </Paper>
                      <Paper className="aupage">
                        <b>Total Agents</b>
                        <br></br>
                        <div className="dapage">
                          {" "}
                          {jsonOpenAuditData["Total Agents"]}
                        </div>
                      </Paper>
                      <Paper className="aupage">
                        <b>Avg Pre-Fatal Score</b>
                        <br></br>
                        <div className="dapage">
                          {" "}
                          {jsonOpenAuditData["Average Score"]}
                        </div>
                      </Paper>
                      <Paper className="aupage">
                        <b>Fatal Calls above 90%</b>
                        <br></br>
                        <div className="dapage">
                          {jsonOpenAuditData["Fatal Threshold Above 90"]}
                        </div>
                      </Paper>
                      <Paper className="aupage">
                        <b>Fatal Calls below 60%</b>
                        <br></br>
                        <div className="dapage">
                          {jsonOpenAuditData["Fatal Threshold Below 60"]}
                        </div>
                      </Paper>
                      <Paper className="aupage">
                        <b>Avg Fatal Score</b>
                        <br></br>
                        <div className="dapage">
                          {" "}
                          {jsonOpenAuditData["Average FatalScore"]}
                        </div>
                      </Paper>
                    </Box>
                  </div>
                  <div className="col-md-8">
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        "& > :not(style)": {
                          m: 0.5,
                          width: 245,
                          height: 120,
                        },
                      }}
                    >
                      <Paper
                        className="aupage"
                        style={{
                          marginLeft: "-28px",
                          marginTop: "5px",
                        }}
                      >
                        <b>Best Parameters</b>
                        <br></br>
                        <br></br>
                        <ul
                          style={{
                            paddingLeft: "10px",
                            textAlign: "left",
                          }}
                        >
                          {jsonOpenAuditData["Best Parameters"] &&
                            transformArray(
                              jsonOpenAuditData["Best Parameters"]
                            ).map((el, i) => (
                              <div key={i}>{el.replace(/_/g, " ")}</div>
                            ))}
                        </ul>
                      </Paper>
                      <Paper className="aupage">
                        <b>Best Agents</b>
                        <br></br>
                        <br></br>
                        <ul
                          style={{
                            paddingLeft: "10px",
                            textAlign: "left",
                          }}
                        >
                          {jsonOpenAuditData["Best Agents"] &&
                            jsonOpenAuditData["Best Agents"].map((el, i) => (
                              <div key={i}>{el}</div>
                            ))}
                        </ul>
                      </Paper>
                      <Paper className="aupage">
                        <b>Need Improvement</b>
                        <br></br>
                        <br></br>
                        <ul
                          style={{
                            paddingLeft: "10px",
                            textAlign: "left",
                          }}
                        >
                          {jsonOpenAuditData["Needs Improvement"] &&
                            jsonOpenAuditData["Needs Improvement"].map(
                              (el, i) => <div key={i}>{el}</div>
                            )}
                        </ul>
                      </Paper>
                    </Box>
                  </div>
                </div>
                <br></br>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <Box
                      sx={{
                        flexWrap: "wrap",
                        "& > :not(style)": {
                          m: 1,
                          width: 600,
                          height: 500,
                        },
                      }}
                    >
                      <Paper elevation={3} style={{ marginLeft: "-13px" }}>
                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={audGraphVal}
                            onChange={handleChangeAudGraph}
                          >
                            <FormControlLabel
                              value="campaign"
                              control={<Radio size="small" />}
                              editable="true"
                              label="Campaign"
                              checked={checkCampaign}
                            />
                            <FormControlLabel
                              value="disposition"
                              control={<Radio size="small" />}
                              label="Disposition"
                            />
                            <FormControlLabel
                              value="skill"
                              control={<Radio size="small" />}
                              label="Skill"
                            />
                            <FormControlLabel
                              value="parameter"
                              control={<Radio size="small" />}
                              label="Parameter-wise"
                            />
                          </RadioGroup>
                        </FormControl>
                        {/* </Paper> */}

                        <div style={{ paddingTop: "20px" }}>
                          {Object.keys(dashboardAuditData).length !== 0 && (
                            <Grid container item spacing={3}>
                              {audGraphVal === "disposition" ? (
                                <Grid item xs={6}>
                                  {" "}
                                  <Paper
                                    elevation={3}
                                    sx={{
                                      width: "580px",
                                      height: "400px",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    <Bar
                                      options={
                                        dashboardAuditData[
                                          "dispositionScoreOptions"
                                        ]
                                      }
                                      data={
                                        dashboardAuditData[
                                          "dispositionScoreData"
                                        ]
                                      }
                                      plugins={[ChartDataLabels]}
                                    />
                                  </Paper>
                                </Grid>
                              ) : audGraphVal === "skill" ? (
                                <Grid item xs={6}>
                                  {" "}
                                  <Paper
                                    elevation={3}
                                    sx={{
                                      width: "580px",
                                      height: "400px",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    <Bar
                                      options={
                                        dashboardAuditData["skillScoreOptions"]
                                      }
                                      data={
                                        dashboardAuditData["skillScoreData"]
                                      }
                                      plugins={[ChartDataLabels]}
                                    />
                                  </Paper>
                                </Grid>
                              ) : audGraphVal === "parameter" ? (
                                <Grid item xs={6}>
                                  {" "}
                                  <Paper
                                    elevation={3}
                                    sx={{
                                      width: "580px",
                                      height: "400px",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    <Bar
                                      options={
                                        dashboardAuditData[
                                          "parameterScoreOptions"
                                        ]
                                      }
                                      data={
                                        dashboardAuditData["parameterScoreData"]
                                      }
                                      plugins={[ChartDataLabels]}
                                    />
                                  </Paper>
                                </Grid>
                              ) : (
                                <Grid item xs={6}>
                                  {" "}
                                  <Paper
                                    elevation={4}
                                    sx={{
                                      width: "580px",
                                      height: "400px",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    <Bar
                                      options={
                                        dashboardAuditData[
                                          "campaignScoreOptions"
                                        ]
                                      }
                                      data={
                                        dashboardAuditData["campaignScoreData"]
                                      }
                                      plugins={[ChartDataLabels]}
                                    />
                                  </Paper>
                                </Grid>
                              )}
                            </Grid>
                          )}
                        </div>
                      </Paper>
                    </Box>
                  </div>
                  <br></br>
                  <div>
                    <Box
                      sx={{
                        flexWrap: "wrap",
                        "& > :not(style)": {
                          m: 1,
                          width: 600,
                          height: 500,
                        },
                      }}
                    >
                      <Paper elevation={3} style={{ marginLeft: "-13px" }}>
                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={audPieVal}
                            onChange={handleChangeAudPie}
                          >
                            <FormControlLabel
                              value="language"
                              control={<Radio size="small" />}
                              editable="true"
                              label="Language"
                              checked={checkLanguage}
                            />
                            <FormControlLabel
                              value="disposition"
                              control={<Radio size="small" />}
                              label="Disposition"
                            />
                          </RadioGroup>
                        </FormControl>

                        <div style={{ paddingTop: "25px" }}>
                          {Object.keys(dashboardAuditData).length !== 0 ? (
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                {audPieVal === "language" ? (
                                  <Grid item xs={6}>
                                    <Paper
                                      elevation={3}
                                      sx={{
                                        width: "520px",
                                        height: "400px",
                                        marginLeft: "40px",
                                      }}
                                    >
                                      <div
                                        style={{
                                          marginLeft: "30px",
                                          width: "410px",
                                        }}
                                      >
                                        <Pie
                                          options={
                                            dashboardAuditData[
                                              "pieChartsOptions"
                                            ]
                                          }
                                          data={
                                            dashboardAuditData[
                                              "languagePieChartData"
                                            ]
                                          }
                                          plugins={[ChartDataLabels]}
                                        />
                                      </div>
                                    </Paper>
                                  </Grid>
                                ) : (
                                  <Grid item xs={6}>
                                    <Paper
                                      elevation={3}
                                      sx={{
                                        width: "520px",
                                        height: "400px",
                                        marginLeft: "40px",
                                      }}
                                    >
                                      <div
                                        style={{
                                          marginLeft: "30px",
                                          width: "410px",
                                        }}
                                      >
                                        <Pie
                                          options={
                                            dashboardAuditData[
                                              "dispositionPieChartOptions"
                                            ]
                                          }
                                          data={
                                            dashboardAuditData[
                                              "dispositionPieChartData"
                                            ]
                                          }
                                          plugins={[ChartDataLabels]}
                                        />
                                      </div>
                                    </Paper>
                                  </Grid>
                                )}
                              </Grid>
                            </Grid>
                          ) : (
                            <Grid container item spacing={3}>
                              <label
                                style={{
                                  marginLeft: "30px",
                                  width: "410px",
                                }}
                              >
                                No Data For Analysis
                              </label>
                            </Grid>
                          )}
                        </div>
                      </Paper>
                    </Box>
                  </div>
                  <br></br>
                </div>
                <Box
                  sx={{
                    flexWrap: "wrap",
                    "& > :not(style)": {
                      m: 1,
                      width: 1275,
                      height: 555,
                    },
                  }}
                >
                  <Paper elevation={3} style={{ marginLeft: "-13px" }}>
                    <div style={{ paddingTop: "20px" }}>
                      {Object.keys(dashboardAuditData).length !== 0 ? (
                        <Grid container item spacing={3}>
                          <Grid item xs={6}>
                            {" "}
                            <Paper
                              elevation={3}
                              sx={{
                                width: "1240px",
                                height: "495px",
                                marginLeft: "20px",
                              }}
                            >
                              <Line
                                options={dashboardAuditData["timeScoreOptions"]}
                                data={dashboardAuditData["timeScoreData"]}
                                plugins={[ChartDataLabels]}
                              />
                            </Paper>
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid container spacing={3}>
                          <label>No Data For Time-Score Analysis</label>
                        </Grid>
                      )}
                    </div>
                  </Paper>
                </Box>
                <br></br>
                <Grid item xs={12} style={{ marginLeft: "-13px" }}>
                  <Adret1AuditTable
                    fetchAudit={[
                      jsonOpenAuditData,
                      fetchOpenSearchAuditDataCallBack,
                      {
                        startDateAudit: startDateAudit,
                        endDateAudit: endDateAudit,
                        campaignAuditVal: selectionList(campaignAuditVal),
                        agentAuditVal: selectionList(agentAuditVal),
                        dispositionAuditVal: selectionList(dispositionAuditVal),
                        languageAuditVal: selectionList(languageAuditVal),
                        skillAuditVal: selectionList(skillAuditVal),
                        scoreInputVal: scoreInputVal,
                        scoreAuditVal: scoreAuditVal,
                      },
                      languageAuditVal,
                    ]}
                  ></Adret1AuditTable>
                </Grid>
              </TabPanel>
            ) : null}

            {window.localStorage.getItem("user") !== "mindtree" &&
            window.localStorage.getItem("user") === "adret1" ? (
              <TabPanel
                value="2"
                style={{ pointerEvents: load ? "none" : "auto" }}
              >
                <Grid container item spacing={2}>
                  <div className="date-container">&nbsp;</div>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      renderInput={(props) => (
                        <TextField {...props} style={{ width: "124px" }} />
                      )}
                      disableFuture
                      hideTabs
                      showTodayButton
                      maxDate={moment().toDate()}
                      todayText="now"
                      label="Start Date"
                      value={startDateAudit}
                      onChange={(newValue) => {
                        setStartDateAudit(
                          String(moment(newValue).format("YYYY-MM-DD"))
                        );
                      }}
                    />
                  </LocalizationProvider>
                  <div></div>
                  <div className="date-container">&nbsp;</div>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      renderInput={(props) => (
                        <TextField {...props} style={{ width: "124px" }} />
                      )}
                      label="End Date"
                      value={endDateAudit}
                      disableFuture
                      hideTabs
                      showTodayButton
                      maxDate={moment().toDate()}
                      todayText="now"
                      onChange={(newValue) => {
                        setEndDateAudit(
                          String(moment(newValue).format("YYYY-MM-DD"))
                        );
                      }}
                    />
                  </LocalizationProvider>
                  <div className="date-container">&nbsp;</div>
                  <FormControl variant="outlined" style={{ width: "125px" }}>
                    <MultiSelect
                      options={campaignAuditOptions}
                      value={campaignAuditVal}
                      onChange={handleChangeCampaignAudit}
                      labelledBy="Camapaign"
                      overrideStrings={{ selectSomeItems: "Campaigns" }}
                      hasSelectAll={true}
                      searchable={true} // Enable search functionality
                      ArrowRenderer={ArrowRenderer}
                      ClearIcon={<CustomClearIcon />}
                      ClearSelectedIcon={<CustomClearIcon />}
                    />
                  </FormControl>
                  <div className="date-container">&nbsp;</div>
                  <FormControl variant="outlined" style={{ width: "125px" }}>
                    <MultiSelect
                      options={agentAuditOptions}
                      value={agentAuditVal}
                      onChange={handleChangeAgentAudit}
                      labelledBy="Agent"
                      hasSelectAll={true}
                      overrideStrings={{ selectSomeItems: "Agents" }}
                      searchable={true} // Enable search functionality
                      ArrowRenderer={ArrowRenderer}
                      ClearIcon={<CustomClearIcon />}
                      ClearSelectedIcon={<CustomClearIcon />}
                    />
                  </FormControl>
                  <div className="date-container">&nbsp;</div>
                  <div
                    variant="outlined"
                    style={{ width: "125px", position: "relative" }}
                  >
                    <MultiSelect
                      id="demo-multisearch-disposition"
                      options={dispositionAuditOptions}
                      value={dispositionAuditVal}
                      onChange={handleChangeDispositionAudit}
                      labelledBy="Disposition"
                      hasSelectAll={true}
                      overrideStrings={{ selectSomeItems: "Dispositions" }}
                      searchable={true} // Enable search functionality
                      ArrowRenderer={ArrowRenderer}
                      ClearIcon={<CustomClearIcon />}
                      ClearSelectedIcon={<CustomClearIcon />}
                    />
                  </div>
                  <div className="date-container">&nbsp;</div>
                  <FormControl variant="outlined" style={{ width: "125px" }}>
                    <MultiSelect
                      options={skillAuditOptions}
                      value={skillAuditVal}
                      onChange={handleChangeSkillAudit}
                      labelledBy="Skill"
                      hasSelectAll={true}
                      overrideStrings={{ selectSomeItems: "Skills" }}
                      searchable={true} // Enable search functionality
                      ArrowRenderer={ArrowRenderer}
                      ClearIcon={<CustomClearIcon />}
                      ClearSelectedIcon={<CustomClearIcon />}
                    />
                  </FormControl>
                  <div className="date-container">&nbsp;</div>
                  <FormControl style={{ width: "125px" }}>
                    <InputLabel id="demo-multiple-language">
                      Language
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-language"
                      id="demo-multiple-checkbox"
                      multiple
                      value={languageAuditVal}
                      onChange={handleChangeLanguageAudit}
                      input={<OutlinedInput label="LanguageAudit" />}
                      renderValue={(selected) =>
                        selected.map((x) => x.value).join(", ")
                      }
                      MenuProps={MenuProps}
                    >
                      {languageAuditOptions.map((m) => (
                        <MenuItem key={m.label} value={m}>
                          <Checkbox
                            checked={
                              languageAuditVal.findIndex(
                                (item) => item.label === m.label
                              ) >= 0
                            }
                          />
                          <ListItemText primary={m.value} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <div className="date-container">&nbsp;</div>
                  <FormControl style={{ width: "125px" }}>
                    <InputLabel id="demo-multiple-score">Score</InputLabel>
                    <Select
                      labelId="demo-score"
                      id="demo-checkbox"
                      value={scoreAuditVal}
                      // multiple
                      onChange={handleChangeScoreAudit}
                      input={<OutlinedInput label="Score" />}
                      renderValue={(selected) => selected}
                      MenuProps={MenuProps}
                    >
                      {scores.map((m) => (
                        <MenuItem key={m} value={m}>
                          <Checkbox checked={scoreAuditVal.indexOf(m) > -1} />
                          <ListItemText primary={m} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <div className="date-container">&nbsp;</div>
                  {scoreSelected ? (
                    <div>
                      <Box
                        component="form"
                        sx={{
                          "& > :not(style)": { m: 1, width: 98 },
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <Input
                          style={{ fontSize: "0.8rem" }}
                          placeholder="Enter Score"
                          value={scoreInputVal}
                          onChange={handleChangeScoreInput}
                        />
                      </Box>
                    </div>
                  ) : (
                    <Grid></Grid>
                  )}
                  <div className="date-container">
                    &nbsp;
                    <br />
                  </div>
                  <FormControl style={{ width: "125px" }}>
                    <div>
                      <Box
                        component="form"
                        sx={{
                          "& > :not(style)": { m: 1, width: 98 },
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <Input
                          style={{ fontSize: "0.8rem" }}
                          placeholder="Enter UCID"
                          value={ucidAuditVal}
                          onChange={handlechangeUcidVal}
                          inputProps={{ pattern: "^[0-9]*$" }} // Only allow numbers
                        />
                      </Box>
                    </div>
                  </FormControl>
                  <div className="date-container">&nbsp;</div>
                  <FormControl>
                    <Button
                      variant="contained"
                      style={{ padding: "13px 8px", fontSize: "0.8rem" }}
                      onClick={() => {
                        fetchOpenSearchCallsData(
                          startDateAudit,
                          endDateAudit,
                          campaignAuditVal,
                          agentAuditVal,
                          dispositionAuditVal,
                          languageAuditVal,
                          skillAuditVal,
                          ucidAuditVal,
                          scoreInputVal,
                          scoreAuditVal
                        );
                      }}
                    >
                      Search
                    </Button>
                  </FormControl>

                  {load ? (
                    <div className="loader_digital" id="loader_digital"></div>
                  ) : null}
                </Grid>
                <div className="date-container">&nbsp;</div>
                <Grid item xs={12} style={{ marginLeft: "-13px" }}>
                  <Adret1CallsTable
                    fetchCalls={[
                      jsonOpenCallsData,
                      fetchOpenSearchCallsDataCallBack,
                      {
                        startDateAudit: startDateAudit,
                        endDateAudit: endDateAudit,
                        campaignAuditVal: selectionList(campaignAuditVal),
                        agentAuditVal: selectionList(agentAuditVal),
                        languageAuditVal: selectionList(languageAuditVal),
                        dispositionAuditVal: selectionList(dispositionAuditVal),
                        skillAuditVal: selectionList(skillAuditVal),
                        ucidAuditVal: ucidAuditVal,
                        scoreInputVal: scoreInputVal,
                        scoreAuditVal: scoreAuditVal,
                      },
                    ]}
                  ></Adret1CallsTable>
                </Grid>
              </TabPanel>
            ) : null}

            {window.localStorage.getItem("user") !== "mindtree" &&
            window.localStorage.getItem("user") === "adret1" ? (
              <TabPanel
                value="3"
                style={{ pointerEvents: load ? "none" : "auto" }}
              >
                <div className="voc-filters-search-box-container">
                  <Grid container item spacing={2}>
                    {/* <Grid item xs={1.5}> */}
                    <div className="date-container">&nbsp;</div>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        renderInput={(props) => (
                          <TextField {...props} style={{ width: "124px" }} />
                        )}
                        disableFuture
                        showTodayButton
                        maxDate={moment().toDate()}
                        todayText="now"
                        label="Start Date"
                        value={vocFromDate}
                        onChange={(e) => {
                          handleVocDates(e, "startDate");
                        }}
                      />
                    </LocalizationProvider>
                    <div className="date-container">&nbsp;</div>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        renderInput={(props) => (
                          <TextField {...props} style={{ width: "124px" }} />
                        )}
                        disableFuture
                        showTodayButton
                        maxDate={moment().toDate()}
                        todayText="now"
                        label="End Date"
                        value={vocToDate}
                        onChange={(e) => {
                          handleVocDates(e, "endDate");
                        }}
                      />
                    </LocalizationProvider>
                    <div className="date-container">&nbsp;</div>
                    <FormControl variant="outlined" style={{ width: "125px" }}>
                      <MultiSelect
                        options={vocTopicFilter}
                        value={selectedTopicFilter}
                        onChange={(e) => {
                          handleVOCfilter(e, "topic");
                        }}
                        labelledBy="Topic"
                        hasSelectAll={true}
                        overrideStrings={{ selectSomeItems: "Topics" }}
                        searchable={true} // Enable search functionality
                        ArrowRenderer={ArrowRenderer}
                        ClearIcon={<CustomClearIcon />}
                        ClearSelectedIcon={<CustomClearIcon />}
                      />
                    </FormControl>
                    <div className="date-container">&nbsp;</div>
                    <FormControl variant="outlined" style={{ width: "125px" }}>
                      <MultiSelect
                        options={vocSkillFilter}
                        value={selectedSkillFilter}
                        onChange={(e) => {
                          handleVOCfilter(e, "skill");
                        }}
                        labelledBy="Skill"
                        hasSelectAll={true}
                        overrideStrings={{ selectSomeItems: "Skills" }}
                        searchable={true} // Enable search functionality
                        ArrowRenderer={ArrowRenderer}
                        ClearIcon={<CustomClearIcon />}
                        ClearSelectedIcon={<CustomClearIcon />}
                      />
                    </FormControl>
                    <div className="date-container">&nbsp;</div>
                    <FormControl variant="outlined" style={{ width: "125px" }}>
                      <MultiSelect
                        options={vocAgentFilter}
                        value={selectedAgentFilter}
                        onChange={(e) => {
                          handleVOCfilter(e, "agent");
                        }}
                        labelledBy="Agent"
                        hasSelectAll={true}
                        overrideStrings={{ selectSomeItems: "Agents" }}
                        searchable={true} // Enable search functionality
                        ArrowRenderer={ArrowRenderer}
                        ClearIcon={<CustomClearIcon />}
                        ClearSelectedIcon={<CustomClearIcon />}
                      />
                    </FormControl>
                    <div className="date-container">&nbsp;</div>
                    <FormControl variant="outlined" style={{ width: "125px" }}>
                      <MultiSelect
                        options={vocDispositionFilter}
                        value={selectedDispositionFilter}
                        onChange={(e) => {
                          handleVOCfilter(e, "disposition");
                        }}
                        labelledBy="Disposition"
                        hasSelectAll={true}
                        overrideStrings={{ selectSomeItems: "Dispositions" }}
                        searchable={true} // Enable search functionality
                        ArrowRenderer={ArrowRenderer}
                        ClearIcon={<CustomClearIcon />}
                        ClearSelectedIcon={<CustomClearIcon />}
                      />
                    </FormControl>
                    <div className="date-container">&nbsp;</div>
                    <FormControl variant="outlined" style={{ width: "125px" }}>
                      <MultiSelect
                        options={vocCampaignFilter}
                        value={selectedCampaignFilter}
                        onChange={(e) => {
                          handleVOCfilter(e, "campaign");
                        }}
                        labelledBy="Campaign"
                        hasSelectAll={true}
                        overrideStrings={{ selectSomeItems: "Campaigns" }}
                        searchable={true} // Enable search functionality
                        ArrowRenderer={ArrowRenderer}
                        ClearIcon={<CustomClearIcon />}
                        ClearSelectedIcon={<CustomClearIcon />}
                      />
                    </FormControl>
                    <div className="date-container">&nbsp;</div>
                    <FormControl variant="outlined" style={{ width: "125px" }}>
                      <MultiSelect
                        options={vocProductAcceptedFilter}
                        value={selectedProductAcceptedFilter}
                        onChange={(e) => {
                          handleVOCfilter(e, "prAccept");
                        }}
                        labelledBy="prAccept"
                        hasSelectAll={true}
                        overrideStrings={{ selectSomeItems: "Likes" }}
                        searchable={true} // Enable search functionality
                        ArrowRenderer={ArrowRenderer}
                        ClearIcon={<CustomClearIcon />}
                        ClearSelectedIcon={<CustomClearIcon />}
                      />
                    </FormControl>
                    <div className="date-container">&nbsp;</div>
                    <FormControl variant="outlined" style={{ width: "125px" }}>
                      <MultiSelect
                        options={vocProductRejectedFilter}
                        value={selectedProductRejectedFilter}
                        onChange={(e) => {
                          handleVOCfilter(e, "prReject");
                        }}
                        labelledBy="prReject"
                        hasSelectAll={true}
                        overrideStrings={{ selectSomeItems: "Dislikes" }}
                        searchable={true} // Enable search functionality
                        ArrowRenderer={ArrowRenderer}
                        ClearIcon={<CustomClearIcon />}
                        ClearSelectedIcon={<CustomClearIcon />}
                      />
                    </FormControl>
                    <div className="date-container">&nbsp;</div>
                    <FormControl variant="outlined" style={{ width: "125px" }}>
                      <MultiSelect
                        options={vocSaleMadeFilter}
                        value={selectedSalesMadeFilter}
                        onChange={(e) => {
                          handleVOCfilter(e, "salesMade");
                        }}
                        labelledBy="salesMade"
                        hasSelectAll={true}
                        overrideStrings={{ selectSomeItems: "Sales Made" }}
                        searchable={true} // Enable search functionality
                        ArrowRenderer={ArrowRenderer}
                        ClearIcon={<CustomClearIcon />}
                        ClearSelectedIcon={<CustomClearIcon />}
                      />
                    </FormControl>
                    <div className="date-container">&nbsp;</div>
                    <Button
                      variant="contained"
                      style={{ padding: "12px 10px",marginTop: "10px", width: "90px" }}
                      onClick={() => {
                        fetchVocData(
                          vocFromDate,
                          vocToDate,
                          selectedTopicFilter,
                          selectedSkillFilter,
                          selectedSalesMadeFilter,
                          selectedProductAcceptedFilter,
                          selectedProductRejectedFilter,
                          selectedCampaignFilter,
                          selectedAgentFilter,
                          selectedDispositionFilter
                        );
                      }}
                    >
                      Search
                    </Button>
                  </Grid>
                </div>
                <br></br>
                <Grid item xs={1}></Grid>
                {Object.keys(dashboardVocData).length !== 0 ? (
                  <Grid container spacing={3}>
                    <Grid container item spacing={3}>
                      <Grid item xs={12}>
                        <Paper elevation={3}>
                          <div className="voc-bar-graph">
                            <label className="voc-graphs-header-names">
                              Topic Overview Distribution
                            </label>
                            {Object.keys(dashboardVocData).length !== 0 && (
                              <Bar
                                options={dashboardVocData["topicScoreOptions"]}
                                data={dashboardVocData["topicScoreData"]}
                                plugins={[ChartDataLabels]}
                              />
                            )}
                          </div>
                        </Paper>
                      </Grid>
                    </Grid>                   
                    <Grid container item spacing={3}>                      
                      <Grid item xs={12}>
                        <Paper elevation={3}>
                          <div className="voc-bar-graph">
                            <label className="voc-graphs-header-names">
                              Distribution of Reasons to Like Products
                            </label>
                            {Object.keys(dashboardVocData).length !== 0 && (
                              <Bar
                                options={dashboardVocData["acceptScoreOptions"]}
                                data={dashboardVocData["acceptScoreData"]}
                                plugins={[ChartDataLabels]}
                              />
                            )}
                          </div>
                        </Paper>
                      </Grid>
                    </Grid>
                    <Grid container item spacing={3}>
                      <Grid item xs={12}>
                        <Paper elevation={3}>
                          <div className="voc-bar-graph">
                            <label className="voc-graphs-header-names">
                              Distribution of Reasons to Dislike Products
                            </label>
                            {Object.keys(dashboardVocData).length !== 0 && (
                              <Bar
                                options={dashboardVocData["rejectScoreOptions"]}
                                data={dashboardVocData["rejectScoreData"]}
                                plugins={[ChartDataLabels]}
                              />
                            )}
                          </div>
                        </Paper>
                      </Grid>
                    </Grid>
                    <Grid container item spacing={3}>
                      <Grid item xs={12}>
                        <div className="voc-word-cloud-container">
                          <Paper>
                            <label className="voc-graphs-header-names">
                              Word Cloud over product feedback
                            </label>
                            <img
                              src={wordClusterData}
                              className="word-cloud-properties"
                              alt="logo"
                            />
                          </Paper>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <div className="voc-no-data-found-container">
                    No Data found ...
                  </div>
                )}
              </TabPanel>
            ) : null}

            {window.localStorage.getItem("user") !== "mindtree" &&
            window.localStorage.getItem("user") === "adret1" ? (
              <TabPanel
                value="4"
                style={{ pointerEvents: load ? "none" : "auto" }}
              >
                <div className="voc-filters-search-box-container">
                  <Grid container item spacing={2}>
                    {/* <Grid item xs={1.5}> */}
                    <div className="date-container">&nbsp;</div>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        renderInput={(props) => (
                          <TextField {...props} style={{ width: "124px" }} />
                        )}
                        disableFuture
                        showTodayButton
                        maxDate={moment().toDate()}
                        todayText="now"
                        label="Start Date"
                        value={vocFromDate}
                        onChange={(e) => {
                          handleVocDates(e, "startDate");
                        }}
                      />
                    </LocalizationProvider>
                    <div className="date-container">&nbsp;</div>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        renderInput={(props) => (
                          <TextField {...props} style={{ width: "124px" }} />
                        )}
                        disableFuture
                        showTodayButton
                        maxDate={moment().toDate()}
                        todayText="now"
                        label="End Date"
                        value={vocToDate}
                        onChange={(e) => {
                          handleVocDates(e, "endDate");
                        }}
                      />
                    </LocalizationProvider>
                    <div className="date-container">&nbsp;</div>
                    <FormControl variant="outlined" style={{ width: "125px" }}>
                      <MultiSelect
                        options={vocTopicFilter}
                        value={selectedTrxTopicFilter}
                        onChange={(e) => {
                          handleVOCTranscriptFilter(e, "topic");
                        }}
                        labelledBy="Topic"
                        hasSelectAll={true}
                        overrideStrings={{ selectSomeItems: "Topics" }}
                        searchable={true} // Enable search functionality
                        ArrowRenderer={ArrowRenderer}
                        ClearIcon={<CustomClearIcon />}
                        ClearSelectedIcon={<CustomClearIcon />}
                      />
                    </FormControl>
                    <div className="date-container">&nbsp;</div>
                    <FormControl variant="outlined" style={{ width: "125px" }}>
                      <MultiSelect
                        options={vocCallTypeFilter}
                        value={selectedTrxCallTypeFilter}
                        onChange={(e) => {
                          handleVOCTranscriptFilter(e, "call-type");
                        }}
                        labelledBy="CallType"
                        hasSelectAll={true}
                        overrideStrings={{ selectSomeItems: "CallType" }}
                        searchable={true} // Enable search functionality
                        ArrowRenderer={ArrowRenderer}
                        ClearIcon={<CustomClearIcon />}
                        ClearSelectedIcon={<CustomClearIcon />}
                      />
                    </FormControl>
                    <div className="date-container">&nbsp;</div>
                    <FormControl variant="outlined" style={{ width: "125px" }}>
                      <MultiSelect
                        options={vocSkillFilter}
                        value={selectedTrxSkillFilter}
                        onChange={(e) => {
                          handleVOCTranscriptFilter(e, "skill");
                        }}
                        labelledBy="Skill"
                        hasSelectAll={true}
                        overrideStrings={{ selectSomeItems: "Skills" }}
                        searchable={true} // Enable search functionality
                        ArrowRenderer={ArrowRenderer}
                        ClearIcon={<CustomClearIcon />}
                        ClearSelectedIcon={<CustomClearIcon />}
                      />
                    </FormControl>
                    <div className="date-container">&nbsp;</div>
                    <FormControl variant="outlined" style={{ width: "125px" }}>
                      <MultiSelect
                        options={vocDispositionFilter}
                        value={selectedTrxDispositionFilter}
                        onChange={(e) => {
                          handleVOCTranscriptFilter(e, "disposition");
                        }}
                        labelledBy="Disposition"
                        hasSelectAll={true}
                        overrideStrings={{ selectSomeItems: "Dispositions" }}
                        searchable={true} // Enable search functionality
                        ArrowRenderer={ArrowRenderer}
                        ClearIcon={<CustomClearIcon />}
                        ClearSelectedIcon={<CustomClearIcon />}
                      />
                    </FormControl>
                    <div className="date-container">&nbsp;</div>
                    <FormControl variant="outlined" style={{ width: "125px" }}>
                      <MultiSelect
                        options={vocAgentFilter}
                        value={selectedTrxAgentFilter}
                        onChange={(e) => {
                          handleVOCTranscriptFilter(e, "agent");
                        }}
                        labelledBy="Agent"
                        hasSelectAll={true}
                        overrideStrings={{ selectSomeItems: "Agents" }}
                        searchable={true} // Enable search functionality
                        ArrowRenderer={ArrowRenderer}
                        ClearIcon={<CustomClearIcon />}
                        ClearSelectedIcon={<CustomClearIcon />}
                      />
                    </FormControl>
                    <div className="date-container">&nbsp;</div>
                    <FormControl variant="outlined" style={{ width: "125px" }}>
                      <MultiSelect
                        options={vocCampaignFilter}
                        value={selectedTrxCampaignFilter}
                        onChange={(e) => {
                          handleVOCTranscriptFilter(e, "campaign");
                        }}
                        labelledBy="Campaign"
                        hasSelectAll={true}
                        overrideStrings={{ selectSomeItems: "Campaigns" }}
                        searchable={true} // Enable search functionality
                        ArrowRenderer={ArrowRenderer}
                        ClearIcon={<CustomClearIcon />}
                        ClearSelectedIcon={<CustomClearIcon />}
                      />
                    </FormControl>
                    <div className="date-container">&nbsp;</div>
                    <FormControl variant="outlined" style={{ width: "125px" }}>
                      <MultiSelect
                        options={vocSaleMadeFilter}
                        value={selectedTrxSalesMadeFilter}
                        onChange={(e) => {
                          handleVOCTranscriptFilter(e, "salesMade");
                        }}
                        labelledBy="sales"
                        hasSelectAll={true}
                        overrideStrings={{ selectSomeItems: "Sales Made" }}
                        searchable={true} // Enable search functionality
                        ArrowRenderer={ArrowRenderer}
                        ClearIcon={<CustomClearIcon />}
                        ClearSelectedIcon={<CustomClearIcon />}
                      />
                    </FormControl>
                    <div className="date-container">&nbsp;</div>
                    <FormControl variant="outlined" style={{ width: "125px" }}>
                      <MultiSelect
                        options={vocProductAcceptedFilter}
                        value={selectedTrxPrAcceptFilter}
                        onChange={(e) => {
                          handleVOCTranscriptFilter(e, "prAccept");
                        }}
                        labelledBy="prAccept"
                        hasSelectAll={true}
                        overrideStrings={{ selectSomeItems: "Likes" }}
                        searchable={true} // Enable search functionality
                        ArrowRenderer={ArrowRenderer}
                        ClearIcon={<CustomClearIcon />}
                        ClearSelectedIcon={<CustomClearIcon />}
                      />
                    </FormControl>
                    <div className="date-container">&nbsp;</div>
                    <FormControl variant="outlined" style={{ width: "125px",marginTop: "10px" }}>
                      <MultiSelect
                        options={vocProductRejectedFilter}
                        value={selectedTrxPrRejectFilter}
                        onChange={(e) => {
                          handleVOCTranscriptFilter(e, "prReject");
                        }}
                        labelledBy="prReject"
                        hasSelectAll={true}
                        overrideStrings={{ selectSomeItems: "Dislikes" }}
                        searchable={true} // Enable search functionality
                        ArrowRenderer={ArrowRenderer}
                        ClearIcon={<CustomClearIcon />}
                        ClearSelectedIcon={<CustomClearIcon />}
                      />
                    </FormControl>
                    <div className="date-container">&nbsp;</div>
                    <FormControl variant="outlined" style={{ width: "125px",marginTop: "10px"}}>
                      <MultiSelect
                        options={variants}
                        value={selectedTrxFeedbackFilter}
                        onChange={(e) => {
                          handleVOCTranscriptFilter(e, "prFeedback");
                        }}
                        labelledBy="feedback"
                        hasSelectAll={true}
                        overrideStrings={{ selectSomeItems: "Product Feedback" }}
                        searchable={true} // Enable search functionality
                        ArrowRenderer={ArrowRenderer}
                        ClearIcon={<CustomClearIcon />}
                        ClearSelectedIcon={<CustomClearIcon />}
                      />
                    </FormControl>
                    <div className="date-container">&nbsp;</div>
                    <FormControl style={{ width: "125px",marginTop: "10px" }}>
                      <div>
                        <Box
                          component="form"
                          sx={{
                            "& > :not(style)": { m: 1, width: 98 },
                          }}
                          noValidate
                          autoComplete="off"
                        >
                          <Input
                            style={{ fontSize: "0.8rem" }}
                            placeholder="Enter UCID"
                            value={ucidVal}
                            onChange={handleVOCUcidFilter}
                            inputProps={{ pattern: "^[0-9]*$" }} // Only allow numbers
                          />
                        </Box>
                      </div>
                    </FormControl>
                    <div className="date-container">&nbsp;</div>
                    <FormControl>
                      <Button
                        variant="contained"
                        style={{ padding: "12px 10px",marginTop: "10px", width: "90px" }}
                        onClick={() => {
                          fetchVocTranscriptData(
                            vocFromDate,
                            vocToDate,
                            selectedTrxTopicFilter,
                            ucidVal,
                            selectedTrxSkillFilter,
                            selectedTrxCallTypeFilter,
                            selectedTrxCampaignFilter,
                            selectedTrxAgentFilter,
                            selectedTrxDispositionFilter,
                            selectedTrxSalesMadeFilter,
                            selectedTrxPrAcceptFilter,
                            selectedTrxPrRejectFilter,
                            selectedTrxFeedbackFilter,
                          );
                        }}
                      >
                        Search
                      </Button>
                    </FormControl>
                    {load ? (
                      <div className="loader_digital" id="loader_digital"></div>
                    ) : null}
                    <Grid item xs={12}>
                      <AdretTranscriptTable
                        fetchVocTranscriptData={jsonVocDataTranscript}
                        fetchVOCTranscriptFunction={
                          fetchVocTranscriptDataCallBack
                        }
                        fetchData={{
                          fetchVocTranscriptDataCallBack,
                          startDate: String(vocFromDate),
                          endDate: String(vocToDate),
                          topics: selectionList(selectedTrxTopicFilter),
                          ucid: !_.isEmpty(ucidVal) ? String(ucidVal) : "0",
                          callType: selectionList(selectedTrxCallTypeFilter),
                          disposition: selectionList(
                            selectedTrxDispositionFilter
                          ),
                          campaign: selectionList(selectedTrxCampaignFilter),
                          skill: selectionList(selectedTrxSkillFilter),
                          agent: selectionList(selectedTrxAgentFilter),
                          salesMade: selectionList(selectedTrxSalesMadeFilter),
                          likes: selectionList(selectedTrxPrAcceptFilter),
                          dislikes: selectionList(selectedTrxPrRejectFilter),
                          productFeedback: selectionList(selectedTrxFeedbackFilter),
                        }}
                      ></AdretTranscriptTable>
                    </Grid>
                  </Grid>
                </div>
              </TabPanel>
            ) : null}
          </TabContext>
        </Box>
      </Grid>
    </Container>
  );
};
export default Adret1DashBoard;
