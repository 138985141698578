import "./../App_Speech.css";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  PieController,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  BarElement,
  Title,
} from "chart.js";
import { Bar, Line } from "react-chartjs-2";
import Paper from "@mui/material/Paper";
import "react-h5-audio-player/lib/styles.css";
import "react-circular-progressbar/dist/styles.css";
import "./Toolbar.css";
import "./loader.css";
import Axios from "axios";
import FormControl from "@mui/material/FormControl";
import moment from "moment";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import ChartDataLabels from "chartjs-plugin-datalabels";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import * as _ from "lodash";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  BarElement,
  Title,
  PieController
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const openConversationFilterUrl =
  process.env.REACT_APP_BASE_URL + "/allegiant/getAllegiantFiltersData";

const openConversationDataUrl =
  process.env.REACT_APP_BASE_URL + "/allegiant/getOpenConversationData";

const axios = require("axios");
axios.defaults.headers.common["user"] = localStorage.getItem("user");
var objFilter = {};
var campaignFinalFilter = [];
var skillFinalFilter = [];
var agentFinalFilter = {};
var dispositionFinalFilter = {};

export function logoutUser(ele) {
  localStorage.clear();
  sessionStorage.clear();
  const temp = window;
  temp.location = "/#/login";
}

function SpeechAnalyticsNonVOC() {
  const [dashboardAuditData, setDashboardAuditData] = useState({});
  const [jsonOpenAuditData, setJsonOpenAuditData] = useState({});
  const [load, setIsload] = useState(true);
  const startDateAuditVal = String(moment().format("YYYY-MM-DD"));
  const emdDateAuditVal = String(moment().format("YYYY-MM-DD"));
  const [startDateAudit, setStartDateAudit] = useState(startDateAuditVal);
  const [endDateAudit, setEndDateAudit] = useState(emdDateAuditVal);
  const [campaignAuditVal, setCampaignAuditVal] = React.useState([]);
  const [dispositionAuditVal, setDispositionAuditVal] = React.useState([]);
  const [skillAuditVal, setSkillAuditVal] = React.useState([]);
  const [agentAuditOptions, setAgentAuditOptions] = React.useState([]);
  const [campaignAuditOptions, setCampaignAuditOptions] = React.useState([]);
  const [dispositionAuditOptions, setDispositionAuditOptions] = React.useState(
    []
  );
  const [skillOptions, setSkillOptions] = React.useState([]);
  const [value, setValue] = useState("1");
  const [audGraphVal, setAudGraphVal] = React.useState("campaign");
  const [audBarVal, setAudBarVal] = React.useState("campaign");
  const [audStackVal, setAudStackVal] = React.useState(
    "Participation-Ratio By Campaign"
  );
  const [audStack2Val, setAudStack2Val] = React.useState(
    "Interrupts By Campaign"
  );
  const [checkCampaign, setCheckCampaign] = React.useState(true);
  const [checkParticipation, setCheckParticipation] = React.useState(true);
  const [checkInterrupt, setCheckInterrupt] = React.useState(true);
  const [checkBCampaign, setBCheckCampaign] = React.useState(true);
  const [agentAuditVal, setAgentAuditVal] = React.useState([]);

  const handleChangeAudGraph = (event) => {
    if (event.target.value !== "campaign") setCheckCampaign(false);
    else setCheckCampaign(true);
    setAudGraphVal(event.target.value);
  };
  const handleChangeAudStackGraph = (event) => {
    if (event.target.value !== "Participation-Ratio By Campaign")
      setCheckParticipation(false);
    else setCheckParticipation(true);
    setAudStackVal(event.target.value);
  };
  const handleChangeAudStack2Graph = (event) => {
    if (event.target.value !== "Interrupts By Campaign")
      setCheckInterrupt(false);
    else setCheckInterrupt(true);
    setAudStack2Val(event.target.value);
  };
  const handleChangeAudBar = (event) => {
    if (event.target.value !== "campaign") setBCheckCampaign(false);
    else setBCheckCampaign(true);
    setAudBarVal(event.target.value);
  };
  const handleChangeDispositionAudit = (event) => {
    const fil = handleFilters(event);
    setDispositionAuditVal(fil);
  };
  const handleChangeSkillAudit = (event) => {
    const fil = handleFilters(event);
    setSkillAuditVal(fil);
  };
  const handleChangeCampaignAudit = (event) => {
    const fil = handleFilters(event);
    setCampaignAuditVal(fil);
  };
  const handleChangeAgentAudit = (event) => {
    const fil = handleFilters(event);
    setAgentAuditVal(fil);
  };

  const handleFilters = (event) => {
    //handle filters here. On change we have to update the graphs. Call the API again and get new data.
    // This is better than using Reactive componenets as we have to send the query for each component separately.
    const {
      target: { value },
    } = event;

    let map = {};

    for (let list of value) {
      map[Object.values(list).join("")] = list;
    }

    let duplicateRemoved = [];
    value.forEach((item) => {
      if (duplicateRemoved.findIndex((o) => o.id === item.id) > 0) {
        duplicateRemoved = duplicateRemoved.filter((x) => x.id === item.id);
      } else {
        duplicateRemoved.push(item);
      }
    });
    return duplicateRemoved;
  };

  if (window.localStorage.getItem("user") === "allegiant") {
    useEffect(() => {
      fetchConversationFilterData();
    }, []);
  }

  if (window.localStorage.getItem("user") === "allegiant") {
    useEffect(() => {
      fetchConversationData(startDateAudit, endDateAudit);
    }, []);
  }

  if (window.localStorage.getItem("user") === "allegiant") {
    useEffect(() => {
      if (jsonOpenAuditData && Object.keys(jsonOpenAuditData).length !== 0) {
        setDashboardAuditData(getOpenAuditGraphData());
      } else {
        setDashboardAuditData({});
      }
    }, [jsonOpenAuditData]);
  }

  const findFilters = (name) => {
    for (const element of objFilter) {
      // console.log("Element filter is",element)
      if (element["name"] === name) {
        return element;
      }
    }
  };

  const getOpenAuditGraphData = () => {
    const loudnessCampaignAggr = jsonOpenAuditData["Loudness By Campaign"];
    let loudnessCampaignLabel = [];
    let loudnessCampaignAgentData = [];
    let loudnessCampaignCustomerData = [];
    let loudnessCampaignAggregationObj = {};
    let loudnessCampaignAggregationCustObj = {};

    if (
      !_.isEmpty(loudnessCampaignAggr[0]) &&
      loudnessCampaignAggr[0].length !== 0
    ) {
      loudnessCampaignAggr[0].labels.forEach((element, index) => {
        loudnessCampaignAggregationObj[element] =
          loudnessCampaignAggr[0].AgentData[index];
      });
      Object.entries(loudnessCampaignAggregationObj).forEach(([label, val]) => {
        loudnessCampaignLabel.push(label);
        loudnessCampaignAgentData.push(val.toFixed(1));
      });
    }
    if (
      !_.isEmpty(loudnessCampaignAggr[0]) &&
      loudnessCampaignAggr[0].length !== 0
    ) {
      loudnessCampaignAggr[0].labels.forEach((element, index) => {
        loudnessCampaignAggregationCustObj[element] =
          loudnessCampaignAggr[0].CustData[index];
      });
      Object.entries(loudnessCampaignAggregationCustObj).forEach(
        ([label, val]) => {
          loudnessCampaignCustomerData.push(val.toFixed(1));
        }
      );
    }
    const loudnessCampaignBarData = {
      datasets: [
        {
          data: loudnessCampaignAgentData,
          backgroundColor: "#90ED7D",
          label: "Campaign-wise Loudness by Agent",
          borderWidth: 1,
        },
        {
          data: loudnessCampaignCustomerData,
          backgroundColor: "#FF3039",
          label: "Campaign-wise Loudness by Customer",
          borderWidth: 1,
        },
      ],
      labels: transformArray(loudnessCampaignLabel),
    };

    const loudnessCampaignOptions = {
      plugins: {
        datalabels: {
          display: true,
          color: "black",
          formatter: function (value) {
            return value;
          },
        },
        title: {
          display: true,
          text: "Loudness Distribution over Agent/Customer by Campaign",
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
          ticks: {
            callback: function (value) {
              return this.getLabelForValue(value).length > 15
                ? this.getLabelForValue(value).substr(0, 15) + "..."
                : this.getLabelForValue(value);
            },
          },
        },
        y: {
          stacked: true,
        },
      },
    };

    const loudnessAgentAggr = jsonOpenAuditData["Loudness By Agent"];
    let loudnessAgentLabel = [];
    let loudnessAgentagrAgentData = [];
    let loudnessAgentCustomerData = [];
    let loudnessAgentAggregationObj = {};
    let loudnessAgentAggregationCustObj = {};

    if (!_.isEmpty(loudnessAgentAggr[0]) && loudnessAgentAggr[0].length !== 0) {
      loudnessAgentAggr[0].labels.forEach((element, index) => {
        loudnessAgentAggregationObj[element] =
          loudnessAgentAggr[0].AgentData[index];
      });
      Object.entries(loudnessAgentAggregationObj).forEach(([label, val]) => {
        loudnessAgentLabel.push(label);
        loudnessAgentagrAgentData.push(val.toFixed(1));
      });
    }
    if (!_.isEmpty(loudnessAgentAggr[0]) && loudnessAgentAggr[0].length !== 0) {
      loudnessAgentAggr[0].labels.forEach((element, index) => {
        loudnessAgentAggregationCustObj[element] =
          loudnessAgentAggr[0].CustData[index];
      });
      Object.entries(loudnessAgentAggregationCustObj).forEach(
        ([label, val]) => {
          loudnessAgentCustomerData.push(val.toFixed(1));
        }
      );
    }
    const loudnessAgentBarData = {
      datasets: [
        {
          data: loudnessAgentagrAgentData,
          backgroundColor: "#90ED7D",
          label: "Agent-wise Loudness by Agent",
          borderWidth: 1,
        },
        {
          data: loudnessAgentCustomerData,
          backgroundColor: "#FF3039",
          label: "Agent-wise Loudness by Customer",
          borderWidth: 1,
        },
      ],
      labels: transformArray(loudnessAgentLabel),
    };

    const loudnessAgentBarOptions = {
      plugins: {
        datalabels: {
          display: true,
          color: "black",
          formatter: function (value) {
            return value;
          },
        },
        title: {
          display: true,
          text: "Loudness Distribution over Agent/Customer by Agent",
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
          ticks: {
            callback: function (value) {
              return this.getLabelForValue(value).length > 15
                ? this.getLabelForValue(value).substr(0, 15) + "..."
                : this.getLabelForValue(value);
            },
          },
        },
        y: {
          stacked: true,
        },
      },
    };

    const interruptsCampaignAggr = jsonOpenAuditData["Interrupts By Campaign"];
    let interruptsCampaignLabel = [];
    let interruptsCampaignAgentData = [];
    let interruptsCampaignCustomerData = [];
    let interruptsCampaignAggregationObj = {};
    let interruptsCampaignAggregationCustObj = {};

    if (
      !_.isEmpty(interruptsCampaignAggr[0]) &&
      interruptsCampaignAggr[0].length !== 0
    ) {
      interruptsCampaignAggr[0].labels.forEach((element, index) => {
        interruptsCampaignAggregationObj[element] =
          interruptsCampaignAggr[0].AgentData[index];
      });
      Object.entries(interruptsCampaignAggregationObj).forEach(
        ([label, val]) => {
          interruptsCampaignLabel.push(label);
          interruptsCampaignAgentData.push(val.toFixed(1));
        }
      );
    }
    if (
      !_.isEmpty(interruptsCampaignAggr[0]) &&
      interruptsCampaignAggr[0].length !== 0
    ) {
      interruptsCampaignAggr[0].labels.forEach((element, index) => {
        interruptsCampaignAggregationCustObj[element] =
          interruptsCampaignAggr[0].CustData[index];
      });
      Object.entries(interruptsCampaignAggregationCustObj).forEach(
        ([label, val]) => {
          interruptsCampaignCustomerData.push(val.toFixed(1));
        }
      );
    }

    const interruptsCampaignBarData = {
      datasets: [
        {
          data: interruptsCampaignAgentData,
          backgroundColor: "#90ED7D",
          label: "Campaign-wise Interrupts by Agent",
          borderWidth: 1,
        },
        {
          data: interruptsCampaignCustomerData,
          backgroundColor: "#FF3039",
          label: "Campaign-wise Iterrupts by Customer",
          borderWidth: 1,
        },
      ],
      labels: transformArray(interruptsCampaignLabel),
    };

    const interruptsCampaignOptions = {
      plugins: {
        datalabels: {
          display: true,
          color: "black",
          formatter: function (value) {
            return value;
          },
        },
        title: {
          display: true,
          text: "No. of Interrupts Distribution over Agent/Customer by Campaign",
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
          ticks: {
            callback: function (value) {
              return this.getLabelForValue(value).length > 15
                ? this.getLabelForValue(value).substr(0, 15) + "..."
                : this.getLabelForValue(value);
            },
          },
        },
        y: {
          stacked: true,
        },
      },
    };

    const interruptsAgentAggr = jsonOpenAuditData["Interrupts By Agent"];
    let interruptAgentLabel = [];
    let interruptsAgentagrAgentData = [];
    let interruptsAgentCustomerData = [];
    let interruptsAgentAggregationObj = {};
    let interruptsAgentAggregationCustObj = {};

    if (
      !_.isEmpty(interruptsAgentAggr[0]) &&
      interruptsAgentAggr[0].length !== 0
    ) {
      interruptsAgentAggr[0].labels.forEach((element, index) => {
        interruptsAgentAggregationObj[element] =
          interruptsAgentAggr[0].AgentData[index];
      });
      Object.entries(interruptsAgentAggregationObj).forEach(([label, val]) => {
        interruptAgentLabel.push(label);
        interruptsAgentagrAgentData.push(val.toFixed(1));
      });
    }
    if (
      !_.isEmpty(interruptsAgentAggr[0]) &&
      interruptsAgentAggr[0].length !== 0
    ) {
      interruptsAgentAggr[0].labels.forEach((element, index) => {
        interruptsAgentAggregationCustObj[element] =
          interruptsAgentAggr[0].CustData[index];
      });
      Object.entries(interruptsAgentAggregationCustObj).forEach(
        ([label, val]) => {
          interruptsAgentCustomerData.push(val.toFixed(1));
        }
      );
    }

    const interruptsAgentBarData = {
      datasets: [
        {
          data: interruptsAgentagrAgentData,
          backgroundColor: "#90ED7D",
          label: "Agent-wise No. of Interrupts by Agent",
          borderWidth: 1,
        },
        {
          data: interruptsAgentCustomerData,
          backgroundColor: "#FF3039",
          label: "Agent-wise No. of Interrupts by Customer",
          borderWidth: 1,
        },
      ],
      labels: transformArray(interruptAgentLabel),
    };

    const interruptsAgentBarOptions = {
      plugins: {
        datalabels: {
          display: true,
          color: "black",
          formatter: function (value) {
            return value;
          },
        },
        title: {
          display: true,
          text: "No. of Interrupts over Agent/Customer by Agent",
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
          ticks: {
            callback: function (value) {
              return this.getLabelForValue(value).length > 15
                ? this.getLabelForValue(value).substr(0, 15) + "..."
                : this.getLabelForValue(value);
            },
          },
        },
        y: {
          stacked: true,
        },
      },
    };

    const IterruptDurationCampaignAggr =
      jsonOpenAuditData["Interrupts Duration By Campaign"];
    let interruptDurationCampaignLabel = [];
    let interruptDurationCampaignAgentData = [];
    let interruptDurationCampaignCustomerData = [];
    let interruptDurationCampaignAggregationObj = {};
    let inerruptDurationCampaignAggregationCustObj = {};

    if (
      !_.isEmpty(IterruptDurationCampaignAggr[0]) &&
      IterruptDurationCampaignAggr[0].length !== 0
    ) {
      IterruptDurationCampaignAggr[0].labels.forEach((element, index) => {
        interruptDurationCampaignAggregationObj[element] =
          IterruptDurationCampaignAggr[0].AgentData[index];
      });
      Object.entries(interruptDurationCampaignAggregationObj).forEach(
        ([label, val]) => {
          interruptDurationCampaignLabel.push(label);
          interruptDurationCampaignAgentData.push(val.toFixed(1));
        }
      );
    }
    if (
      !_.isEmpty(IterruptDurationCampaignAggr[0]) &&
      IterruptDurationCampaignAggr[0].length !== 0
    ) {
      IterruptDurationCampaignAggr[0].labels.forEach((element, index) => {
        inerruptDurationCampaignAggregationCustObj[element] =
          IterruptDurationCampaignAggr[0].CustData[index];
      });
      Object.entries(inerruptDurationCampaignAggregationCustObj).forEach(
        ([label, val]) => {
          interruptDurationCampaignCustomerData.push(val.toFixed(1));
        }
      );
    }

    const interruptDurationCampaignBarData = {
      datasets: [
        {
          data: interruptDurationCampaignAgentData,
          backgroundColor: "#90ED7D",
          label: "Campaign-wise Interrupt duration by Agent",
          borderWidth: 1,
        },
        {
          data: interruptDurationCampaignCustomerData,
          backgroundColor: "#FF3039",
          label: "Campaign-wise Interrupt Duration by Customer",
          borderWidth: 1,
        },
      ],
      labels: transformArray(interruptDurationCampaignLabel),
    };

    const interruptDurationCampaignOptions = {
      plugins: {
        datalabels: {
          display: true,
          color: "black",
          formatter: function (value) {
            return value;
          },
        },
        title: {
          display: true,
          text: "Interrupts Duration Distribution over Agent/Customer by Campaign",
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
          ticks: {
            callback: function (value) {
              return this.getLabelForValue(value).length > 15
                ? this.getLabelForValue(value).substr(0, 15) + "..."
                : this.getLabelForValue(value);
            },
          },
        },
        y: {
          stacked: true,
        },
      },
    };

    const interruptDurationAgentAggr =
      jsonOpenAuditData["Interrupts Duration By Agent"];
    let interruptDurationAgentLabel = [];
    let interruptDurationAgentagrAgentData = [];
    let interruptDurationAgentCustomerData = [];
    let interruptDurationAgentAggregationObj = {};
    let interruptDurationAgentAggregationCustObj = {};

    if (
      !_.isEmpty(interruptDurationAgentAggr[0]) &&
      interruptDurationAgentAggr[0].length !== 0
    ) {
      interruptDurationAgentAggr[0].labels.forEach((element, index) => {
        interruptDurationAgentAggregationObj[element] =
          interruptDurationAgentAggr[0].AgentData[index];
      });
      Object.entries(interruptDurationAgentAggregationObj).forEach(
        ([label, val]) => {
          interruptDurationAgentLabel.push(label);
          interruptDurationAgentagrAgentData.push(val.toFixed(1));
        }
      );
    }
    if (
      !_.isEmpty(interruptDurationAgentAggr[0]) &&
      interruptDurationAgentAggr[0].length !== 0
    ) {
      interruptDurationAgentAggr[0].labels.forEach((element, index) => {
        interruptDurationAgentAggregationCustObj[element] =
          interruptDurationAgentAggr[0].CustData[index];
      });
      Object.entries(interruptDurationAgentAggregationCustObj).forEach(
        ([label, val]) => {
          interruptDurationAgentCustomerData.push(val.toFixed(1));
        }
      );
    }

    const interruptDurationAgentBarData = {
      datasets: [
        {
          data: interruptDurationAgentagrAgentData,
          backgroundColor: "#90ED7D",
          label: "Agent-wise Interrupt Duration by Agent",
          borderWidth: 1,
        },
        {
          data: interruptDurationAgentCustomerData,
          backgroundColor: "#FF3039",
          label: "Agent-wise Interrupt Duration by Customer",
          borderWidth: 1,
        },
      ],
      labels: transformArray(interruptDurationAgentLabel),
    };

    const interruptDurationAgentBarOptions = {
      plugins: {
        datalabels: {
          display: true,
          color: "black",
          formatter: function (value) {
            return value;
          },
        },
        title: {
          display: true,
          text: "Interrupt Duration over Agent/Customer by Agent",
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
          ticks: {
            callback: function (value) {
              return this.getLabelForValue(value).length > 15
                ? this.getLabelForValue(value).substr(0, 15) + "..."
                : this.getLabelForValue(value);
            },
          },
        },
        y: {
          stacked: true,
        },
      },
    };

    const speakingCampaignAggr = jsonOpenAuditData["Speaking-Rate By Campaign"];
    let speakingCampaignLabel = [];
    let speakingCampaignAgentData = [];
    let speakingCampaignCustomerData = [];
    let speakingCampaignAggregationObj = {};
    let speakingCampaignAggregationCustObj = {};

    if (
      !_.isEmpty(speakingCampaignAggr[0]) &&
      speakingCampaignAggr[0].length !== 0
    ) {
      speakingCampaignAggr[0].labels.forEach((element, index) => {
        speakingCampaignAggregationObj[element] =
          speakingCampaignAggr[0].AgentData[index];
      });
      Object.entries(speakingCampaignAggregationObj).forEach(([label, val]) => {
        speakingCampaignLabel.push(label);
        speakingCampaignAgentData.push(val.toFixed(1));
      });
    }
    if (
      !_.isEmpty(speakingCampaignAggr[0]) &&
      speakingCampaignAggr[0].length !== 0
    ) {
      speakingCampaignAggr[0].labels.forEach((element, index) => {
        speakingCampaignAggregationCustObj[element] =
          speakingCampaignAggr[0].CustData[index];
      });
      Object.entries(speakingCampaignAggregationCustObj).forEach(
        ([label, val]) => {
          speakingCampaignCustomerData.push(val.toFixed(1));
        }
      );
    }

    const speakingCampaignBarData = {
      datasets: [
        {
          data: speakingCampaignAgentData,
          backgroundColor: "#90ED7D",
          label: "Campaign-wise Speaking-Rate by Agent",
          borderWidth: 1,
        },
        {
          data: speakingCampaignCustomerData,
          backgroundColor: "#FF3039",
          label: "Campaign-wise Speaking-Rate by Customer",
          borderWidth: 1,
        },
      ],
      labels: transformArray(speakingCampaignLabel),
    };

    const speakingCampaignOptions = {
      plugins: {
        datalabels: {
          display: true,
          color: "black",
          formatter: function (value) {
            return value;
          },
        },
        title: {
          display: true,
          text: "Speaking-Rate Distribution over Agent/Customer by Campaign",
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
          ticks: {
            callback: function (value) {
              return this.getLabelForValue(value).length > 15
                ? this.getLabelForValue(value).substr(0, 15) + "..."
                : this.getLabelForValue(value);
            },
          },
        },
        y: {
          stacked: true,
        },
      },
    };

    const speakingRateAgentAggr = jsonOpenAuditData["Speaking-Rate By Agent"];
    let speakingAgentLabel = [];
    let speakingAgentagrAgentData = [];
    let speakingAgentCustomerData = [];
    let speakingAgentAggregationObj = {};
    let speakingAgentAggregationCustObj = {};

    if (
      !_.isEmpty(speakingRateAgentAggr[0]) &&
      speakingRateAgentAggr[0].length !== 0
    ) {
      speakingRateAgentAggr[0].labels.forEach((element, index) => {
        speakingAgentAggregationObj[element] =
          speakingRateAgentAggr[0].AgentData[index];
      });
      Object.entries(speakingAgentAggregationObj).forEach(([label, val]) => {
        speakingAgentLabel.push(label);
        speakingAgentagrAgentData.push(val.toFixed(1));
      });
    }
    if (
      !_.isEmpty(speakingRateAgentAggr[0]) &&
      speakingRateAgentAggr[0].length !== 0
    ) {
      speakingRateAgentAggr[0].labels.forEach((element, index) => {
        speakingAgentAggregationCustObj[element] =
          speakingRateAgentAggr[0].CustData[index];
      });
      Object.entries(speakingAgentAggregationCustObj).forEach(
        ([label, val]) => {
          speakingAgentCustomerData.push(val.toFixed(1));
        }
      );
    }

    const speakingRateAgentBarData = {
      datasets: [
        {
          data: speakingAgentagrAgentData,
          backgroundColor: "#90ED7D",
          label: "Agent-wise Speaking-Rate by Agent",
          borderWidth: 1,
        },
        {
          data: speakingAgentCustomerData,
          backgroundColor: "#FF3039",
          label: "Agent-wise Speaking-Rate by Customer",
          borderWidth: 1,
        },
      ],
      labels: transformArray(speakingAgentLabel),
    };

    const speakingRateAgentBarOptions = {
      plugins: {
        datalabels: {
          display: true,
          color: "black",
          formatter: function (value) {
            return value;
          },
        },
        title: {
          display: true,
          text: "Speaking-Rate over Agent/Customer by Agent",
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
          ticks: {
            callback: function (value) {
              return this.getLabelForValue(value).length > 15
                ? this.getLabelForValue(value).substr(0, 15) + "..."
                : this.getLabelForValue(value);
            },
          },
        },
        y: {
          stacked: true,
        },
      },
    };

    const participationCampaignAggr =
      jsonOpenAuditData["Participation-Ratio By Campaign"];
    let partipationCampaignLabel = [];
    let participationCampaignAgentData = [];
    let participationCampaignCustomerData = [];
    let participationCampaignAggregationObj = {};
    let participationCampaignAggregationCustObj = {};

    if (
      !_.isEmpty(participationCampaignAggr[0]) &&
      participationCampaignAggr[0].length !== 0
    ) {
      participationCampaignAggr[0].labels.forEach((element, index) => {
        participationCampaignAggregationObj[element] =
          participationCampaignAggr[0].AgentData[index];
      });
      Object.entries(participationCampaignAggregationObj).forEach(
        ([label, val]) => {
          partipationCampaignLabel.push(label);
          participationCampaignAgentData.push(val.toFixed(1));
        }
      );
    }
    if (
      !_.isEmpty(participationCampaignAggr[0]) &&
      participationCampaignAggr[0].length !== 0
    ) {
      participationCampaignAggr[0].labels.forEach((element, index) => {
        participationCampaignAggregationCustObj[element] =
          participationCampaignAggr[0].CustData[index];
      });
      Object.entries(participationCampaignAggregationCustObj).forEach(
        ([label, val]) => {
          participationCampaignCustomerData.push(val.toFixed(1));
        }
      );
    }

    const participationCampaignBarData = {
      datasets: [
        {
          data: participationCampaignAgentData,
          backgroundColor: "#90ED7D",
          label: "Campaign-wise Participation-Ratio by Agent",
          borderWidth: 1,
        },
        {
          data: participationCampaignCustomerData,
          backgroundColor: "#FF3039",
          label: "Campaign-wise Participation-Ratio by Customer",
          borderWidth: 1,
        },
      ],
      labels: transformArray(partipationCampaignLabel),
    };

    const participationCampaignOptions = {
      plugins: {
        datalabels: {
          display: true,
          color: "black",
          formatter: function (value) {
            return value;
          },
        },
        title: {
          display: true,
          text: "Participation-Ratio Distribution over Agent/Customer by Campaign",
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
          ticks: {
            callback: function (value) {
              return this.getLabelForValue(value).length > 15
                ? this.getLabelForValue(value).substr(0, 15) + "..."
                : this.getLabelForValue(value);
            },
          },
        },
        y: {
          stacked: true,
        },
      },
    };

    const participationAgentAggr =
      jsonOpenAuditData["Participation-Ratio By Agent"];
    let participationAgentLabel = [];
    let participationAgentagrAgentData = [];
    let participationAgentCustomerData = [];
    let participationAgentAggregationObj = {};
    let participationAgentAggregationCustObj = {};

    if (
      !_.isEmpty(participationAgentAggr[0]) &&
      participationAgentAggr[0].length !== 0
    ) {
      participationAgentAggr[0].labels.forEach((element, index) => {
        participationAgentAggregationObj[element] =
          participationAgentAggr[0].AgentData[index];
      });
      Object.entries(participationAgentAggregationObj).forEach(
        ([label, val]) => {
          participationAgentLabel.push(label);
          participationAgentagrAgentData.push(val.toFixed(1));
        }
      );
    }
    if (
      !_.isEmpty(participationAgentAggr[0]) &&
      participationAgentAggr[0].length !== 0
    ) {
      participationAgentAggr[0].labels.forEach((element, index) => {
        participationAgentAggregationCustObj[element] =
          participationAgentAggr[0].CustData[index];
      });
      Object.entries(participationAgentAggregationCustObj).forEach(
        ([label, val]) => {
          participationAgentCustomerData.push(val.toFixed(1));
        }
      );
    }

    const participationAgentBarData = {
      datasets: [
        {
          data: participationAgentagrAgentData,
          backgroundColor: "#90ED7D",
          label: "Agent-wise Participation-Ratio by Agent",
          borderWidth: 1,
        },
        {
          data: participationAgentCustomerData,
          backgroundColor: "#FF3039",
          label: "Agent-wise Participation-Ratio by Customer",
          borderWidth: 1,
        },
      ],
      labels: transformArray(speakingAgentLabel),
    };

    const participationAgentBarOptions = {
      plugins: {
        datalabels: {
          display: true,
          color: "black",
          formatter: function (value) {
            return value;
          },
        },
        title: {
          display: true,
          text: "Participation-Ratio over Agent/Customer by Agent",
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
          ticks: {
            callback: function (value) {
              return this.getLabelForValue(value).length > 15
                ? this.getLabelForValue(value).substr(0, 15) + "..."
                : this.getLabelForValue(value);
            },
          },
        },
        y: {
          stacked: true,
        },
      },
    };

    const timeScoreAggregation = jsonOpenAuditData["TimeScore Histogram"];
    let timeScoreAggrLabelPer = [];
    let timeScoreAggrDataPer = [];
    let timeScoreAggrDataCallPer = [];
    let timeScoreAggregationObj = {};
    let timeScoreAggregationCallsObj = {};
    if (
      !_.isEmpty(timeScoreAggregation[0]) &&
      timeScoreAggregation[0].length !== 0
    ) {
      timeScoreAggregation[0].labels.forEach((element, index) => {
        timeScoreAggregationObj[element] =
          timeScoreAggregation[0].data[index] * 100;
      });
      Object.entries(timeScoreAggregationObj).forEach(([label, val]) => {
        timeScoreAggrLabelPer.push(label);
        timeScoreAggrDataPer.push(val.toFixed(1));
      });
      console.log(timeScoreAggrDataPer);
    }
    if (
      !_.isEmpty(timeScoreAggregation[0]) &&
      timeScoreAggregation[0].length !== 0
    ) {
      timeScoreAggregation[0].labels.forEach((element, index) => {
        timeScoreAggregationCallsObj[element] =
          timeScoreAggregation[0].tooltipData[index];
      });
      Object.entries(timeScoreAggregationCallsObj).forEach(([label, val]) => {
        timeScoreAggrDataCallPer.push(val.toFixed(1));
      });
    }

    const timeScoreData = {
      datasets: [
        {
          data: timeScoreAggrDataPer,
          backgroundColor: "#008DFF",
          // borderColor: "#000000",
          borderWidth: 1,
          label: "Trends of Score in %",
          fill: false,
          lineTension: 0.2,
          borderColor: "#008DFF",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "#008DFF",
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "#008DFF",
          pointHoverBorderColor: "rgba(220,220,220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          yAxisID: "y",
        },
        {
          data: timeScoreAggrDataCallPer,
          backgroundColor: "#EC357E",
          // borderColor: "#000000",
          borderWidth: 1,
          label: "Trends of Calls",
          fill: false,
          lineTension: 0.2,
          borderColor: "#EC357E",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "#EC357E",
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "#EC357E",
          pointHoverBorderColor: "rgba(220,220,220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          yAxisID: "y1",
        },
      ],
      labels: timeScoreAggrLabelPer,
    };

    const timeScoreOptions = {
      plugins: {
        legend: {
          display: true,
          position: "top",
        },
        title: {
          display: true,
          text: "Trends Over Time",
        },
        scrollbar: {
          enabled: true,
        },
      },
      interaction: {
        mode: "index",
        axis: "x",
        intersect: false,
      },
      responsiveAnimationDuration: 0,
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          type: "linear",
          display: true,
          beginAtZero: true,
          position: "left",
        },
        y1: {
          type: "linear",
          display: true,
          beginAtZero: true,
          position: "right",
          grid: {
            drawOnChartArea: false,
          },
        },
      },
    };

    const agentScoreAggregation = jsonOpenAuditData["Agent Score"];
    let agentScoreAggrLabelPer = [];
    let agentScoreAggrDataPer = [];
    let agentScoreAggregationObj = {};
    if (
      !_.isEmpty(agentScoreAggregation[0]) &&
      agentScoreAggregation[0].length !== 0
    ) {
      agentScoreAggregation[0].labels.forEach((element, index) => {
        agentScoreAggregationObj[element] =
          agentScoreAggregation[0].data[index] * 100;
      });
      Object.entries(agentScoreAggregationObj).forEach(([label, val]) => {
        agentScoreAggrLabelPer.push(label);
        agentScoreAggrDataPer.push(val.toFixed(1));
      });
    }
    const agentScoreOptions = {
      plugins: {
        datalabels: {
          display: true,
          color: "black",
          formatter: function (value) {
            return value;
          },
        },
        title: {
          display: true,
          text: "Agent-wise Score Distribution",
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
          ticks: {
            callback: function (value) {
              return this.getLabelForValue(value).length > 15
                ? this.getLabelForValue(value).substr(0, 15) + "..."
                : this.getLabelForValue(value);
            },
          },
        },
        y: {
          stacked: true,
        },
      },
    };

    const agentScoreData = {
      datasets: [
        {
          data: agentScoreAggrDataPer,
          backgroundColor: "#90ED7D",
          label: "Agent-wise Score Data in %",
          stack: 1,
        },
      ],
      labels: transformArray(agentScoreAggrLabelPer),
    };

    const campaignScoreAggregation = jsonOpenAuditData["Score By Campaign"];
    let campaignScoreAggregationLabelPer = [];
    let campaignScoreAggregationDataPer = [];
    let campaignScoreAggregationObj = {};
    if (!_.isEmpty(campaignScoreAggregation[0])) {
      campaignScoreAggregation[0].labels.forEach((element, index) => {
        campaignScoreAggregationObj[element] =
          campaignScoreAggregation[0].data[index] * 100;
      });
      Object.entries(campaignScoreAggregationObj).forEach(([label, val]) => {
        campaignScoreAggregationLabelPer.push(label);
        campaignScoreAggregationDataPer.push(val.toFixed(1));
      });
    }

    const campaignScoreOptions = {
      plugins: {
        datalabels: {
          display: true,
          color: "black",
          formatter: function (value) {
            return value;
          },
        },
        title: {
          display: true,
          text: "Campaign Score Distribution",
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
          ticks: {
            callback: function (value) {
              return this.getLabelForValue(value).length > 15
                ? this.getLabelForValue(value).substr(0, 15) + "..."
                : this.getLabelForValue(value);
            },
          },
        },
        y: {
          stacked: true,
        },
      },
    };

    const campaignScoreData = {
      datasets: [
        {
          data: campaignScoreAggregationDataPer.slice(0, 12),
          backgroundColor: "#90ED7D",
          label: "Campaign Score Data in %",
          stack: 1,
        },
      ],
      labels: transformArray(campaignScoreAggregationLabelPer.slice(0, 12)),
    };

    const skillScoreAggregation = jsonOpenAuditData["Score By Skill"];

    let skillScoreAggregationLabelPer = [];
    let skillScoreAggregationDataPer = [];
    let skillScoreAggregationAggregationObj = {};
    if (!_.isEmpty(skillScoreAggregation[0])) {
      skillScoreAggregation[0].labels.forEach((element, index) => {
        skillScoreAggregationAggregationObj[element] =
          skillScoreAggregation[0].data[index] * 100;
      });
      Object.entries(skillScoreAggregationAggregationObj).forEach(
        ([label, val]) => {
          skillScoreAggregationLabelPer.push(label);
          skillScoreAggregationDataPer.push(val.toFixed(1));
        }
      );
    }

    const skillScoreOptions = {
      plugins: {
        datalabels: {
          display: true,
          color: "black",
          formatter: function (value) {
            return value;
          },
        },
        title: {
          display: true,
          text: "Skill Score Distribution",
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
          ticks: {
            callback: function (value) {
              return this.getLabelForValue(value).length > 15
                ? this.getLabelForValue(value).substr(0, 15) + "..."
                : this.getLabelForValue(value);
            },
          },
        },
        y: {
          stacked: true,
        },
      },
    };

    const skillScoreData = {
      datasets: [
        {
          data: skillScoreAggregationDataPer.slice(0, 12),
          backgroundColor: "#90ED7D",
          label: "Skill Score Data in %",
          stack: 1,
        },
      ],
      labels: transformArray(skillScoreAggregationLabelPer.slice(0, 12)),
    };

    const dispositionScoreAggregation =
      jsonOpenAuditData["Score By Disposition"];

    let dispositionScoreAggregationLabelPer = [];
    let dispositionScoreAggregationDataPer = [];
    let dispositionScoreAggregationAggregationObj = {};
    if (!_.isEmpty(dispositionScoreAggregation[0])) {
      dispositionScoreAggregation[0].labels.forEach((element, index) => {
        dispositionScoreAggregationAggregationObj[element] =
          dispositionScoreAggregation[0].data[index] * 100;
      });
      Object.entries(dispositionScoreAggregationAggregationObj).forEach(
        ([label, val]) => {
          dispositionScoreAggregationLabelPer.push(label);
          dispositionScoreAggregationDataPer.push(val.toFixed(1));
        }
      );
    }

    const dispositionScoreOptions = {
      plugins: {
        datalabels: {
          display: true,
          color: "black",
          formatter: function (value) {
            return value;
          },
        },
        title: {
          display: true,
          text: "Disposition Score Distribution",
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
          ticks: {
            callback: function (value) {
              return this.getLabelForValue(value).length > 15
                ? this.getLabelForValue(value).substr(0, 15) + "..."
                : this.getLabelForValue(value);
            },
          },
        },
        y: {
          stacked: true,
        },
      },
    };

    const dispositionScoreData = {
      datasets: [
        {
          data: dispositionScoreAggregationDataPer.slice(0, 12),
          backgroundColor: "#90ED7D",
          label: "Disposition Score Data in %",
          stack: 1,
        },
      ],
      labels: transformArray(dispositionScoreAggregationLabelPer.slice(0, 12)),
    };
    //add all defined variables into one object
    return {
      campaignScoreData: campaignScoreData,
      campaignScoreOptions: campaignScoreOptions,
      skillScoreData: skillScoreData,
      skillScoreOptions: skillScoreOptions,
      dispositionScoreData: dispositionScoreData,
      dispositionScoreOptions: dispositionScoreOptions,
      loudnessCampaignBarData: loudnessCampaignBarData,
      loudnessCampaignOptions: loudnessCampaignOptions,
      loudnessAgentBarOptions: loudnessAgentBarOptions,
      loudnessAgentBarData: loudnessAgentBarData,
      speakingCampaignOptions: speakingCampaignOptions,
      speakingCampaignBarData: speakingCampaignBarData,
      speakingRateAgentBarData: speakingRateAgentBarData,
      speakingRateAgentBarOptions: speakingRateAgentBarOptions,
      interruptsCampaignBarData: interruptsCampaignBarData,
      interruptsCampaignOptions: interruptsCampaignOptions,
      interruptsAgentBarData: interruptsAgentBarData,
      interruptsAgentBarOptions: interruptsAgentBarOptions,
      interruptDurationCampaignBarData: interruptDurationCampaignBarData,
      interruptDurationCampaignOptions: interruptDurationCampaignOptions,
      interruptDurationAgentBarData: interruptDurationAgentBarData,
      interruptDurationAgentBarOptions: interruptDurationAgentBarOptions,
      participationCampaignBarData: participationCampaignBarData,
      participationCampaignOptions: participationCampaignOptions,
      participationAgentBarData: participationAgentBarData,
      participationAgentBarOptions: participationAgentBarOptions,
      agentScoreOptions: agentScoreOptions,
      agentScoreData: agentScoreData,
      timeScoreData: timeScoreData,
      timeScoreOptions: timeScoreOptions,
    };
  };

  const getIntentData = (intent) => {
    let intentArray = [];
    for (const element of intent) {
      intentArray.push({ label: element, value: element });
    }
    return intentArray;
  };

  const selectionList = (arrOfObj) => {
    let selectionString = "";
    let selectionArr = [];
    if (arrOfObj !== undefined && arrOfObj.length > 0) {
      for (const ele of arrOfObj) {
        selectionArr.push(ele["value"]);
      }
      selectionString = String(selectionArr);
    } else {
      selectionString = "0";
    }
    return selectionString;
  };

  const handleChange = (event, newValue) => {
    // console.log("Tabs value", newValue);
    setValue(newValue);
  };

  function transformArray(arr) {
    if (arr.length !== 0) {
      return arr.map((item) => {
        // Split the string by underscores
        const words = item.split("_");
        // Capitalize the first letter of each word
        const capitalizedWords = words.map((word) => {
          return word.charAt(0).toUpperCase() + word.slice(1);
        });
        // Join the words with spaces and return the transformed string
        return capitalizedWords && capitalizedWords.join(" ");
      });
    } else {
      return [];
    }
  }

  const fetchConversationData = async (
    startDateAudit,
    endDateAudit,
    campaignAuditVal,
    agentAuditVal,
    dispositionAuditVal,
    skillAuditVal,
    size,
    from
  ) => {
    setIsload(!load);

    if (startDateAudit) {
      const response = await axios.get(openConversationDataUrl, {
        params: {
          startDateAudit: String(startDateAudit),
          endDateAudit: String(endDateAudit),
          campaignAuditVal: selectionList(campaignAuditVal),
          agentAuditVal: selectionList(agentAuditVal),
          dispositionAuditVal: selectionList(dispositionAuditVal),
          skillAuditVal: selectionList(skillAuditVal),
          size: size ? size : 5,
          from: from ? from : 0,
        },
      });
      setJsonOpenAuditData(response.data);
      if (_.isEmpty(response.data)) {
        setJsonOpenAuditData({});
      }
      setIsload(false);
    } else {
      const response = await Axios(openConversationDataUrl);
      setJsonOpenAuditData(response.data);
      if (_.isEmpty(response.data)) {
        setJsonOpenAuditData({});
      }
      setIsload(false);
    }
  };

  const fetchConversationFilterData = async () => {
    const filtersAudit = await Axios(openConversationFilterUrl);
    // console.log(filtersAudit.data.aggregation);
    sessionStorage.setItem(
      "filtersData",
      JSON.stringify(filtersAudit.data.aggregation)
    );
    if (typeof sessionStorage.filtersData !== "undefined") {
      objFilter = JSON.parse(sessionStorage.filtersData);
    }
    const campaignFilters = findFilters("AuditCampaignCount", objFilter);
    sessionStorage.setItem(
      "campaignFilters",
      JSON.stringify(campaignFilters["labels"])
    );
    if (typeof sessionStorage.campaignFilters !== "undefined") {
      campaignFinalFilter = JSON.parse(sessionStorage.campaignFilters);
    }
    const campaignArr = getIntentData(campaignFinalFilter);
    setCampaignAuditOptions(campaignArr);

    const languageFilters = findFilters("AuditLanguageCount", objFilter);
    sessionStorage.setItem(
      "languageFilters",
      JSON.stringify(languageFilters["labels"])
    );

    const agentFilters = findFilters("AuditCallsPerAgent", objFilter);
    sessionStorage.setItem(
      "agentFilters",
      JSON.stringify(agentFilters["labels"])
    );
    if (typeof sessionStorage.agentFilters !== "undefined") {
      agentFinalFilter = JSON.parse(sessionStorage.agentFilters);
    }
    const agentArr = getIntentData(agentFinalFilter);
    setAgentAuditOptions(agentArr);

    const dispositionFilters = findFilters("AuditDispositionCount", objFilter);
    sessionStorage.setItem(
      "dispositionFilters",
      JSON.stringify(dispositionFilters["labels"])
    );
    if (typeof sessionStorage.dispositionFilters !== "undefined") {
      dispositionFinalFilter = JSON.parse(sessionStorage.dispositionFilters);
    }
    const dispositionArr = getIntentData(dispositionFinalFilter);
    setDispositionAuditOptions(dispositionArr);

    const skillFilters = findFilters("AuditSkillCount", objFilter);
    sessionStorage.setItem(
      "skillFilters",
      JSON.stringify(skillFilters["labels"])
    );
    if (typeof sessionStorage.skillFilters !== "undefined") {
      skillFinalFilter = JSON.parse(sessionStorage.skillFilters);
    }
    const skillArr = getIntentData(skillFinalFilter);
    setSkillOptions(skillArr);

    return {
      agentAuditOptions: agentAuditOptions,
      campaignAuditOptions: campaignAuditOptions,
      dispositionAuditOptions: dispositionAuditOptions,
      skillOptions: skillOptions,
    };
  };

  return (
    <Container maxWidth="xl">
      <Grid container spacing={2}>
        <Grid item xs={10}>
          <div className="logo-container">
            <h1>Conversational Intelligence</h1>
          </div>
        </Grid>
        <Grid item xs={2}>
          <span className="loggedInuser font-user">USER:</span>
          <span className="font-user">
            {" "}
            {window.localStorage.getItem("user")} |{" "}
          </span>
          <span className="logout-title font-user" onClick={logoutUser}>
            Logout
          </span>
        </Grid>
        <Box
          sx={{
            width: "100%",
            typography: "body1",
            pointerEvents: load ? "none" : "auto",
          }}
          className={load ? "hidden-cus" : ""}
        >
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                {window.localStorage.getItem("user") === "allegiant" ? (
                  <Tab label="Conversational Analysis" value="1" />
                ) : null}
              </TabList>
            </Box>
            <TabPanel
              value="1"
              style={{ pointerEvents: load ? "none" : "auto" }}
            >
              <Grid container item spacing={2}>
                <div className="date-container">&nbsp;</div>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    renderInput={(props) => (
                      <TextField {...props} style={{ width: "124px" }} />
                    )}
                    disableFuture
                    hideTabs
                    showTodayButton
                    maxDate={moment().toDate()}
                    todayText="now"
                    label="Start Date"
                    value={startDateAudit}
                    onChange={(newValue) => {
                      setStartDateAudit(
                        String(moment(newValue).format("YYYY-MM-DD"))
                      );
                    }}
                  />
                </LocalizationProvider>
                <div></div>
                <div className="date-container">&nbsp;</div>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    renderInput={(props) => (
                      <TextField {...props} style={{ width: "124px" }} />
                    )}
                    label="End Date"
                    value={endDateAudit}
                    disableFuture
                    hideTabs
                    showTodayButton
                    maxDate={moment().toDate()}
                    todayText="now"
                    onChange={(newValue) => {
                      setEndDateAudit(
                        String(moment(newValue).format("YYYY-MM-DD"))
                      );
                    }}
                  />
                </LocalizationProvider>
                <div className="date-container">&nbsp;</div>
                <FormControl style={{ width: "125px" }}>
                  <InputLabel id="demo-multiple-camapaign">Campaign</InputLabel>
                  <Select
                    labelId="demo-multiple-campaign"
                    id="demo-multiple-checkbox"
                    multiple
                    value={campaignAuditVal}
                    onChange={handleChangeCampaignAudit}
                    input={<OutlinedInput label="CampaignAudit" />}
                    renderValue={(selected) =>
                      selected.map((x) => x.value).join(", ")
                    }
                    MenuProps={MenuProps}
                  >
                    {campaignAuditOptions.map((m) => (
                      <MenuItem key={m.label} value={m}>
                        <Checkbox
                          checked={
                            campaignAuditVal.findIndex(
                              (item) => item.label === m.label
                            ) >= 0
                          }
                        />
                        <ListItemText primary={m.value} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div className="date-container">&nbsp;</div>
                <FormControl style={{ width: "125px" }}>
                  <InputLabel id="demo-multiple-agent-audit">Agent</InputLabel>
                  <Select
                    labelId="demo-multiple-agent-audit"
                    id="demo-agent-audit"
                    multiple
                    value={agentAuditVal}
                    onChange={handleChangeAgentAudit}
                    input={<OutlinedInput label="AgentAudit" />}
                    renderValue={(selected) =>
                      selected.map((x) => x.value).join(", ")
                    }
                    MenuProps={MenuProps}
                  >
                    {agentAuditOptions.map((m) => (
                      <MenuItem key={m.label} value={m}>
                        <Checkbox
                          checked={
                            agentAuditVal.findIndex(
                              (item) => item.label === m.label
                            ) >= 0
                          }
                        />
                        <ListItemText primary={m.value} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div className="date-container">&nbsp;</div>
                <FormControl style={{ width: "125px" }}>
                  <InputLabel id="demo-multiple-disposition">
                    Disposition
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-disposition"
                    id="demo-multiple-checkbox"
                    multiple
                    value={dispositionAuditVal}
                    onChange={handleChangeDispositionAudit}
                    input={<OutlinedInput label="DispositionAudit" />}
                    renderValue={(selected) =>
                      selected.map((x) => x.value).join(", ")
                    }
                    MenuProps={MenuProps}
                  >
                    {dispositionAuditOptions.map((m) => (
                      <MenuItem key={m.label} value={m}>
                        <Checkbox
                          checked={
                            dispositionAuditVal.findIndex(
                              (item) => item.label === m.label
                            ) >= 0
                          }
                        />
                        <ListItemText primary={m.value} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <div className="date-container">&nbsp;</div>
                <FormControl style={{ width: "125px" }}>
                  <InputLabel id="demo-multiple-skill">Skill</InputLabel>
                  <Select
                    labelId="demo-multiple-skill"
                    id="demo-multiple-checkbox"
                    multiple
                    value={skillAuditVal}
                    onChange={handleChangeSkillAudit}
                    input={<OutlinedInput label="Skill" />}
                    renderValue={(selected) =>
                      selected.map((x) => x.value).join(", ")
                    }
                    MenuProps={MenuProps}
                  >
                    {skillOptions.map((m) => (
                      <MenuItem key={m.label} value={m}>
                        <Checkbox
                          checked={
                            skillAuditVal.findIndex(
                              (item) => item.label === m.label
                            ) >= 0
                          }
                        />
                        <ListItemText primary={m.value} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div className="date-container">&nbsp;</div>

                <div className="date-container">
                  &nbsp;
                  <br />
                </div>
                <FormControl>
                  <Button
                    variant="contained"
                    style={{ padding: "13px 8px", fontSize: "0.8rem" }}
                    onClick={() => {
                      fetchConversationData(
                        startDateAudit,
                        endDateAudit,
                        campaignAuditVal,
                        agentAuditVal,
                        dispositionAuditVal,
                        skillAuditVal
                      );
                    }}
                  >
                    Search
                  </Button>
                </FormControl>
                {load ? (
                  <div className="loader_digital" id="loader_digital"></div>
                ) : null}
              </Grid>
              <br></br>
              <div className="col-md-12 row">
                <div className="col-md-4">
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      marginLeft: -2,
                      "& > :not(style)": {
                        m: 0.5,
                        width: 115,
                        height: 60,
                      },
                    }}
                  >
                    <Paper className="aupage">
                      <b>Total Calls</b>
                      <br></br>
                      <div className="dapage">
                        {" "}
                        {jsonOpenAuditData["Total Calls"]}
                      </div>
                    </Paper>
                    <Paper className="aupage">
                      <b>Avg Score</b>
                      <br></br>
                      <div className="dapage">
                        {" "}
                        {jsonOpenAuditData["Average Score"]}
                      </div>
                    </Paper>
                    <Paper className="aupage">
                      <b>Total Agents</b>
                      <br></br>
                      <div className="dapage">
                        {" "}
                        {jsonOpenAuditData["Total Agents"]}
                      </div>
                    </Paper>
                  </Box>
                </div>
                <div className="col-md-8">
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "nowrap",
                      justifyContent: "space-evenly",
                      alignItems: "stretch",
                      "& > :not(style)": {
                        m: 0.5,
                        width: 245,
                        height: 135,
                      },
                    }}
                  >
                    <Paper className="aupage">
                      <b>Best Agents</b>
                      <br></br>
                      <br></br>
                      <ul
                        style={{
                          paddingLeft: "10px",
                          textAlign: "left",
                        }}
                      >
                        {jsonOpenAuditData["Best Agents"] &&
                          jsonOpenAuditData["Best Agents"].map((el, i) => (
                            <div key={i}>{el}</div>
                          ))}
                      </ul>
                    </Paper>
                    <Paper className="aupage">
                      <b>Need Improvement</b>
                      <br></br>
                      <br></br>
                      <ul
                        style={{
                          paddingLeft: "10px",
                          textAlign: "left",
                        }}
                      >
                        {jsonOpenAuditData["Needs Improvement"] &&
                          jsonOpenAuditData["Needs Improvement"].map(
                            (el, i) => <div key={i}>{el}</div>
                          )}
                      </ul>
                    </Paper>
                  </Box>
                </div>
              </div>
              <br></br>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <Box
                    sx={{
                      flexWrap: "wrap",
                      "& > :not(style)": {
                        m: 1,
                        width: 600,
                        height: 500,
                      },
                    }}
                  >
                    <Paper elevation={3} style={{ marginLeft: "-13px" }}>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={audGraphVal}
                          onChange={handleChangeAudGraph}
                        >
                          <FormControlLabel
                            value="campaign"
                            control={<Radio size="small" />}
                            editable="true"
                            label="Campaign"
                            checked={checkCampaign}
                          />
                          <FormControlLabel
                            value="disposition"
                            control={<Radio size="small" />}
                            label="Disposition"
                          />
                          <FormControlLabel
                            value="skill"
                            control={<Radio size="small" />}
                            label="Skill"
                          />
                          <FormControlLabel
                            value="agent"
                            control={<Radio size="small" />}
                            label="Agent"
                          />
                        </RadioGroup>
                      </FormControl>
                      {/* </Paper> */}

                      <div style={{ paddingTop: "20px" }}>
                        {Object.keys(dashboardAuditData).length !== 0 && (
                          <Grid container item spacing={3}>
                            {audGraphVal === "disposition" ? (
                              <Grid item xs={6}>
                                {" "}
                                <Paper
                                  elevation={3}
                                  sx={{
                                    width: "580px",
                                    height: "400px",
                                    marginLeft: "10px",
                                  }}
                                >
                                  <Bar
                                    options={
                                      dashboardAuditData[
                                        "dispositionScoreOptions"
                                      ]
                                    }
                                    data={
                                      dashboardAuditData["dispositionScoreData"]
                                    }
                                    plugins={[ChartDataLabels]}
                                  />
                                </Paper>
                              </Grid>
                            ) : audGraphVal === "skill" ? (
                              <Grid item xs={6}>
                                {" "}
                                <Paper
                                  elevation={3}
                                  sx={{
                                    width: "580px",
                                    height: "400px",
                                    marginLeft: "10px",
                                  }}
                                >
                                  <Bar
                                    options={
                                      dashboardAuditData["skillScoreOptions"]
                                    }
                                    data={dashboardAuditData["skillScoreData"]}
                                    plugins={[ChartDataLabels]}
                                  />
                                </Paper>
                              </Grid>
                            ) : audGraphVal === "agent" ? (
                              <Grid item xs={6}>
                                {" "}
                                <Paper
                                  elevation={3}
                                  sx={{
                                    width: "580px",
                                    height: "400px",
                                    marginLeft: "10px",
                                  }}
                                >
                                  <Bar
                                    options={
                                      dashboardAuditData["agentScoreOptions"]
                                    }
                                    data={dashboardAuditData["agentScoreData"]}
                                    plugins={[ChartDataLabels]}
                                  />
                                </Paper>
                              </Grid>
                            ) : (
                              <Grid item xs={6}>
                                {" "}
                                <Paper
                                  elevation={4}
                                  sx={{
                                    width: "580px",
                                    height: "400px",
                                    marginLeft: "10px",
                                  }}
                                >
                                  <Bar
                                    options={
                                      dashboardAuditData["campaignScoreOptions"]
                                    }
                                    data={
                                      dashboardAuditData["campaignScoreData"]
                                    }
                                    plugins={[ChartDataLabels]}
                                  />
                                </Paper>
                              </Grid>
                            )}
                          </Grid>
                        )}
                      </div>
                    </Paper>
                  </Box>
                </div>

                <br></br>
                <div>
                  <Box
                    sx={{
                      flexWrap: "wrap",
                      "& > :not(style)": {
                        m: 1,
                        width: 600,
                        height: 500,
                      },
                    }}
                  >
                    <Paper elevation={3} style={{ marginLeft: "-13px" }}>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={audBarVal}
                          onChange={handleChangeAudBar}
                        >
                          <FormControlLabel
                            value="campaign"
                            control={<Radio size="small" />}
                            editable="true"
                            label="Loudness By Campaign"
                            checked={checkBCampaign}
                          />
                          <FormControlLabel
                            value="agent"
                            control={<Radio size="small" />}
                            label="Loudness By Agent"
                          />
                        </RadioGroup>
                      </FormControl>

                      <div style={{ paddingTop: "20px" }}>
                        {Object.keys(dashboardAuditData).length !== 0 ? (
                          <Grid container spacing={3}>
                            <Grid item xs={6}>
                              {audBarVal === "campaign" ? (
                                <Grid item xs={6}>
                                  <Paper
                                    elevation={3}
                                    sx={{
                                      width: "580px",
                                      height: "400px",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    <Bar
                                      options={
                                        dashboardAuditData[
                                          "loudnessCampaignOptions"
                                        ]
                                      }
                                      data={
                                        dashboardAuditData[
                                          "loudnessCampaignBarData"
                                        ]
                                      }
                                      plugins={[ChartDataLabels]}
                                    />
                                  </Paper>
                                </Grid>
                              ) : (
                                <Grid item xs={6}>
                                  <Paper
                                    elevation={3}
                                    sx={{
                                      width: "580px",
                                      height: "400px",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    <Bar
                                      options={
                                        dashboardAuditData[
                                          "loudnessAgentBarOptions"
                                        ]
                                      }
                                      data={
                                        dashboardAuditData[
                                          "loudnessAgentBarData"
                                        ]
                                      }
                                      plugins={[ChartDataLabels]}
                                    />
                                  </Paper>
                                </Grid>
                              )}
                            </Grid>
                          </Grid>
                        ) : (
                          <Grid container item spacing={3}>
                            <label
                              style={{
                                marginLeft: "30px",
                                width: "410px",
                              }}
                            >
                              No Data For Analysis
                            </label>
                          </Grid>
                        )}
                      </div>
                    </Paper>
                  </Box>
                </div>
                <br></br>
              </div>
              <br></br>
              <div
                style={{
                  display: "flex",
                }}
              >
                <div>
                  <Box
                    sx={{
                      flexWrap: "wrap",
                      "& > :not(style)": {
                        m: 1,
                        width: 600,
                        height: 500,
                      },
                    }}
                  >
                    <Paper elevation={3} style={{ marginLeft: "-13px" }}>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={audStackVal}
                          onChange={handleChangeAudStackGraph}
                        >
                          <FormControlLabel
                            value="Participation-Ratio By Campaign"
                            control={<Radio size="small" />}
                            editable="true"
                            label="Participation-Ratio By Campaign"
                            checked={checkParticipation}
                          />
                          <FormControlLabel
                            value="Participation-Ratio By Agent"
                            control={<Radio size="small" />}
                            label="Participation-Ratio By Agent"
                          />
                          <FormControlLabel
                            value="Speaking-Rate By Agent"
                            control={<Radio size="small" />}
                            label="Speaking-Rate By Agent"
                          />
                          <FormControlLabel
                            value="Speaking-Rate By Campaign"
                            control={<Radio size="small" />}
                            label="Speaking-Rate By Campaign"
                          />
                        </RadioGroup>
                      </FormControl>

                      <div style={{ paddingTop: "20px" }}>
                        {Object.keys(dashboardAuditData).length !== 0 && (
                          <Grid container item spacing={3}>
                            {audStackVal === "Participation-Ratio By Agent" ? (
                              <Grid item xs={6}>
                                {" "}
                                <Paper
                                  elevation={3}
                                  sx={{
                                    width: "580px",
                                    height: "400px",
                                    marginLeft: "10px",
                                  }}
                                >
                                  <Bar
                                    options={
                                      dashboardAuditData[
                                        "participationAgentBarOptions"
                                      ]
                                    }
                                    data={
                                      dashboardAuditData[
                                        "participationAgentBarData"
                                      ]
                                    }
                                    plugins={[ChartDataLabels]}
                                  />
                                </Paper>
                              </Grid>
                            ) : audStackVal === "Speaking-Rate By Agent" ? (
                              <Grid item xs={6}>
                                {" "}
                                <Paper
                                  elevation={3}
                                  sx={{
                                    width: "580px",
                                    height: "400px",
                                    marginLeft: "10px",
                                  }}
                                >
                                  <Bar
                                    options={
                                      dashboardAuditData[
                                        "speakingRateAgentBarOptions"
                                      ]
                                    }
                                    data={
                                      dashboardAuditData[
                                        "speakingRateAgentBarData"
                                      ]
                                    }
                                    plugins={[ChartDataLabels]}
                                  />
                                </Paper>
                              </Grid>
                            ) : audStackVal === "Speaking-Rate By Campaign" ? (
                              <Grid item xs={6}>
                                {" "}
                                <Paper
                                  elevation={3}
                                  sx={{
                                    width: "580px",
                                    height: "400px",
                                    marginLeft: "10px",
                                  }}
                                >
                                  <Bar
                                    options={
                                      dashboardAuditData[
                                        "speakingCampaignOptions"
                                      ]
                                    }
                                    data={
                                      dashboardAuditData[
                                        "speakingCampaignBarData"
                                      ]
                                    }
                                    plugins={[ChartDataLabels]}
                                  />
                                </Paper>
                              </Grid>
                            ) : (
                              <Grid item xs={6}>
                                {" "}
                                <Paper
                                  elevation={4}
                                  sx={{
                                    width: "580px",
                                    height: "400px",
                                    marginLeft: "10px",
                                  }}
                                >
                                  <Bar
                                    options={
                                      dashboardAuditData[
                                        "participationCampaignOptions"
                                      ]
                                    }
                                    data={
                                      dashboardAuditData[
                                        "participationCampaignBarData"
                                      ]
                                    }
                                    plugins={[ChartDataLabels]}
                                  />
                                </Paper>
                              </Grid>
                            )}
                          </Grid>
                        )}
                      </div>
                    </Paper>
                  </Box>
                </div>
                {/* <br></br> */}
                <div style={{ marginLeft: "60px" }}>
                  <Box
                    sx={{
                      flexWrap: "wrap",
                      "& > :not(style)": {
                        m: 1,
                        width: 600,
                        height: 500,
                      },
                    }}
                  >
                    <Paper elevation={3} style={{ marginLeft: "-13px" }}>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={audStack2Val}
                          onChange={handleChangeAudStack2Graph}
                        >
                          <FormControlLabel
                            value="Interrupts By Campaign"
                            control={<Radio size="small" />}
                            editable="true"
                            label="Interrupts By Campaign"
                            checked={checkInterrupt}
                          />
                          <FormControlLabel
                            value="Interrupts By Agent"
                            control={<Radio size="small" />}
                            label="Interrupts By Agent"
                          />
                          <FormControlLabel
                            value="Interrupts Duration By Agent"
                            control={<Radio size="small" />}
                            label="Interrupts Duration By Agent"
                          />
                          <FormControlLabel
                            value="Interrupts Duration By Campaign"
                            control={<Radio size="small" />}
                            label="Interrupts Duration By Campaign"
                          />
                        </RadioGroup>
                      </FormControl>
                      <div style={{ paddingTop: "20px" }}>
                        {Object.keys(dashboardAuditData).length !== 0 && (
                          <Grid container item spacing={3}>
                            {audStack2Val === "Interrupts By Agent" ? (
                              <Grid item xs={6}>
                                {" "}
                                <Paper
                                  elevation={3}
                                  sx={{
                                    width: "580px",
                                    height: "400px",
                                    marginLeft: "10px",
                                  }}
                                >
                                  <Bar
                                    options={
                                      dashboardAuditData[
                                        "interruptsAgentBarOptions"
                                      ]
                                    }
                                    data={
                                      dashboardAuditData[
                                        "interruptsAgentBarData"
                                      ]
                                    }
                                    plugins={[ChartDataLabels]}
                                  />
                                </Paper>
                              </Grid>
                            ) : audStack2Val ===
                              "Interrupts Duration By Agent" ? (
                              <Grid item xs={6}>
                                {" "}
                                <Paper
                                  elevation={3}
                                  sx={{
                                    width: "580px",
                                    height: "400px",
                                    marginLeft: "10px",
                                  }}
                                >
                                  <Bar
                                    options={
                                      dashboardAuditData[
                                        "interruptDurationAgentBarOptions"
                                      ]
                                    }
                                    data={
                                      dashboardAuditData[
                                        "interruptDurationAgentBarData"
                                      ]
                                    }
                                    plugins={[ChartDataLabels]}
                                  />
                                </Paper>
                              </Grid>
                            ) : audStack2Val ===
                              "Interrupts Duration By Campaign" ? (
                              <Grid item xs={6}>
                                {" "}
                                <Paper
                                  elevation={3}
                                  sx={{
                                    width: "580px",
                                    height: "400px",
                                    marginLeft: "10px",
                                  }}
                                >
                                  <Bar
                                    options={
                                      dashboardAuditData[
                                        "interruptDurationCampaignOptions"
                                      ]
                                    }
                                    data={
                                      dashboardAuditData[
                                        "interruptDurationCampaignBarData"
                                      ]
                                    }
                                    plugins={[ChartDataLabels]}
                                  />
                                </Paper>
                              </Grid>
                            ) : (
                              <Grid item xs={6}>
                                {" "}
                                <Paper
                                  elevation={4}
                                  sx={{
                                    width: "580px",
                                    height: "400px",
                                    marginLeft: "10px",
                                  }}
                                >
                                  <Bar
                                    options={
                                      dashboardAuditData[
                                        "interruptsCampaignOptions"
                                      ]
                                    }
                                    data={
                                      dashboardAuditData[
                                        "interruptsCampaignBarData"
                                      ]
                                    }
                                    plugins={[ChartDataLabels]}
                                  />
                                </Paper>
                              </Grid>
                            )}
                          </Grid>
                        )}
                      </div>
                    </Paper>
                  </Box>
                </div>
              </div>
              <br></br>
              <Box
                sx={{
                  flexWrap: "wrap",
                  "& > :not(style)": {
                    m: 1,
                    width: 1275,
                    height: 555,
                  },
                }}
              >
                <Paper elevation={3} style={{ marginLeft: "-13px" }}>
                  <div style={{ paddingTop: "20px" }}>
                    {Object.keys(dashboardAuditData).length !== 0 ? (
                      <Grid container item spacing={3}>
                        <Grid item xs={6}>
                          {" "}
                          <Paper
                            elevation={3}
                            sx={{
                              width: "1240px",
                              height: "495px",
                              marginLeft: "20px",
                            }}
                          >
                            <Line
                              options={dashboardAuditData["timeScoreOptions"]}
                              data={dashboardAuditData["timeScoreData"]}
                              plugins={[ChartDataLabels]}
                            />
                          </Paper>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid container spacing={3}>
                        <label>No Data For Time-Score Analysis</label>
                      </Grid>
                    )}
                  </div>
                </Paper>
              </Box>
            </TabPanel>
          </TabContext>
        </Box>
      </Grid>
    </Container>
  );
}

export default SpeechAnalyticsNonVOC;
