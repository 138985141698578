import React from "react";
import SpeechDashboard from "../SpeechDashboard";
import SpeechAnalyticsNonVOC from "../SpeechAnalyticsNonVOC";
import SpeechDemoAudit from "../SpeechDemoAudit";
import SpeechDemoVOC from "../SpeechDemoVOC";
import ClearTaxDashBoard from "../ClearTaxDashboard";
import AdretDemo from "../AdretDashBoard";
import Adret1DashBoard from "../Adret1LiveDashBoard";
import WellnessDashBoard from "../WellnessDashBoard";
import LarsentoubroDashboard from "../LarsenDashBoard";
import PiramalDashBoard from "../PiramalDashBoards";
import RunwalDashBoard from "../RunwalDashBoard";
import AckoDashBoard from "../AckoDashBoard";
import HdbfsDashBoard from "../HdbfsDashBoard";
import HdbfsV2DashBoard from "../HDBFSV2Dashboard";
import HDBFSRecoveryDashBoard from "../HDBFSRecoveryDashboard";
import TataSteelDashboard from "../TataSteelDashBoard";

export default function ComponentRender() {
  const renderCmp = () => {
    let user = window.localStorage.getItem("user");
    let item = <SpeechDashboard />;
    if (user === "allegiant") {
      item = <SpeechAnalyticsNonVOC />;
    } else if (user === "speech_demo_audit") {
      item = <SpeechDemoAudit />;
    } else if (user === "clearTax") {
      item = <ClearTaxDashBoard />;
    } else if (user === "speech_demo_voc") {
      item = <SpeechDemoVOC />;
    } else if (user === "adret") {
      item = <AdretDemo />;
    } else if (user === "adret1") {
      item = <Adret1DashBoard />;
    } else if (user === "piramal") {
      item = <PiramalDashBoard />;
    } else if (user === "wellnessForever") {
      item = <WellnessDashBoard />;
    } else if (user === "larsentoubro") {
      item = <LarsentoubroDashboard />;
    } else if (user === "runwal") {
      item = <RunwalDashBoard />;
    } else if (user === "acko_inbound") {
      item = <AckoDashBoard />;
    } else if (user === "hdbfs") {
      item = <HdbfsDashBoard />;
    } else if (user === "tata_steel") {
      item = <TataSteelDashboard />;
    } else if (user === "hdbfs_process") {
      item = <HdbfsV2DashBoard />;
    }else if (user === "hdbfs_recovery") {
      item = < HDBFSRecoveryDashBoard/>;
    }

    return item;
  };
  return <div>{renderCmp()}</div>;
}
