import * as React from "react";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Card, Popover, TablePagination, Button } from "@mui/material";
import { useState, useRef } from "react";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import _ from "lodash";
import "../App.css";
import * as images from "../utils/images/images.component";
import { AgentDataDetails } from "./AgentDataDetails";
import { useDownloadExcel } from "react-export-table-to-excel";

interface Column {
  id:
    | "agent_names"
    | "score_count"
    | "call_opening"
    | "call_closing"
    | "hold_dead_air_probing"
    | "customer_profiling_effective_probing"
    | "discount_offered"
    | "upselling_pitch_for_pack_upgrade"
    | "sales_closure_or_sales_rebuttals"
    | "product_presentation"
    | "empathy"
    | "speech_rate_and_clarity"
    | "active_listening"
    | "courteous_and_professionality"
    | "conversational_ability"
    | "tone";
  label: string;
  minWidth?: number;
  align?: "right";
}
interface Column_Poonawalla {
  id:
    | "agent_names"
    | "score_count"
    | "energetic_opening"
    | "acknowledgement_comprehension"
    | "closure_script_further_assistance"
    | "hold_script";
  label: string;
  minWidth?: number;
  align?: "right";
}

interface Column_Matrimony {
  id:
    | "agent_names"
    | "call_opening"
    | "effective_probing"
    | "interest_creation"
    | "objection_handling"
    | "package_pitch"
    | "convincing_and_need_creation"
    | "product_usp"
    | "price_pitch_and_discount"
    | "utilize_opportunities"
    | "customer_engagement"
    | "active_listening"
    | "tone_and_voice_modulation"
    | "confidence_and_enthusiasm"
    | "urgency_creation"
    | "summarization_and_call_closing";
  label: string;
  minWidth?: number;
  align?: "right";
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: 10,
    padding: 10,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 10,
    padding: 10,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const columns: readonly Column[] = [
  { id: "agent_names", label: "Agent Names", minWidth: 350 },
  { id: "score_count", label: "Score", minWidth: 100 },
  { id: "call_opening", label: "Call Opening", minWidth: 100 },
  { id: "call_closing", label: "Call Closing", minWidth: 100 },
  {
    id: "hold_dead_air_probing",
    label: "Hold Dead Air Probing",
    minWidth: 150,
  },
  {
    id: "customer_profiling_effective_probing",
    label: "Customer Profiling Effective Probing",
    minWidth: 150,
  },
  { id: "discount_offered", label: "Discount Offered", minWidth: 150 },
  {
    id: "upselling_pitch_for_pack_upgrade",
    label: "Upselling Pitch for Pack Upgrade",
    minWidth: 150,
  },
  {
    id: "sales_closure_or_sales_rebuttals",
    label: "Sales Closure or Sales Rebuttals",
    minWidth: 150,
  },
  { id: "product_presentation", label: "Product Presentation" },
  { id: "empathy", label: "Empathy", minWidth: 100 },
  {
    id: "speech_rate_and_clarity",
    label: "Speech Rate and Clarity",
    minWidth: 120,
  },
  { id: "active_listening", label: "Active Listening", minWidth: 150 },
  {
    id: "courteous_and_professionality",
    label: "Courteous and Professionality",
    minWidth: 120,
  },
  {
    id: "conversational_ability",
    label: "Conversational Ability",
    minWidth: 120,
  },
  { id: "tone", label: "Tone", minWidth: 150 },
];
const columns_poonawalla: readonly Column_Poonawalla[] = [
  { id: "agent_names", label: "Agent ID", minWidth: 350 },
  { id: "energetic_opening", label: "Energetic Opening", minWidth: 300 },
  {
    id: "acknowledgement_comprehension",
    label: "Acknowledgement Comprehensive",
    minWidth: 300,
  },
  {
    id: "closure_script_further_assistance",
    label: "Closure Script Further Assistance",
    minWidth: 300,
  },
  { id: "hold_script", label: "Hold Script", minWidth: 150 },
];

const columns_matrimony: readonly Column_Matrimony[] = [
  { id: "agent_names", label: "Agent ID", minWidth: 150 },
  { id: "call_opening", label: "Call Opening", minWidth: 150 },
  { id: "effective_probing", label: "Effective Probing", minWidth: 150 },
  { id: "interest_creation", label: "Interest Creation", minWidth: 150 },
  { id: "objection_handling", label: "Object Handling", minWidth: 150 },
  { id: "package_pitch", label: "Package Pitch", minWidth: 150 },
  {
    id: "convincing_and_need_creation",
    label: "Convincing and Need Creation",
    minWidth: 150,
  },
  { id: "product_usp", label: "Product USP", minWidth: 150 },
  {
    id: "price_pitch_and_discount",
    label: "Price Pitch and Discount",
    minWidth: 150,
  },
  {
    id: "utilize_opportunities",
    label: "Utilize Opportunities",
    minWidth: 150,
  },
  { id: "customer_engagement", label: "Customer Engagement", minWidth: 150 },
  { id: "active_listening", label: "Active Listening", minWidth: 150 },
  {
    id: "tone_and_voice_modulation",
    label: "Tone and Voice Modulation",
    minWidth: 150,
  },
  {
    id: "confidence_and_enthusiasm",
    label: "Confidence and Enthusiasm",
    minWidth: 150,
  },
  { id: "urgency_creation", label: "Urgency Creation", minWidth: 150 },
  {
    id: "summarization_and_call_closing",
    label: "Summarization and Call Closing",
    minWidth: 150,
  },
];

let metaArr: any[] = [];

const getData = () => {
  metaArr = [];
  Object.values(HitsArr).forEach((data) => {
    
    const obj = JSON.parse(JSON.stringify(data));
    
    metaArr.push(obj);
  });
  console.log("original meta array is", metaArr);
  // setTableData(metaArr);
  return metaArr;
};

type Props = {
  fetchAudit: any;
};

let HitsArr = {};

axios.defaults.headers.common["user"] = localStorage.getItem("user");

export default function AuditTable({ fetchAudit }: Props) {
  let agentAggregationObj: any = {};
  let avgDurationObj: any = {};
  let agentCountArr: any[] = [];
  let avgDurationArr: any[] = [];

  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [eleRefPoint, setElRefPoint] = useState<any>(null);
  const [responseData, setResponseData] = useState<any>();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [sortColumn, setSortColumn] = useState("");
  const [agentCountFinal, setAgentCountFinal] = useState<any>([]);
  const [avgDurationFinal, setAvgDurationFinal] = useState<any>([]);
  const [order, setOrder] = useState("");
  // const [rows, setRows] = useState<Data[]>([]);
  const [tableData, setTableData] = useState<any>([]);
  console.log("order is auditttttt", order);
  const tableRef = useRef(null);
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: String(localStorage.getItem("user")) + "_AUDIT",
    sheet: "Users",
  });
  const findName = (name: any) => {
    console.log("Name is ", name);
    if (fetchAudit[0].aggregations !== undefined) {
      for (const element of fetchAudit[0].aggregations) {
        console.log("Element is audit", element);
        if (element["name"] === name) {
          return element;
        }
      }
    }
  };
  const handleOnPopoverClose = () => {
    setElRefPoint(null);
    setIsPopoverOpen(false);
  };
  const getPopoverContent = () => {
    return (
      <AgentDataDetails
        getAgentData={[responseData, agentCountFinal, avgDurationFinal]}
      ></AgentDataDetails>
    );
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    console.log("page is rows ", page);
    var from = newPage === 0 ? 0 : newPage * rowsPerPage + 1;
    var size = rowsPerPage;
    fetchAudit[1](
      fetchAudit[2].startDateAudit,
      fetchAudit[2].endDateAudit,
      fetchAudit[2].campaignAuditVal,
      fetchAudit[2].agentAuditVal,
      fetchAudit[2].dispositionAuditVal,
      fetchAudit[2].skillAuditVal,
      fetchAudit[2].scoreInputVal,
      fetchAudit[2].scoreAuditVal,
      size,
      from
    );
    console.log("page is audit ", from);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    fetchAudit[1](
      fetchAudit[2].startDateAudit,
      fetchAudit[2].endDateAudit,
      fetchAudit[2].campaignAuditVal,
      fetchAudit[2].agentAuditVal,
      fetchAudit[2].dispositionAuditVal,
      fetchAudit[2].skillAuditVal,
      fetchAudit[2].scoreInputVal,
      fetchAudit[2].scoreAuditVal,
      parseInt(event.target.value, 10),
      "0"
    );
  };

  if (fetchAudit !== undefined && fetchAudit.length !== 0) {
    if (fetchAudit[0]["Table Agg"] !== undefined) {
      console.log("isOpen valueis ----", fetchAudit[0]["Table Agg"]);
      HitsArr = fetchAudit[0]["Table Agg"];
      console.log("HITS ARRAY IS", HitsArr);
    }
  }
  const agentAggregation = findName("Agent Count");
  console.log("Agent fin name", agentAggregation);
  if (agentAggregation !== undefined) {
    agentAggregation["labels"].forEach((element: any, index: any) => {
      agentAggregationObj[element] = agentAggregation["data"][index];
    });
  }
  Object.entries(agentAggregationObj).forEach((data) => {
    agentCountArr.push({ label: data[0], value: data[1] });
  });

  const avgDurationAggregation = findName("avg_duration");
  if (avgDurationAggregation !== undefined) {
    avgDurationAggregation["labels"].forEach((element: any, index: any) => {
      avgDurationObj[element] = avgDurationAggregation["data"][index];
    });
  }
  Object.entries(avgDurationObj).forEach((data) => {
    avgDurationArr.push({ label: data[0], value: data[1] });
  });
  const agentDataDetails = (event: any) => {
    setElRefPoint({ top: event.clientY, left: event.clientX });
    setIsPopoverOpen(true);
    getPopoverContent();
  };
  let res: never[] = [];
  const getAgentData = async (value: any, e: any) => {
    // const res = await axios.get(chatTranscriptUrl, { params: { url: value.value } });
    // setResponseData(res.data);
    console.log("Value in audit table ", value, "evet in audit table is", e);
    tableData.forEach((val: any) => {
      if (val.agent_names === value.value) {
        res = val;
        setResponseData(val);
        // console.log("Val to be forwarded is",val,"response data is",responseData);
      }
    });
    agentCountArr.forEach((val: any) => {
      if (val.label == value.value) {
        setAgentCountFinal(val.value);
      }
    });
    avgDurationArr.forEach((val: any) => {
      if (val.label === value.value) {
        let trimVal = val.value.toFixed(1);
        setAvgDurationFinal(trimVal);
      }
    });
    agentDataDetails(e);

    return res;
  };
  console.log("response forwardding audit*****", res);
  const handleSortingChange = (sortField: any) => {
    console.log("Accessor isssss****", sortField);
    const sortOrder = order === "asc" ? "desc" : "asc";
    setSortColumn(sortField);
    setOrder(sortOrder);
    console.log("Sorting order is", sortOrder, "sorting field", sortField);
    if (sortField !== null || !sortField.equals("0") || sortField !== undefined) {
      console.log("Im inside sort");
      const sorted = [...metaArr].sort((a, b) => {
        console.log("inside sorted a is", a);
        console.log("inside sorted b is", b);
        if (a[sortField] === null) return 1;
        if (b[sortField] === null) return -1;
        if (a[sortField] === null && b[sortField] === null) return 0;
        return (
          a[sortField].toString().localeCompare(b[sortField].toString(), "en", {
            numeric: true,
          }) * (sortOrder === "asc" ? 1 : -1)
        );
      });
      console.log("Sorted Rows and columns data", sorted);
      setTableData(sorted);
    }
  };

  React.useEffect(() => {
    setTableData(getData());
  }, [fetchAudit]);

  return (
    <Card>
      <Paper>
        <TableContainer component={Paper} ref={tableRef}>
          <Table aria-label="customized table">
            <TableHead sx={{ backgroundColor: "yellow" }}>
              <TableRow>
                {localStorage.getItem("user") == "redcliffe_lab" ? (
                  <>
                    {columns.map((column, accessor) => {
                      return (
                        <StyledTableCell
                          key={accessor}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                            backgroundColor: "#1976d2",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                          onClick={() => handleSortingChange(column.id)}
                        >
                          {column.label}
                          {order === "asc" ? (
                            <img
                              alt="upArrow"
                              style={{ height: "12px", paddingLeft: "5px" }}
                              src={images.sortAsc}
                            />
                          ) : order == "" ? null : (
                            <img
                              alt="downArrow"
                              style={{ height: "12px" }}
                              src={images.sortDsc}
                            />
                          )}
                        </StyledTableCell>
                      );
                    })}
                  </>
                ) : localStorage.getItem("user") === "poonawalla_fincorp" ? (
                  <>
                    {columns_poonawalla.map((column, accessor) => {
                      return (
                        <StyledTableCell
                          key={accessor}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                            backgroundColor: "#1976d2",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                          onClick={() => handleSortingChange(column.id)}
                        >
                          {column.label}
                          {order === "asc" ? (
                            <img
                              alt="upArrow"
                              style={{ height: "12px", paddingLeft: "5px" }}
                              src={images.sortAsc}
                            />
                          ) : order == "" ? null : (
                            <img
                              alt="downArrow"
                              style={{ height: "12px" }}
                              src={images.sortDsc}
                            />
                          )}
                        </StyledTableCell>
                      );
                    })}
                  </>
                ) : localStorage.getItem("user") === "matrimony" ? (
                  <>
                    {columns_matrimony.map((column, accessor) => {
                      return (
                        <StyledTableCell
                          key={accessor}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                            backgroundColor: "#1976d2",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                          onClick={() => handleSortingChange(column.id)}
                        >
                          {column.label}
                          {order === "asc" ? (
                            <img
                              alt="upArrow"
                              style={{ height: "12px", paddingLeft: "5px" }}
                              src={images.sortAsc}
                            />
                          ) : order == "" ? null : (
                            <img
                              alt="downArrow"
                              style={{ height: "12px" }}
                              src={images.sortDsc}
                            />
                          )}
                        </StyledTableCell>
                      );
                    })}
                  </>
                ) : null}
              </TableRow>
            </TableHead>
            <TableBody>
              {localStorage.getItem("user") == "redcliffe_lab" ? (
                <>
                  {tableData.map((row: any, rowIdx: any) => {
                    console.log("row audit data is", row);
                    return (
                      <StyledTableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.SNO}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          console.log("Value data is", value);
                          if (column.id === "agent_names") {
                            return (
                              <StyledTableCell
                                className="sub-color"
                                key={column.id + rowIdx}
                                align={column.align}
                              >
                                <Button
                                  variant="text"
                                  onClick={(e) => getAgentData({ value }, e)}
                                >
                                  {value}
                                </Button>
                              </StyledTableCell>
                            );
                          } else {
                            return (
                              <StyledTableCell
                                key={column.id + rowIdx}
                                align={column.align}
                              >
                                {value}
                              </StyledTableCell>
                            );
                          }
                        })}
                      </StyledTableRow>
                    );
                  })}
                </>
              ) : localStorage.getItem("user") === "poonawalla_fincorp" ? (
                <>
                  {tableData.map((row: any, rowIdx: any) => {
                    console.log("row audit data is", row);
                    return (
                      <StyledTableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.SNO}
                      >
                        {columns_poonawalla.map((column) => {
                          const value = row[column.id];
                          console.log("Value data is", value);
                          if (column.id === "agent_names") {
                            return (
                              <StyledTableCell
                                className="sub-color"
                                key={column.id + rowIdx}
                                align={column.align}
                              >
                                <Button
                                  variant="text"
                                  onClick={(e) => getAgentData({ value }, e)}
                                >
                                  {value}
                                </Button>
                              </StyledTableCell>
                            );
                          } else {
                            return (
                              <StyledTableCell
                                key={column.id + rowIdx}
                                align={column.align}
                              >
                                {value}
                              </StyledTableCell>
                            );
                          }
                        })}
                      </StyledTableRow>
                    );
                  })}
                </>
              ) : localStorage.getItem("user") === "matrimony" ? (
                <>
                  {tableData.map((row: any, rowIdx: any) => {
                    console.log("row audit data is", row);
                    return (
                      <StyledTableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.SNO}
                      >
                        {columns_matrimony.map((column) => {
                          const value = row[column.id];
                          console.log("Value data is", value);
                          if (column.id === "agent_names") {
                            return (
                              <StyledTableCell
                                className="sub-color"
                                key={column.id + rowIdx}
                                align={column.align}
                              >
                                <Button
                                  variant="text"
                                  onClick={(e) => getAgentData({ value }, e)}
                                >
                                  {value}
                                </Button>
                              </StyledTableCell>
                            );
                          } else {
                            return (
                              <StyledTableCell
                                key={column.id + rowIdx}
                                align={column.align}
                              >
                                {value}
                              </StyledTableCell>
                            );
                          }
                        })}
                      </StyledTableRow>
                    );
                  })}
                </>
              ) : null}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={
            fetchAudit[0]["Total Agents"] > 0
              ? fetchAudit[0]["Total Agents"]
              : 0
          } // This is what your request should be returning in addition to the current page of rows.
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Popover
        open={isPopoverOpen}
        onClose={handleOnPopoverClose}
        anchorReference="anchorPosition"
        anchorPosition={eleRefPoint}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        <span
          className="cs-modal-close"
          onClick={() => {
            handleOnPopoverClose();
          }}
        >
          X
        </span>
        {getPopoverContent()}
      </Popover>
      <Button
        style={{ float: "right", marginTop: "-10px", marginRight: "22px" }}
        onClick={() => onDownload()}
      >
        Download
      </Button>
    </Card>
  );
}
