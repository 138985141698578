import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import axios from "axios";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Card, Popover, TablePagination, Button } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import _ from "lodash";
import "../App.css";
import * as XLSX from "xlsx";
import "../components/loader.css";
import * as images from "../utils/images/images.component";
import { AgentDataDetails } from "./AgentDataDetails";
import "../App.css";

interface Column {
  id:
    | "agent"
    | "ucid"
    | "score"
    | "call_opening"
    | "call_closing"
    | "information_gathering"
    | "providing_project_information"
    | "site_visit_pitch"
    | "rate_of_speech"
    | "volume"
    | "noisy_calls"
    | "interruptions"
    | "participation_ratio"
    | "dead_air"
    | "campaign"
    | "audio_url"
    | "disposition"
    | "skill"
    | "call_type"
    | "call_duration"
    | "call_date"
    | "language";
  label: string;
  minWidth?: number;
  align?: "right";
}
interface DataItem {
  [key: string]: string;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: 10,
    padding: 10,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 10,
    padding: 10,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const columns: readonly Column[] = [
  { id: "audio_url", label: "Audio", minWidth: 150 },
  { id: "call_date", label: "Call Date", minWidth: 100 },
  { id: "language", label: "Language", minWidth: 100 },
  { id: "agent", label: "Agent Name", minWidth: 150 },
  { id: "ucid", label: "UCID", minWidth: 100 },
  { id: "campaign", label: "Campaign", minWidth: 120 },
  { id: "disposition", label: "Disposition", minWidth: 120 },
  { id: "call_type", label: "Call Type", minWidth: 120 },
  { id: "score", label: "Score", minWidth: 100 },
  { id: "call_duration", label: "Call Duration", minWidth: 100 },
  { id: "call_opening", label: "Call Opening", minWidth: 100 },
  { id: "call_closing", label: "Call Closing", minWidth: 100 },
  { id: "dead_air", label: "Dead Air", minWidth: 120 },
  { id: "volume", label: "Volume", minWidth: 150 },
  { id: "noisy_calls", label: "Noisy Calls", minWidth: 100 },
  { id: "interruptions", label: "Interruptions", minWidth: 120 },
  { id: "participation_ratio", label: "Participation Ratio", minWidth: 120 },
  { id: "site_visit_pitch", label: "Site Visit Pitch", minWidth: 150 },
  { id: "rate_of_speech", label: "Rate of Speech", minWidth: 150 },
  {
    id: "information_gathering",
    label: "Information Gathering",
    minWidth: 150,
  },
  {
    id: "providing_project_information",
    label: "Providing Project Information",
    minWidth: 150,
  },
];

let metaArr: any[] = [];

const getData = () => {
  metaArr = [];
  Object.values(HitsArr).forEach((data) => {
    const obj = JSON.parse(JSON.stringify(data));
    metaArr.push(obj);
  });
  return metaArr;
};

type Props = {
  fetchCalls: any;
};

let HitsArr = {};
let downloadArr: any[] = [];

axios.defaults.headers.common["user"] = localStorage.getItem("user");

export default function OpensearchCallsTable({ fetchCalls }: Props) {
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [eleRefPoint, setElRefPoint] = useState<any>(null);
  const [responseData, setResponseData] = useState<any>();
  const [downloadData, setDownloadData] = useState<any>();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [sortColumn, setSortColumn] = useState("");
  const [agentCountFinal, setAgentCountFinal] = useState<any>([]);
  const [avgDurationFinal, setAvgDurationFinal] = useState<any>([]);
  const [order, setOrder] = useState("");
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState<any>([]);
  // const [currentlyPlaying, setCurrentlyPlaying] = useState<string | null>(null);
  // const [anchorAudio, setAnchorAudio] = React.useState<null | HTMLElement>(
  //   null
  // );
  const tableRef = useRef(null);
  // const audioRefs = useRef<{ [key: string]: HTMLAudioElement | null }>({});
  const sobhaCallsTabDownloadUrl =
    process.env.REACT_APP_BASE_URL + "/shobha/getCallsDataDownload";

  //   const findName = (name: any) => {
  //     // console.log("Name is ", name);
  //     if (fetchCalls[0].aggregations != undefined) {
  //       for (const element of fetchCalls[0].aggregations) {
  //         // console.log("Element is audit", element);
  //         if (element["name"] === name) {
  //           return element;
  //         }
  //       }
  //     }
  //   };

  // const handlePlay = (audioUrl: string) => {
  //   if (
  //     currentlyPlaying &&
  //     currentlyPlaying !== audioUrl &&
  //     audioRefs.current[currentlyPlaying]
  //   ) {
  //     const prevAudio = audioRefs.current[currentlyPlaying];
  //     if (prevAudio) {
  //       prevAudio.pause();
  //     }
  //     const audioElement = audioRefs.current[audioUrl];
  //     if (audioElement) {
  //       audioElement.play();
  //     }
  //   }
  //   setCurrentlyPlaying(audioUrl);
  // };

  // const handlePause = (audioUrl: string) => {
  //   const audioElement = audioRefs.current[audioUrl];
  //   if (audioElement) {
  //     audioElement.pause();
  //   }
  //   setCurrentlyPlaying(null);
  // };
  const convertToTitleCase = (str: string) => {
    return str.replace(/_/g, " ").replace(/\w\S*/g, (word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
  };
  const convertKeysToTitleCase = (obj: DataItem) => {
    const newObj: DataItem = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        newObj[convertToTitleCase(key)] = obj[key];
      }
    }
    return newObj;
  };
  const exportToExcel = (data: any) => {
    // Check if data is defined and is an array
    if (!data || !Array.isArray(data) || data.length === 0) {
      console.error("Invalid or empty data array");
      return;
    }
    // Convert keys to camel case for each object in the array
    const camelCaseData = data.map((obj) => convertKeysToTitleCase(obj));
    // Check if camelCaseData is defined and is an array
    if (
      !camelCaseData ||
      !Array.isArray(camelCaseData) ||
      camelCaseData.length === 0
    ) {
      console.error("Invalid or empty camelCaseData array");
      return;
    }
    const worksheet = XLSX.utils.json_to_sheet(camelCaseData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    // Save the workbook as an XLSX file
    XLSX.writeFile(
      workbook,
      String(localStorage.getItem("user")) + "_Calls.xlsx"
    );
  };
  interface LanguageMapping {
    [key: string]: string;
  }

  const getLanguageName = (languageCode: string): string => {
    const languageMapping: LanguageMapping = {
      "en-IN": "English",
      "ta-IN": "Tamil",
      "ml-IN": "Malayalam",
      "mr-IN": "Marathi",
      "hi-IN": "Hindi",
      "te-IN": "Telugu",
      "kn-IN": "Kannada",
      "gu-IN": "Gujarati",
      // Add more language codes and names as needed
    };
    return languageMapping[languageCode] || "Language not found";
  };

  const getLanguageCode = (languageName: string): string => {
    const languageMapping: LanguageMapping = {
      "English": "en-IN",
      "Tamil": "ta-IN",
      "Malayalam": "ml-IN",
      "Marathi": "mr-IN",
      "Hindi": "hi-IN",
      "Telugu": "te-IN",
      "Kannada": "kn-IN",
      "Gujarati": "gu-IN",
      // Add more language codes and names as needed
    };
    return languageMapping[languageName] || "0";
  };

  const downloadDataCall = async () => {
    try {
      setLoading(true);
      const response = await axios.get(sobhaCallsTabDownloadUrl, {
        params: {
          startDateAudit: fetchCalls[2].startDateAudit,
          endDateAudit: fetchCalls[2].endDateAudit,
          campaignAuditVal: fetchCalls[2].campaignAuditVal,
          agentAuditVal: fetchCalls[2].agentAuditVal,
          languageAuditVal: !_.isEqual(fetchCalls[2].languageAuditVal,"0") ?
           getLanguageCode(fetchCalls[2].languageAuditVal):fetchCalls[2].languageAuditVal,
          dispositionAuditVal: fetchCalls[2].dispositionAuditVal,
          skillAuditVal: fetchCalls[2].skillAuditVal,
          scoreInputVal: fetchCalls[2].scoreInputVal,
          scoreAuditVal: fetchCalls[2].scoreAuditVal,
          size: fetchCalls[0].dataCount,
          from: 0,
        },
        timeout: 180000,
      });
      const data = response && response.data && response.data.Hits;
      downloadArr = [];
      Object.values(data).forEach((rowData: any) => {
        const orderedRow: any = {};
        // Iterate through columns to ensure the order
        columns.forEach((column) => {
          const { id } = column;
          orderedRow[id] = rowData.hasOwnProperty(id)
            ? JSON.parse(JSON.stringify(rowData[id]))
            : undefined;
          // Additional processing for specific columns if needed
          if (id === "language" && orderedRow[id] !== undefined) {
            orderedRow[id] = JSON.parse(
              JSON.stringify(getLanguageName(orderedRow[id]))
            );
          }
          // Add more conditions for other columns if needed
        });
        downloadArr.push(orderedRow);
      });
      console.log(downloadArr);
      setDownloadData(downloadArr);
      exportToExcel(downloadArr);
    } catch (error) {
      downloadArr = [];
      setLoading(false); // Set isLoading to false when done or if an error occurs
      console.log(error);
      window.alert("Error Calling Api to Download");
    } finally {
      downloadArr = [];
      setLoading(false); // Set isLoading to false when done or if an error occurs
    }
  };

  const handleDownload = (e: any) => {
    downloadDataCall();
  };

  const handleOnPopoverClose = () => {
    setElRefPoint(null);
    setIsPopoverOpen(false);
  };
  const getPopoverContent = () => {
    if (
      !_.isEmpty(responseData) &&
      !_.isEmpty(agentCountFinal) &&
      !_.isEmpty(avgDurationFinal)
    ) {
      return (
        <AgentDataDetails
          getAgentData={[responseData, agentCountFinal, avgDurationFinal]}
        ></AgentDataDetails>
      );
    } else {
      return <AgentDataDetails></AgentDataDetails>;
    }
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setTableData([]);
    setPage(newPage);
    var from = newPage === 0 ? 0 : newPage * rowsPerPage;
    var size = rowsPerPage;
    fetchCalls[1](
      fetchCalls[2].startDateAudit,
      fetchCalls[2].endDateAudit,
      fetchCalls[2].campaignAuditVal,
      fetchCalls[2].agentAuditVal,
      fetchCalls[2].languageAuditVal,
      fetchCalls[2].dispositionAuditVal,
      fetchCalls[2].skillAuditVal,
      fetchCalls[2].scoreInputVal,
      fetchCalls[2].scoreAuditVal,
      size,
      from
    );
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTableData([]);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    var size = rowsPerPage;
    fetchCalls[1](
      fetchCalls[2].startDateAudit,
      fetchCalls[2].endDateAudit,
      fetchCalls[2].campaignAuditVal,
      fetchCalls[2].agentAuditVal,
      fetchCalls[2].languageAuditVal,
      fetchCalls[2].dispositionAuditVal,
      fetchCalls[2].skillAuditVal,
      fetchCalls[2].scoreInputVal,
      fetchCalls[2].scoreAuditVal,
      parseInt(event.target.value, 10),
      "0"
    );
  };

  if (fetchCalls != undefined && fetchCalls.length != 0) {
    if (fetchCalls[0]["Hits"] != undefined) {
      HitsArr = fetchCalls[0]["Hits"];
    }
  }

  useEffect(() => {
    setTableData(getData());
  }, [fetchCalls]);

  return (
    <Card>
      <Paper>
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead sx={{ backgroundColor: "yellow" }}>
              <TableRow>
              {localStorage.getItem("user") === "sobha_limited" || 
                localStorage.getItem("user") === "speech_demo_audit" ? (
                  <>
                    {columns.map((column, accessor) => {
                      return (
                        <StyledTableCell
                          key={accessor}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                            backgroundColor: "#1976d2",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                          // onClick={() => handleSortingChange(column.id)}
                        >
                          {column.label}
                          {order === "asc" ? (
                            <img
                              alt="upArrow"
                              style={{ height: "12px", paddingLeft: "5px" }}
                              src={images.sortAsc}
                            />
                          ) : order == "" ? null : (
                            <img
                              alt="downArrow"
                              style={{ height: "12px" }}
                              src={images.sortDsc}
                            />
                          )}
                        </StyledTableCell>
                      );
                    })}
                  </>
                ) : null}
              </TableRow>
            </TableHead>
            <TableBody>
            {localStorage.getItem("user") === "sobha_limited"  ? (
                <>
                  {tableData &&
                    tableData.map((row: any, rowIdx: any) => {
                      return (
                        <StyledTableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.SNO}
                        >
                          {columns.map((column, keyId) => {
                            if (column.id === "audio_url") {
                              return (
                                <StyledTableCell
                                  className="sub-color"
                                  key={column.id + rowIdx + keyId}
                                  align={column.align}
                                >
                                  {audioFile(row.audio_url)}
                                </StyledTableCell>
                              );
                            }
                            if (column.id === "language") {
                              return (
                                <StyledTableCell
                                  key={column.id + rowIdx + keyId}
                                  align={column.align}
                                >
                                  {getLanguageName(row[column.id])}
                                </StyledTableCell>
                              );
                            } else {
                              return (
                                <StyledTableCell
                                  key={column.id + rowIdx + keyId}
                                  align={column.align}
                                >
                                  {row[column.id]}
                                </StyledTableCell>
                              );
                            }
                          })}
                        </StyledTableRow>
                      );
                    })}
                </>
              ) : localStorage.getItem("user") === "speech_demo_audit" ? (
                <>
                  {tableData &&
                    tableData.map((row: any, rowIdx: any) => {
                      return (
                        <StyledTableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.SNO}
                        >
                          {columns.map((column, keyId) => {
                            if (column.id === "audio_url") {
                              return (
                                <StyledTableCell
                                  className="sub-color"
                                  key={column.id + rowIdx + keyId}
                                  align={column.align}
                                >
                                  {audioFile("")}
                                </StyledTableCell>
                              );
                            }
                            if (column.id === "language") {
                              return (
                                <StyledTableCell
                                  key={column.id + rowIdx + keyId}
                                  align={column.align}
                                >
                                  {getLanguageName(row[column.id])}
                                </StyledTableCell>
                              );
                            } else {
                              return (
                                <StyledTableCell
                                  key={column.id + rowIdx + keyId}
                                  align={column.align}
                                >
                                  {row[column.id]}
                                </StyledTableCell>
                              );
                            }
                          })}
                        </StyledTableRow>
                      );
                    })}
                </>
              ) : null}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[50, 100, 250]}
          component="div"
          count={
            fetchCalls[0]["dataCount"] > 0 ? fetchCalls[0]["dataCount"] : 0
          } // This is what your request should be returning in addition to the current page of rows.
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Popover
        open={isPopoverOpen}
        onClose={handleOnPopoverClose}
        anchorReference="anchorPosition"
        anchorPosition={eleRefPoint}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        <span
          className="cs-modal-close"
          onClick={(e) => {
            handleOnPopoverClose();
          }}
        >
          X
        </span>
        {getPopoverContent()}
      </Popover>
      {loading ? (
        <div className="loader_digital" id="loader_digital"></div>
      ) : (
        <Button
          style={{ float: "right", marginTop: "-10px", marginRight: "22px" }}
          onClick={handleDownload}
        >
          Download
        </Button>
      )}
    </Card>
  );
}

function audioFile(url: string) {
  const aud = <audio controls controlsList="nodownload" src={url}></audio>;
  return aud;
}
