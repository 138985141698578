import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Card, Popover, TablePagination, Button } from '@mui/material';
import { useState, useRef } from 'react';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import _ from 'lodash';
import "../App.css";
import * as images from '../utils/images/images.component';
import { AgentOutboundDataDetails } from './AgentOutboundDataDetails';
import { useDownloadExcel } from 'react-export-table-to-excel';


interface Column {
  id: 'agent_names'|'score_count'|'call_opening' | 'customer_identification' | 'purpose_of_call' | 'quality_disclaimer' | 'asset_verification' | 'hold_script' | 'communication_skill' | 'call_probe' | 'objection_rebuttals_urgency_creation' | 'additional_quiries'| 'active_listening' | 'call_closing' | 'mendetory_check_followed' ;
  label: string;
  minWidth?: number;
  align?: 'right';
}
let arr: any[] = [];


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: 10,
    padding:10
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 10,
    padding:10
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const columns: readonly Column[] = [
  { id: 'agent_names', label: 'Agent Names',minWidth:250 },
  { id: 'score_count', label: 'Score',minWidth: 100 },
  { id: 'call_opening', label: 'Call Opening',minWidth:100 },
  { id: 'customer_identification', label: 'Customer Identification', minWidth: 100 },
  { id: 'purpose_of_call', label: 'Purpose Of Call' ,minWidth: 150 },
  { id: 'quality_disclaimer', label: 'Quality Disclaimer',minWidth: 150 },
  { id: 'asset_verification', label: 'Asset Verification',minWidth: 150 },
  { id: 'hold_script', label: 'Hold Script',minWidth: 150  },
  { id: 'communication_skill', label: 'Communication Skill',minWidth: 150  },
  { id: 'call_probe', label: 'Call Probe'},
  { id: 'objection_rebuttals_urgency_creation', label: 'Objection Rebuttals Urgency Creation',minWidth: 100},
  { id: 'additional_quiries', label: 'Additional Queries',minWidth: 120},
  { id: 'active_listening', label: 'Active Listening',minWidth: 150},
  { id: 'call_closing', label: 'Call Closing' ,minWidth: 120},
  { id: 'mendetory_check_followed' , label: 'Mandatory Check Followed' ,minWidth: 120},
];


let metaArr: any[] = [];

const getData = () => {
  metaArr=[];
  Object.values(HitsArr).forEach((data) => {
    console.log("CHECKING USE AUDIT EFFECT%%%%%%%%",data);
    const obj = JSON.parse(JSON.stringify(data));
    console.log("Object data is#####",obj);
    metaArr.push(obj);
    let metaArrObj = {};
  })
  console.log("original meta array is",metaArr);
  // setTableData(metaArr);
  return metaArr;
}


type Props = {
  fetchAuditOut: any;
};


let PageSize = 10;
let HitsArr = {};

const axios = require('axios');
axios.defaults.headers.common['user'] = localStorage.getItem('user');
const getAgentCount = (agent:any) => {
  let agentArray = [];
  console.log("GET INTENT audit ARRAY IS*****", agent);
  for (const element of agent) {
    agentArray.push({ label: element, value: element });
  }
  console.log("agent array audit count check", agentArray);
  return agentArray;
}




export default function AuditOutTable({ fetchAuditOut }: Props) {
  let agentAggregationObj:any={};
  let avgDurationObj:any={};
  let agentCountArr:any[] = [];
  let avgDurationArr:any[] = [];
  console.log('Chechikn methos pass----------->',fetchAuditOut);
  console.log('Chechikn methos audit pass----------->',fetchAuditOut[0].aggregations);
  const tableRef = useRef(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [eleRefPoint, setElRefPoint] = useState<any>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [responseData,setResponseData]=useState<any>();
  const [selected, setSelected] = React.useState<number[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [sortColumn, setSortColumn] = useState("");
  const [agentCountFinal, setAgentCountFinal] =useState<any>([]);
  const [avgDurationFinal, setAvgDurationFinal] =useState<any>([]);
  const [order, setOrder] = useState("");
  // const [rows, setRows] = useState<Data[]>([]);
  const [loading, setLoading] = useState(false);
  const [tableData,setTableData]=useState<any>([]);
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: 'Audit_data',
    sheet: 'Users'
})


  const findName = (name:any) => {
    console.log("Name is ",name);
    if(fetchAuditOut[0].aggregations!=undefined)
    {
    for (const element of fetchAuditOut[0].aggregations) {
      console.log("Element is audit",element)
      if (element['name'] === name) {
        return element;
      }
    }
  }
  }
  const handleOnPopoverClose = () => {
    setElRefPoint(null);
    setIsPopoverOpen(false);
  };
  const getPopoverContent = () => {
       return (
      <AgentOutboundDataDetails getAgentData={[responseData,agentCountFinal,avgDurationFinal]}></AgentOutboundDataDetails>
    );
  };
    const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
      console.log("page is rows ",page);
      var from = (newPage === 0 ? 0 : ((newPage*rowsPerPage)+1));
      var size = rowsPerPage;
      fetchAuditOut[1](fetchAuditOut[2].startDateAuditOut,
        fetchAuditOut[2].endDateAuditOut,
        fetchAuditOut[2].campaignAuditOutVal,
        fetchAuditOut[2].agentAuditOutVal,
        fetchAuditOut[2].dispositionAuditOutVal,
        fetchAuditOut[2].skillAuditOutVal,
        fetchAuditOut[2].scoreOutInputVal,
        fetchAuditOut[2].scoreAuditOutVal,
         size, from);
      console.log("page is audit ",from);
    };
   
    const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    var size = rowsPerPage;
    fetchAuditOut[1](fetchAuditOut[2].startDateAuditOut,
      fetchAuditOut[2].endDateAuditOut,
      fetchAuditOut[2].campaignAuditOutVal,
      fetchAuditOut[2].agentAuditOutVal,
      fetchAuditOut[2].dispositionAuditOutVal,
      fetchAuditOut[2].skillAuditOutVal,
      fetchAuditOut[2].scoreOutInputVal,
      fetchAuditOut[2].scoreAuditOutVal,
       parseInt(event.target.value, 10), "0");
    };
    



  
  if (fetchAuditOut != undefined && fetchAuditOut.length != 0) {
    if (fetchAuditOut[0]['Table Agg'] != undefined) {
      console.log("isOpen valueis ----", fetchAuditOut[0]['Table Agg']);
      HitsArr = fetchAuditOut[0]['Table Agg'];
      console.log("HITS ARRAY IS", HitsArr);
    }

  }
  const agentAggregation = findName('Agent Count');
  console.log("Agent fin name",agentAggregation)
  if(agentAggregation!=undefined)
  {
    agentAggregation['labels'].forEach((element:any, index:any) => { agentAggregationObj[element] = agentAggregation['data'][index]; });
}
Object.entries(agentAggregationObj).forEach((data) => {
  agentCountArr.push({ label: data[0], value: data[1] });
})

const avgDurationAggregation = findName('avg_duration');
if(avgDurationAggregation!=undefined)
{
  avgDurationAggregation['labels'].forEach((element:any, index:any) => { avgDurationObj[element] = avgDurationAggregation['data'][index]; });
}
Object.entries(avgDurationObj).forEach((data) => {
  avgDurationArr.push({ label: data[0], value: data[1] });
})
  const agentDataDetails = (event: any) => {
    setElRefPoint({ top: event.clientY, left: event.clientX });
    setIsPopoverOpen(true);
    getPopoverContent();
  };
  let res: never[]=[];
  const getAgentData = async (value: any, e: any) => {
    // const res = await axios.get(chatTranscriptUrl, { params: { url: value.value } });
    // setResponseData(res.data);
    console.log("Value in audit table ",value,"evet in audit table is",e);
    tableData.forEach((val:any)=> {
      if(val.agent_names==value.value)
      {
        res=val;
        setResponseData(val);
        // console.log("Val to be forwarded is",val,"response data is",responseData);
      }
    });
    agentCountArr.forEach((val:any)=>
    {
    if(val.label==value.value)
    {
      setAgentCountFinal(val.value);
    }
  });
  avgDurationArr.forEach((val:any)=>
  {
  if(val.label==value.value)
  {
    let trimVal=val.value.toFixed(1);
    setAvgDurationFinal(trimVal);
  }
});
    agentDataDetails(e);

    return res;

  }
  console.log("response forwardding audit*****",res);
const handleSortingChange = (sortField:any) => {
  console.log("Accessor isssss****",sortField);
  const sortOrder = order === "asc" ? "desc" : "asc";
  setSortColumn(sortField);
  setOrder(sortOrder);
console.log("Sorting order is",sortOrder,"sorting field",sortField) 
if (sortField!=null||!sortField.equals("0")||sortField!=undefined) {
  console.log("Im inside sort")
  const sorted = [...metaArr].sort((a, b) => {
   console.log("inside sorted a is",a);
   console.log("inside sorted b is",b);
   if (a[sortField] === null) return 1;
   if (b[sortField] === null) return -1;
   if (a[sortField] === null && b[sortField] === null) return 0;
   return (
    a[sortField].toString().localeCompare(b[sortField].toString(), "en", {
     numeric: true,
    }) * (sortOrder === "asc" ? 1 : -1)
   );
  });
  console.log("Sorted Rows and columns data",sorted);
  setTableData(sorted);
 }

};

  React.useEffect(() => {
    setTableData(getData());
    console.log("tablearray in useeffect is",tableData)
    // setTableData(metaArr);
  }, [fetchAuditOut]);

  return (
    <Card>
      <Paper>
        <TableContainer component={Paper}>
          <Table  aria-label="customized table" ref={tableRef}>
            <TableHead sx={{backgroundColor: "yellow"}}>
              <TableRow >
                {columns.map((column, accessor) => {
                  return (
                    <StyledTableCell  key={accessor} align={column.align} style={{ minWidth: column.minWidth, backgroundColor: "#1976d2", fontWeight:"bold",cursor:"pointer" }} onClick={() => handleSortingChange(column.id)}>
                    {column.label} 
                    {order==="asc"?<img alt="upArrow" style={{"height":"12px","paddingLeft":"5px"}} src={images.sortAsc} />:
                    order==""?null:
                    <img alt="downArrow" style={{"height":"12px"}} src={images.sortDsc} />}
                    </StyledTableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.map((row:any, rowIdx:any) => {
                console.log("row audit data is",row);
                return (
                  <StyledTableRow hover role="checkbox" tabIndex={-1} key={row.SNO}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      if (column.id === 'agent_names') {
                        return (
                          <StyledTableCell className="sub-color" key={column.id + rowIdx} align={column.align}>
                          <Button variant="text"  onClick={(e) => getAgentData({value},e)}>
                          {value}
                            </Button>
                         
                          
                        </StyledTableCell>
                        );
                      }
                      // else if(column.id==='score_count')
                      // {
                      //   return (
                      //     <StyledTableCell className="sub-color" key={column.id + rowIdx} align={column.align}>
                      //     {value}
                          
                      //   </StyledTableCell>
                      //   );
                      // }
                      else{
                        return (
                          <StyledTableCell key={column.id + rowIdx} align={column.align}>
                            {value}
                          </StyledTableCell>
                        );
                      
                        }
                    }
                    )}
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count= {fetchAuditOut[0]['Total Agents']>0?fetchAuditOut[0]['Total Agents']:0} // This is what your request should be returning in addition to the current page of rows.
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
          />
      </Paper>
      <Popover
        open={isPopoverOpen}
        onClose={handleOnPopoverClose}
        anchorReference="anchorPosition"
        anchorPosition={eleRefPoint}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left'
        }}
        
      >
        <span
          className="cs-modal-close"
          onClick={(e) => {
            handleOnPopoverClose();
          }}>
          X
        </span>
        {getPopoverContent()}
      </Popover>
    <Button  style={{"float":"right","marginTop": "-10px","marginRight": "22px"}} onClick={()=>onDownload()}>Download</Button>
    </Card>
  );
}




