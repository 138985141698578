import * as React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Card,
  Popover,
  TablePagination,
  Button,
} from "@mui/material";
import { useState, useRef, useEffect } from "react";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import * as XLSX from "xlsx";
import axios from "axios";
import "../App.css";
import { CallTimeLine } from "./CallTimeLine";
import _ from "lodash";

interface Column {
  id:
    | "ucid"
    | "call_date"
    | "call_type"
    | "disposition"
    | "language"
    | "call_duration"
    | "summary"
    | "sentiment"
    | "skill"
    | "agent"
    | "audio_url"
    | "topic"
    | "topic_tags"
    | "campaign"
    | "payment_made_while_on_call_details"
    | "payment_made_while_on_call"
    | "partial_payment"
    | "loan_details_discussed"
    | "loan_default_risk_reason"
    | "loan_default_risk"
    | "late_payment_charges"
    | "emi_paid_details"
    | "emi_paid"
    | "details_of_loan_discussed"
    | "cibil_score_discussed"
    | "branch_visit_suggestion_reason"
    | "branch_visit_suggestion"
    | "before_or_after_due_date";
  label: string;
  minWidth?: number;
  align?: "right";
}

interface DataItem {
  [key: string]: string;
}

const columns: readonly Column[] = [
  { id: "ucid", label: "UCID", minWidth: 80 },
  { id: "call_date", label: "Call Date", minWidth: 150 },
  { id: "call_duration", label: "Call Duration", minWidth: 80 },
  { id: "language", label: "Language", minWidth: 80 },
  { id: "call_type", label: "Call Type", minWidth: 80 },
  { id: "campaign", label: "Campaign", minWidth: 80 },
  { id: "disposition", label: "Disposition", minWidth: 80 },
  { id: "agent", label: "Agent", minWidth: 80 },
  { id: "skill", label: "Skill", minWidth: 80 },
  { id: "sentiment", label: "Sentiment", minWidth: 100 },
  { id: "topic", label: "Topics", minWidth: 150 },
  { id: "topic_tags", label: "Topic Details", minWidth: 300 },
  { id: "summary", label: "Summary", minWidth: 300 },
  {
    id: "payment_made_while_on_call_details",
    label: "Payment On-Call Details",
    minWidth: 300,
  },
  {
    id: "before_or_after_due_date",
    label: "Before/After Due Date",
    minWidth: 100,
  },
  {
    id: "branch_visit_suggestion",
    label: "Branch Visit Suggestion",
    minWidth: 80,
  },
  {
    id: "branch_visit_suggestion_reason",
    label: "Branch Visit Suggestion Reason",
    minWidth: 120,
  },
  {
    id: "cibil_score_discussed",
    label: "CIBIL Score Discussed",
    minWidth: 100,
  },
  { id: "emi_paid", label: "EMI Paid", minWidth: 100 },
  { id: "emi_paid_details", label: "EMI Paid Details", minWidth: 100 },
  { id: "late_payment_charges", label: "Late Payment Charges", minWidth: 120 },
  { id: "loan_default_risk", label: "Loan Default Risk", minWidth: 120 },
  {
    id: "loan_default_risk_reason",
    label: "Loan Default Risk Reason",
    minWidth: 120,
  },
  {
    id: "loan_details_discussed",
    label: "Loan Details Discussed",
    minWidth: 200,
  },
  {
    id: "details_of_loan_discussed",
    label: "Details of Loan Discussed",
    minWidth: 200,
  },
  { id: "partial_payment", label: "Partial Payment", minWidth: 200 },
  { id: "payment_made_while_on_call", label: "Payment On-Call", minWidth: 100 },
  { id: "audio_url", label: "Audio", minWidth: 150 },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

let metaArr: any[] = [];
let downloadArr: any[] = [];
let ucidArr: any[] = [];
let HitsArr = {};

const getData = () => {
  metaArr = [];
  Object.values(HitsArr).forEach((d) => {
    const obj = JSON.parse(JSON.stringify(d));
    metaArr.push(obj);
  });
  return metaArr;
};

axios.defaults.headers.common["user"] = localStorage.getItem("user");

export default function HdbfsTranscriptTable(fetchTranscript: any) {
  const data = fetchTranscript && fetchTranscript;
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [eleRefPoint, setElRefPoint] = useState<any>(null);
  const [responseData, setResponseData] = useState<any>();
  const [downloadData, setDownloadData] = useState<any>();
  const [tableData, setTableData] = useState<any>([]);
  const [currentlyPlaying, setCurrentlyPlaying] = useState<string | null>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const downloadVocTranscriptUrl = `${process.env.REACT_APP_BASE_URL}/hdbfs/vocTranscriptDownload`;
  const audioRefs = useRef<{ [key: string]: HTMLAudioElement | null }>({});

  const convertToTitleCase = (str: string) =>
    str
      .replace(/_/g, " ")
      .replace(/\w\S*/g, (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());

  const convertKeysToTitleCase = (obj: DataItem) => {
    const newObj: DataItem = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        newObj[convertToTitleCase(key)] = obj[key];
      }
    }
    return newObj;
  };

  const exportToExcel = (data: any) => {
    if (!data || !Array.isArray(data) || data.length === 0) {
      console.error("Invalid or empty data array");
      return;
    }
    const camelCaseData = data.map((obj) => convertKeysToTitleCase(obj));
    const worksheet = XLSX.utils.json_to_sheet(camelCaseData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, String(localStorage.getItem("user")) + "_Calls.xlsx");
  };

  const vocDownloadData = async () => {
    try {
      const response = await axios.get(downloadVocTranscriptUrl, {
        params: {
          startDate: String(data.fetchData.startDate),
          endDate: String(data.fetchData.endDate),
          topics: data.fetchData.topics,
          ucid: data.fetchData.ucid ? String(data.fetchData.ucid) : "0",
          sentiment: data.fetchData.sentiment,
          callType: data.fetchData.callType,
          disposition: data.fetchData.disposition,
          campaign: data.fetchData.campaign,
          skill: data.fetchData.skill,
          agent: data.fetchData.agent,
          from: 0,
        },
        timeout: 180000,
      });
      const result = response && response.data && response.data.Hits;
      if (result) {
        downloadArr = Object.values(result).map((result: any) => {
          const orderedRow: any = {};
          columns.forEach((column) => {
            const { id } = column;
            if (result.hasOwnProperty(id)) {
              orderedRow[id] = JSON.parse(JSON.stringify(result[id]));
              if (id === "topic" && orderedRow[id] !== undefined) {
                orderedRow[id] = JSON.parse(JSON.stringify(orderedRow[id].join(",")));
              }
              if (id === "topic_tags" && orderedRow[id] !== undefined) {
                orderedRow[id] = JSON.parse(JSON.stringify(orderedRow[id].join(";")));
              }
            } else {
              orderedRow[id] = undefined;
            }
          });
          return orderedRow;
        });
        setDownloadData(downloadArr);
        exportToExcel(downloadArr);
      }
    } catch (error) {
      console.log(error);
      window.alert("Error Calling Api to Download");
    }
  };

  const handleDownload = () => {
    vocDownloadData();
  };

  const handlePlay = (audioUrl: string) => {
    if (currentlyPlaying && currentlyPlaying !== audioUrl) {
      const prevAudio = audioRefs.current[currentlyPlaying];
      if (prevAudio) {
        prevAudio.pause();
      }
    }
    setCurrentlyPlaying(audioUrl);
  };

  const handlePause = (audioUrl: string) => {
    const audioElement = audioRefs.current[audioUrl];
    if (audioElement) {
      audioElement.pause();
    }
    setCurrentlyPlaying(null);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setTableData([]);
    setPage(newPage);
    const from = newPage === 0 ? 0 : newPage * rowsPerPage + 1;
    data.fetchData.fetchVocTranscriptDataCallBack(
      data.fetchData.startDate,
      data.fetchData.endDate,
      data.fetchData.topics,
      data.fetchData.ucid,
      data.fetchData.sentiment,
      data.fetchData.callType,
      data.fetchData.disposition,
      data.fetchData.campaign,
      data.fetchData.skill,
      data.fetchData.agent,
      from,
      rowsPerPage
    );
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTableData([]);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    data.fetchData.fetchVocTranscriptDataCallBack(
      data.fetchData.startDate,
      data.fetchData.endDate,
      data.fetchData.topics,
      data.fetchData.ucid,
      data.fetchData.sentiment,
      data.fetchData.callType,
      data.fetchData.disposition,
      data.fetchData.campaign,
      data.fetchData.skill,
      data.fetchData.agent,
      parseInt(event.target.value, 10),
      "0"
    );
  };

  const getPopoverContent = () => <CallTimeLine getTranscript={responseData}></CallTimeLine>;

  const handleOnPopoverClose = () => {
    setElRefPoint(null);
    setIsPopoverOpen(false);
  };

  useEffect(() => {
    setTableData(getData());
  }, [fetchTranscript]);

  if (data.fetchVocTranscriptData && data.fetchVocTranscriptData.length !== 0) {
    if (data.fetchVocTranscriptData.Hits !== undefined) {
      HitsArr = data.fetchVocTranscriptData.Hits;
    }
  }

  metaArr.map((row) => ucidArr.push(row.ucid));

  const renderCellContent = (cl: Column, ro: any, rowIdx: any) => {
    switch (cl.id) {
      case "audio_url":
        return (
          <audio
            ref={(audio) => (audioRefs.current[ro.audio_url] = audio)}
            controls
            controlsList="nodownload"
            src={ro.audio_url}
            onPlay={() => handlePlay(ro.audio_url)}
            onPause={() => handlePause(ro.audio_url)}
          ></audio>
        );
      case "topic":
        return <label>{ro.topic.join(",")}</label>;
      case "topic_tags":
        return <label>{splitStringWithNewlines(ro.topic_tags)}</label>;
      default:
        return <label>{ro[cl.id]}</label>;
    }
  };

  function splitStringWithNewlines(stringArray: []) {
    return _.isArrayLikeObject(stringArray) || _.isArray(stringArray)
      ? stringArray.map((line: any, index: any) =>
          line && !_.isEmpty(line) && _.isString(line) ? (
            <label key={index}>
              <b>{line.split(":")[0]}</b> {line.split(":")[1]}
            </label>
          ) : (
            ""
          )
        )
      : null;
  }

  return (
    <Card>
      <Paper>
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead sx={{ backgroundColor: "yellow" }}>
              <TableRow>
                {columns.map((column, idx) => (
                  <StyledTableCell
                    key={column.id + idx}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      backgroundColor: "#1976d2",
                      fontWeight: "bold",
                    }}
                  >
                    {column.label}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {localStorage.getItem("user") === "hdbfs" &&
                tableData.map((ro: any, rowIdx: any) => (
                  <StyledTableRow hover role="checkbox" tabIndex={-1} key={`${ro.SNO}_${rowIdx}`}>
                    {columns.map((cl) => (
                      <StyledTableCell key={cl.id + rowIdx} align={cl.align}>
                        {renderCellContent(cl, ro, rowIdx)}
                      </StyledTableCell>
                    ))}
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          count={
            data.fetchVocTranscriptData && data.fetchVocTranscriptData.DataCount !== 0
              ? Math.ceil(data.fetchVocTranscriptData.DataCount)
              : 0
          }
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      <Popover
        open={isPopoverOpen}
        onClose={handleOnPopoverClose}
        anchorReference="anchorPosition"
        anchorPosition={eleRefPoint}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        <span className="cs-modal-close" onClick={handleOnPopoverClose}>
          X
        </span>
        {getPopoverContent()}
      </Popover>
      <Button
        style={{ float: "right", marginTop: "-10px", marginRight: "22px" }}
        onClick={handleDownload}
      >
        Download
      </Button>
    </Card>
  );
}
