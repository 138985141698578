import React from "react";
import {
  Box,
  Grid,
  Dialog,
  IconButton,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";
import * as _ from "lodash";

const ChatMessage = ({ message, channel, start, callType }) => {
  const isUser = channel === 1;
  const checkCallType = {
    inbound: "customer",
    predective: "customer",
    progressive: "agent",
    manual: "agent",
  };
  const callTypeLowerCase = callType.toLowerCase();

  // Find the corresponding value from checkCallType

  const callTypeValue = checkCallType[callTypeLowerCase];

  console.log("Checked CallType", callTypeValue);
  const oppositeCallTypeValue =
    callTypeValue === "agent" ? "customer" : "agent";
  const roundedStart = Math.round(start);
  const minutes = Math.floor(roundedStart / 60);
  const remainingSeconds = roundedStart % 60;
  const displayTime = `${minutes.toString().padStart(2, "0")}:${remainingSeconds
    .toString()
    .padStart(2, "0")}`;
  return (
    <Box
      display="flex"
      justifyContent={isUser ? "flex-end" : "flex-start"}
      p={2}
      mb={2}
    >
      <Box
        sx={{
          borderLeft: isUser ? "1.5px solid #32D583" : "1.5px solid #909DAD",
          boxShadow: "none",
          maxWidth: "60%",
          minHeight: "50px",
          display: "grid",
          padding: "0px 12px 0px 12px",
          marginLeft: "12px",
          gap: "4px",
        }}
      >
        <Box sx={{ width: "110px", display: "flex", alignItems: "center" }}>
          <Typography
            sx={{
              color: isUser ?"#32D583":"#909DAD",
              width: "50px",
              fontSize: "8px",
              lineHeight: "9px",
              fontWeight: "400"
            }}
          >
            {isUser ? callTypeValue : oppositeCallTypeValue}
          </Typography>
          <Typography
            sx={{
              color: "#3D4C5E",
              borderLeft: "1px solid #909DAD",
              width: "20px",
              fontSize: "8px",
              lineHeight: "9px",
              paddingLeft: "5px",
            }}
          >
            {displayTime}
          </Typography>
        </Box>
        <Typography
          variant="body1"
          sx={{
            fontWeight: "400",
            lineHeight: "14px",
            fontSize: "12px",
            color: "#546881",
          }}
        >
          {message}
        </Typography>
      </Box>
    </Box>
  );
};

const AckoTranscriptDialog = ({ open, handleClose, row }) => {
  console.log("inside Dialog", row);
  const myTitle = "Call Overview";
  const interactionTranscript = row && row.phrases;
  const callType = row && row.call_type;
  const sortedMessages =
    interactionTranscript &&
    interactionTranscript.sort((a, b) => a.start - b.start);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="lg"
      sx={{ height: "620px" }}
    >
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          {myTitle}
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <div
          style={{
            display: "flex",
            borderRadius: "8px",
            width: "1086px",
            height: "525px",
            flexDirection: "row",
            gap: "12px",
          }}
        >
          <div
            style={{
              height: "525px",
              border: "1px solid #DCDCDC",
              borderRadius: "8px",
            }}
          >
            <Typography
              sx={{
                margin: "5px",
                color: "#969696",
                fontSize: "12px",
                fontWeight: "600",
                lineHeight: "14px",
              }}
            >
              Call Transcription
            </Typography>
            <span style={{ flexGrow: 1, alignSelf: "flex-end" }}>
              <Divider variant="fullWidth" sx={{ borderColor: "#DCDCDC" }} />
            </span>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                padding: "12px",
                bgcolor: " #FFFFFF",
                height: "490px",
                overflow: "auto",
              }}
            >
              {sortedMessages &&
                sortedMessages.map((msg, index) => (
                  <ChatMessage
                    key={index}
                    message={msg.transcript}
                    channel={msg.channel}
                    start={msg.start}
                    callType={callType}
                  />
                ))}
            </Box>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AckoTranscriptDialog;
