import "./App.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "react-datepicker/dist/react-datepicker.css";
import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import SpeechDashboard from "./components/SpeechDashboard";
import DashboardLogin from "./components/dashboardLogin";
import ComponentRender from "./components/ComponentsRenderer/ComponentRender";
import { Switch, Route, HashRouter } from "react-router-dom";

// import ErrorBoundary from "./components/ErrorBoundary";
// comment to enable console logs
// console.log = console.warn = console.error = () => {};
export class App extends Component {
  componentDidMount() {
    let token = localStorage.getItem("authToken");
    if (!token) {
      const temp: any = window;
      temp.location = "/#/login";
    }
  }
  render() {
    console.log("react app");
    return (
      // <ErrorBoundary>
      <div className="App">
        <HashRouter>
          <Switch>
            <Route path="/login" exact>
              <DashboardLogin />
            </Route>
            <Route path="/">
              <ComponentRender />
            </Route>
          </Switch>
        </HashRouter>
      </div>
      // </ErrorBoundary>
    );
  }
}

export default App;
