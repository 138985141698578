import React from "react";
import "../App.css";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/material";

const header = ["Parameters", "Score"];
const columns = [
  { id: "score_count", label: "Score", minWidth: 100 },
  { id: "call_opening", label: "Call Opening", minWidth: 100 },
  {
    id: "customer_identification",
    label: "Customer Identification",
    minWidth: 100,
  },
  { id: "purpose_of_call", label: "Purpose Of Call", minWidth: 150 },
  { id: "quality_disclaimer", label: "Quality Disclaimer", minWidth: 150 },
  { id: "asset_verification", label: "Asset Verification", minWidth: 150 },
  { id: "hold_script", label: "Hold Script", minWidth: 150 },
  { id: "communication_skill", label: "Communication Skill", minWidth: 150 },
  { id: "call_probe", label: "Call Probe" },
  {
    id: "objection_rebuttals_urgency_creation",
    label: "Objection Rebuttals Urgency Creation",
    minWidth: 100,
  },
  { id: "additional_quiries", label: "Additional Queries", minWidth: 120 },
  { id: "active_listening", label: "Active Listening", minWidth: 150 },
  { id: "call_closing", label: "Call Closing", minWidth: 120 },
  {
    id: "mendetory_check_followed",
    label: "Mandatory Check Followed",
    minWidth: 120,
  },
];
export const AgentOutboundDataDetails = (responseData) => {
 
  let bestParams = [];
  let needImprovement = [];
  let sortable = [];
  for (let arr in responseData.getAgentData[0]) {
   
    sortable.push([arr, responseData.getAgentData[0][arr]]);
  }
  const sortedArr = sortable.sort(function (a, b) {
    return a[1] - b[1];
  });
  for (let i = 0; i < 3; i++) {
    needImprovement[i] = sortedArr[i];
  }
  for (let i = sortedArr.length - 1, j = 0; j < 3; i--) {
   
    if (sortedArr[i][0] !== "agent_names") {
      bestParams[j] = sortedArr[i];
      j++;
    }
  }
  return (
    <>
      <div className="col-md-12 row">
        <div className="col-md-7">
          <div className="insight">Agent Name</div>
          <br></br>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              "& > :not(style)": {
                m: 1,
                width: 115,
                height: 60,
                marginLeft: 2,
              },
            }}
          >
            <Paper className="aupage">
              Calls
              <div className="dapage"> {responseData.getAgentData[1]}</div>
            </Paper>
            <Paper className="aupage">
              Score
              <div className="dapage">
                {responseData.getAgentData[0].score_count}
              </div>
            </Paper>
            <Paper className="aupage">
              Avg Call Duration<br></br>
              <div className="dapage"> {responseData.getAgentData[2]}</div>
            </Paper>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              "& > :not(style)": {
                m: 1,
                width: 115,
                height: 60,
                marginLeft: 2,
              },
            }}
          ></Box>
          <div className="insight">Insights</div>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              "& > :not(style)": {
                m: 1,
                width: 245,
                height: 120,
              },
            }}
          >
            <Paper className="aupage">
              <b>Best Parameters</b>
              <br></br>
              <br></br>
              <ul style={{ paddingLeft: "10px", textAlign: "center" }}>
                {bestParams &&
                  bestParams.map((el, i) => (
                    <div key={i}>{el[0].replace(/_/g, " ")}</div>
                  ))}
              </ul>
            </Paper>
            <Paper className="aupage">
              <b>Needs Improvement</b>
              <br></br>
              <br></br>
              <ul style={{ paddingLeft: "10px", textAlign: "center" }}>
                {needImprovement &&
                  needImprovement.map((el, i) => (
                    <div key={i}>{el[0].replace(/_/g, " ")}</div>
                  ))}
              </ul>
            </Paper>
          </Box>
        </div>

        <div className="col-md-5" style={{ paddingTop: "9px" }}>
          <table>
            <tr className="bord">
              <th
                className="bord"
                style={{ color: "#008DFF", backgroundColor: "#55493e36" }}
              >
                Parameters
              </th>
              <th className="bord" style={{ backgroundColor: "#55493e36" }}>
                Score
              </th>
            </tr>
            {columns.map((column) => {
              console.log("COLUMN IN OUBOUND AUDIT", column);
              return (
                <tr className="bord">
                  {header.map((m) => {
                    if (m === "Parameters") {
                      return <td className="bord">{column.label}</td>;
                    }
                    if (m === "Score") {
                      return (
                        <td className="bord">
                          {responseData.getAgentData[0][column.id]}
                        </td>
                      );
                    }
                  })}
                </tr>
              );
            })}
          </table>
        </div>
      </div>
    </>
  );
};
