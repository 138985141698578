import styled from "styled-components";
import agentnewicon from '../assets/svgs/agent_icon.svg'
import customericon from '../assets/svgs/customer_icon.svg';
import React from 'react'
const CustomerAgentIconsDiv = styled.div`
    display: grid;
    grid-column: 5 / 7; 
    grid-template-rows: 1fr 21px 1fr;
    font-family: Roboto;
    font-size: 10px;
    font-weight: 300;
    color: #fff;
`;
function CustomerAgentIcons() {
    return (
        <CustomerAgentIconsDiv>
            <figure className="agentconfig">
                <div style={{ "marginLeft": "0px" }}>
                    <img src={agentnewicon} style={{ "marginTop": "40px", "marginLeft": "-135px" }} className="agenticon" alt="logo" />                   
                    <figcaption style={{ "color": "black", "marginLeft": "-135px" }}>Agent</figcaption>
                </div>
            </figure>

            <figure className="customerconfig" style={{ "height": "130px", "left": "39px" }}>
                <div style={{ "marginLeft": "10px" }}>                   
                    <img src={customericon} className="customericon" alt="logo" style={{ "marginTop": "149px", "marginLeft": "-148px" }} />
                    <figcaption style={{ "color": "black", "marginLeft": "-150px" }}>Customer</figcaption>
                </div>
            </figure>
            <div style={{ "marginTop": "-29px", "width": "48px" }}>
                <div className="timeline-extend">
                </div>
            </div>
        </CustomerAgentIconsDiv>
    );
}
export default CustomerAgentIcons;
