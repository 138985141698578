import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Card, Popover, TablePagination, Button } from "@mui/material";
import { useState, useRef } from "react";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import * as _ from "lodash";
import * as XLSX from "xlsx";
import { CallTimeLine } from "./CallTimeLine";
import "../App.css";
interface Column {
  id:
    | "ucid"
    | "call_date"
    | "call_type"
    | "disposition"
    | "summary"
    | "skill"
    | "agent"
    | "AgentStatus"
    | "DialStatus"
    | "score"
    | "TimeToAnswer"
    | "FallBackRule"
    | "HangupBy"
    | "EndTime"
    | "CustomerStatus"
    | "WrapUpDuration"
    | "StartTime"
    | "CallDuration"
    | "Duration"
    | "product_discussed"
    | "benefits_discussed"
    | "product_feedback"
    | "usage_discussed"
    | "information_gathering"
    | "audio_url"
    | "topic"
    | "product_accepted"
    | "product_rejected"
    | "campaign"
    | "sales_made";
  label: string;
  minWidth?: number;
  align?: "right";
}
interface DataItem {
  [key: string]: string;
}
const columns: readonly Column[] = [
  { id: "ucid", label: "UCID", minWidth: 80 },
  { id: "call_date", label: "Call Date", minWidth: 150 },
  { id: "StartTime", label: "Start Time", minWidth: 150 },
  { id: "EndTime", label: "End Time", minWidth: 150 },
  { id: "Duration", label: "Talk Time", minWidth: 150 },
  { id: "TimeToAnswer", label: "Ringing Time", minWidth: 150 },
  { id: "CallDuration", label: "Call Duration", minWidth: 150 },
  { id: "WrapUpDuration", label: "Wrap Up Duration", minWidth: 150 },
  { id: "campaign", label: "Campaign", minWidth: 80 },
  { id: "disposition", label: "Disposition", minWidth: 80 },
  { id: "agent", label: "Agent", minWidth: 80 },
  { id: "skill", label: "Skill", minWidth: 80 },
  { id: "call_type", label: "Call Type", minWidth: 80 },
  { id: "DialStatus", label: "Dial Status", minWidth: 150 },
  { id: "CustomerStatus", label: "Customer Status", minWidth: 150 },
  { id: "AgentStatus", label: "Agent Status", minWidth: 150 },
  { id: "FallBackRule", label: "FallBack Rule", minWidth: 150 },
  { id: "HangupBy", label: "HangupBy", minWidth: 150 },
  { id: "score", label: "Qc Score", minWidth: 150 },
  { id: "topic", label: "Topics", minWidth: 150 },
  { id: "sales_made", label: "Sales Made", minWidth: 150 },
  { id: "product_accepted", label: "Likes", minWidth: 150 },
  { id: "product_rejected", label: "Dislikes", minWidth: 150 },
  { id: "product_discussed", label: "Product Discussed", minWidth: 200 },
  { id: "usage_discussed", label: "Usage Discussed", minWidth: 200 },
  { id: "benefits_discussed", label: "Benefits Discussed", minWidth: 200 },
  { id: "product_feedback", label: "Product Feedback", minWidth: 200 },
  {
    id: "information_gathering",
    label: "Information Gathering",
    minWidth: 200,
  },
  { id: "summary", label: "Summary", minWidth: 300 },
  { id: "audio_url", label: "Audio", minWidth: 150 },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

let metaArr: any[] = [];
let downloadArr: any[] = [];
let ucidArr: any[] = [];
const getData = () => {
  metaArr = [];
  Object.values(HitsArr).forEach((d) => {
    const obj = JSON.parse(JSON.stringify(d));
    metaArr.push(obj);
  });
  // console.log(metaArr);
  return metaArr;
};

// type Props = {
//   fetchTranscript: any;
// };

let HitsArr = {};

import axios from "axios";
axios.defaults.headers.common["user"] = localStorage.getItem("user");

export default function AdretTranscriptTable(fetchTranscript: any) {
  let data = fetchTranscript && fetchTranscript;
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [eleRefPoint, setElRefPoint] = useState<any>(null);
  const [responseData, setResponseData] = useState<any>();
  const [downloadData, setDownloadData] = useState<any>();
  const [tableData, setTableData] = useState<any>([]);
  const [page,setPage] = useState(0);
  const [currentlyPlaying, setCurrentlyPlaying] = useState<string | null>(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const downloadVocTranscriptUrl = `${process.env.REACT_APP_BASE_URL}/adret/vocTranscriptDownload`;
  const audioRefs = useRef<{ [key: string]: HTMLAudioElement | null }>({});

  const convertToTitleCase = (str: string) => {
    return str.replace(/_/g, " ").replace(/\w\S*/g, (word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
  };
  const convertKeysToTitleCase = (obj: DataItem) => {
    const newObj: DataItem = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        newObj[convertToTitleCase(key)] = obj[key];
      }
    }
    return newObj;
  };
  const exportToExcel = (data: any) => {
    // Check if data is defined and is an array
    if (!data || !Array.isArray(data) || data.length === 0) {
      console.error("Invalid or empty data array");
      return;
    }
    // Convert keys to camel case for each object in the array
    const camelCaseData = data.map((obj) => convertKeysToTitleCase(obj));
    // Check if camelCaseData is defined and is an array
    if (
      !camelCaseData ||
      !Array.isArray(camelCaseData) ||
      camelCaseData.length === 0
    ) {
      console.error("Invalid or empty camelCaseData array");
      return;
    }
    const worksheet = XLSX.utils.json_to_sheet(camelCaseData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    // Save the workbook as an XLSX file
    XLSX.writeFile(
      workbook,
      String(localStorage.getItem("user")) + "_Calls.xlsx"
    );
  };
  const vocDownloadData = async () => {
    try {
      const response = await axios.get(downloadVocTranscriptUrl, {
        params: {
          startDate: data.fetchData.startDate,
          endDate: data.fetchData.endDate,
          topics: data.fetchData.topics,
          ucid: data.fetchData.ucid ? String(data.fetchData.ucid) : "0",
          callType: data.fetchData.callType,
          disposition: data.fetchData.disposition,
          campaign: data.fetchData.campaign,
          skill: data.fetchData.skill,
          agent: data.fetchData.agent,
          salesMade: data.fetchData.salesMade, 
          likes: data.fetchData.likes,          
          dislikes: data.fetchData.dislikes, 
          productFeedback: data.fetchData.productFeedback,
          from: 0,
        },
        timeout: 180000,
      });
      const result = response && response.data && response.data.Hits;
      downloadArr = [];
      Object.values(result).forEach((result: any) => {
        const orderedRow: any = {};
        // Iterate through columns to ensure the order
        columns.forEach((column) => {
          const { id } = column;
          orderedRow[id] = result.hasOwnProperty(id)
            ? JSON.parse(JSON.stringify(result[id]))
            : undefined;
          // Additional processing for specific columns if needed
          if (
            (
              id === "topic" ||
              id === "product_accepted" ||
              id === "product_rejected" ||
              id === "information_gathering" ||
              id === "usage_discussed" ||
              id === "product_discussed" ||
              id === "product_feedback") &&
            orderedRow[id] !== undefined &&
            Array.isArray(orderedRow[id]) && // Check if orderedRow[id] is an array
            !_.isEmpty(orderedRow[id]) &&
            orderedRow[id].length > 0 // Check if the array is not empty
          ) {
            orderedRow[id] = orderedRow[id].join(";");
          }
          // Add more conditions for other columns if needed
        });
        downloadArr.push(orderedRow);
      });
      setDownloadData(downloadArr);
      exportToExcel(downloadArr);
    } catch (error) {
      console.log(error);
      window.alert("Error Calling Api to Download");
    }
  };

  const handleDownload = () => {
    vocDownloadData();
  };
  const handlePlay = (audioUrl: string) => {
    if (
      currentlyPlaying &&
      currentlyPlaying !== audioUrl &&
      audioRefs.current[currentlyPlaying]
    ) {
      const prevAudio = audioRefs.current[currentlyPlaying];
      if (prevAudio) {
        prevAudio.pause();
      }
    }
    setCurrentlyPlaying(audioUrl);
  };

  const handlePause = (audioUrl: string) => {
    const audioElement = audioRefs.current[audioUrl];
    if (audioElement) {
      audioElement.pause();
    }
    setCurrentlyPlaying(null);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setTableData([]);
    setPage(newPage);
    console.log("page is rows ", page);
    var from = newPage === 0 ? 0 : newPage * rowsPerPage + 1;
    var size = rowsPerPage;
    data.fetchData.fetchVocTranscriptDataCallBack(
      data.fetchData.startDate,
      data.fetchData.endDate,
      data.fetchData.topics,
      data.fetchData.ucid,
      data.fetchData.callType,
      data.fetchData.disposition,
      data.fetchData.campaign,
      data.fetchData.skill,
      data.fetchData.agent,
      data.fetchData.salesMade,
      data.fetchData.likes,
      data.fetchData.dislikes,
      data.fetchData.productFeedback,
      size,
      from
    );
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTableData([]);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    data.fetchData.fetchVocTranscriptDataCallBack(
      data.fetchData.startDate,
      data.fetchData.endDate,
      data.fetchData.topics,
      data.fetchData.ucid,
      data.fetchData.callType,
      data.fetchData.disposition,
      data.fetchData.campaign,
      data.fetchData.skill,
      data.fetchData.agent,
      data.fetchData.salesMade,
      data.fetchData.likes,
      data.fetchData.dislikes,
      data.fetchData.productFeedback,
      parseInt(event.target.value, 10),
      "0"
    );
  };

  const getPopoverContent = () => {
    return <CallTimeLine getTranscript={responseData}></CallTimeLine>;
  };
  const handleOnPopoverClose = () => {
    setElRefPoint(null);
    setIsPopoverOpen(false);
  };

  if (data.fetchVocTranscriptData && data.fetchVocTranscriptData.length != 0) {
    if (data.fetchVocTranscriptData.Hits != undefined) {
      HitsArr = data.fetchVocTranscriptData.Hits;
      // console.log(HitsArr);
    }
  }

  metaArr.map((row) => {
    ucidArr.push(row.ucid);
  });

  function splitStringWithNewlines(stringArray: []) {
    if (_.isArrayLikeObject(stringArray) || _.isArray(stringArray)) {
      const labels =
        stringArray &&
        stringArray.map((line: any, index: any) =>
          line && !_.isEmpty(line) && _.isString(line) ? (
            <label key={index}>{line}</label>
          ) : (
            ""
          )
        );
      return labels;
    }
  }

  React.useEffect(() => {
    setTableData(getData());
  }, [fetchTranscript]);

  return (
    <Card>
      <Paper>
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead sx={{ backgroundColor: "yellow" }}>
              <TableRow>
                {columns.map((column, idx) => {
                  return (
                    <StyledTableCell
                      key={column.id + idx}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        backgroundColor: "#1976d2",
                        fontWeight: "bold",
                      }}
                    >
                      {column.label}
                    </StyledTableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {localStorage.getItem("user") === "adret" ||
              localStorage.getItem("user") === "adret1" ? (
                <>
                  {tableData &&
                    tableData.map((ro: any, rowIdx: any) => {
                      return (
                        <StyledTableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={rowIdx}
                        >
                          {columns.map((cl, clIdx) => {
                            const uniqueKey = `${cl.id}-${rowIdx}-${clIdx}`;
                            if (cl.id === "audio_url") {
                              return (
                                <StyledTableCell
                                  key={uniqueKey}
                                  align={cl.align}
                                >
                                  <audio
                                    ref={(audio) =>
                                      (audioRefs.current[ro.audio_url] = audio)
                                    }
                                    controls
                                    controlsList="nodownload"
                                    src={ro.audio_url}
                                    onPlay={() => handlePlay(ro.audio_url)}
                                    onPause={() => handlePause(ro.audio_url)}
                                  ></audio>
                                </StyledTableCell>
                              );
                            }
                            if (cl.id === "call_date") {
                              return (
                                <StyledTableCell
                                  key={uniqueKey}
                                  align={cl.align}
                                >
                                  <label>{ro.call_date}</label>
                                </StyledTableCell>
                              );
                            }
                            if (cl.id === "StartTime") {
                              return (
                                <StyledTableCell
                                  key={uniqueKey}
                                  align={cl.align}
                                >
                                  <label>{ro.StartTime}</label>
                                </StyledTableCell>
                              );
                            }
                            if (cl.id === "EndTime") {
                              return (
                                <StyledTableCell
                                  key={uniqueKey}
                                  align={cl.align}
                                >
                                  <label>{ro.EndTime}</label>
                                </StyledTableCell>
                              );
                            }
                            if (cl.id === "Duration") {
                              return (
                                <StyledTableCell
                                  key={uniqueKey}
                                  align={cl.align}
                                >
                                  <label>{ro.Duration}</label>
                                </StyledTableCell>
                              );
                            }
                            if (cl.id === "CallDuration") {
                              return (
                                <StyledTableCell
                                  key={uniqueKey}
                                  align={cl.align}
                                >
                                  <label>{ro.CallDuration}</label>
                                </StyledTableCell>
                              );
                            }
                            if (cl.id === "HangupBy") {
                              return (
                                <StyledTableCell
                                  key={uniqueKey}
                                  align={cl.align}
                                >
                                  <label>{ro.HangupBy}</label>
                                </StyledTableCell>
                              );
                            }
                            if (cl.id === "score") {
                              return (
                                <StyledTableCell
                                  key={uniqueKey}
                                  align={cl.align}
                                >
                                  <label>{ro.score}</label>
                                </StyledTableCell>
                              );
                            }
                            if (cl.id === "CustomerStatus") {
                              return (
                                <StyledTableCell
                                  key={uniqueKey}
                                  align={cl.align}
                                >
                                  <label>{ro.CustomerStatus}</label>
                                </StyledTableCell>
                              );
                            }
                            if (cl.id === "FallBackRule") {
                              return (
                                <StyledTableCell
                                  key={uniqueKey}
                                  align={cl.align}
                                >
                                  <label>{ro.FallBackRule}</label>
                                </StyledTableCell>
                              );
                            }
                            if (cl.id === "TimeToAnswer") {
                              return (
                                <StyledTableCell
                                  key={uniqueKey}
                                  align={cl.align}
                                >
                                  <label>{ro.TimeToAnswer}</label>
                                </StyledTableCell>
                              );
                            }
                            if (cl.id === "WrapUpDuration") {
                              return (
                                <StyledTableCell
                                  key={uniqueKey}
                                  align={cl.align}
                                >
                                  <label>{ro.WrapUpDuration}</label>
                                </StyledTableCell>
                              );
                            }
                            if (cl.id === "AgentStatus") {
                              return (
                                <StyledTableCell
                                  key={uniqueKey}
                                  align={cl.align}
                                >
                                  <label>{ro.AgentStatus}</label>
                                </StyledTableCell>
                              );
                            }
                            if (cl.id === "DialStatus") {
                              return (
                                <StyledTableCell
                                  key={uniqueKey}
                                  align={cl.align}
                                >
                                  <label>{ro.DialStatus}</label>
                                </StyledTableCell>
                              );
                            }
                            if (cl.id === "call_type") {
                              return (
                                <StyledTableCell
                                  key={uniqueKey}
                                  align={cl.align}
                                >
                                  <label>{ro.call_type}</label>
                                </StyledTableCell>
                              );
                            }
                            if (cl.id === "campaign") {
                              return (
                                <StyledTableCell
                                  key={uniqueKey}
                                  align={cl.align}
                                >
                                  <label>{ro.campaign}</label>
                                </StyledTableCell>
                              );
                            }
                            if (cl.id === "ucid") {
                              return (
                                <StyledTableCell
                                  key={uniqueKey}
                                  align={cl.align}
                                >
                                  <label>{ro.ucid} </label>
                                </StyledTableCell>
                              );
                            }
                            if (cl.id === "summary") {
                              return (
                                <StyledTableCell
                                  key={uniqueKey}
                                  align={cl.align}
                                >
                                  <label>{ro.summary}</label>
                                </StyledTableCell>
                              );
                            }
                            if (cl.id === "agent") {
                              return (
                                <StyledTableCell
                                  key={uniqueKey}
                                  align={cl.align}
                                >
                                  <label>{ro.agent}</label>
                                </StyledTableCell>
                              );
                            }
                            if (cl.id === "skill") {
                              return (
                                <StyledTableCell
                                  key={uniqueKey}
                                  align={cl.align}
                                >
                                  <label>{ro.skill}</label>
                                </StyledTableCell>
                              );
                            }
                            if (cl.id === "topic") {
                              return (
                                <StyledTableCell
                                  key={uniqueKey}
                                  align={cl.align}
                                >
                                  <label>
                                    {splitStringWithNewlines(ro.topic)}
                                  </label>
                                </StyledTableCell>
                              );
                            }
                            if (cl.id === "product_accepted") {
                              return (
                                <StyledTableCell
                                  key={uniqueKey}
                                  align={cl.align}
                                >
                                  <label>
                                    {splitStringWithNewlines(ro.product_accepted)}
                                  </label>
                                </StyledTableCell>
                              );
                            }
                            if (cl.id === "product_rejected") {
                              return (
                                <StyledTableCell
                                  key={uniqueKey}
                                  align={cl.align}
                                >
                                  <label>
                                    {splitStringWithNewlines(ro.product_rejected)}
                                  </label>
                                </StyledTableCell>
                              );
                            }
                            if (cl.id === "disposition") {
                              return (
                                <StyledTableCell
                                  key={uniqueKey}
                                  align={cl.align}
                                >
                                  <label>{ro.disposition}</label>
                                </StyledTableCell>
                              );
                            }
                            if (cl.id === "sales_made") {
                              return (
                                <StyledTableCell
                                  key={uniqueKey}
                                  align={cl.align}
                                >
                                  <label>{ro.sales_made}</label>
                                </StyledTableCell>
                              );
                            }
                            if (cl.id === "product_discussed") {
                              return (
                                <StyledTableCell
                                  key={uniqueKey}
                                  align={cl.align}
                                >
                                  <label>{ro.product_discussed}</label>
                                </StyledTableCell>
                              );
                            }
                            if (cl.id === "benefits_discussed") {
                              return (
                                <StyledTableCell
                                  key={uniqueKey}
                                  align={cl.align}
                                >
                                  <label>{ro.benefits_discussed}</label>
                                </StyledTableCell>
                              );
                            }
                            if (cl.id === "usage_discussed") {
                              return (
                                <StyledTableCell
                                  key={uniqueKey}
                                  align={cl.align}
                                >
                                  <label>{ro.usage_discussed}</label>
                                </StyledTableCell>
                              );
                            }
                            if (cl.id === "information_gathering") {
                              return (
                                <StyledTableCell
                                  key={uniqueKey}
                                  align={cl.align}
                                >
                                  <label>{ro.information_gathering}</label>
                                </StyledTableCell>
                              );
                            }
                            if (cl.id === "product_feedback") {
                              return (
                                <StyledTableCell
                                  key={uniqueKey}
                                  align={cl.align}
                                >
                                  <label>{ro.product_feedback}</label>
                                </StyledTableCell>
                              );
                            }
                          })}
                        </StyledTableRow>
                      );
                    })}
                </>
              ) : null}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          count={
            data.fetchVocTranscriptData &&
            data.fetchVocTranscriptData.DataCount !== 0
              ? Math.ceil(data.fetchVocTranscriptData.DataCount)
              : 0
          }
          // This is what your request should be returning in addition to the current page of rows.
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      <Popover
        open={isPopoverOpen}
        onClose={handleOnPopoverClose}
        anchorReference="anchorPosition"
        anchorPosition={eleRefPoint}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        <span
          className="cs-modal-close"
          onClick={() => {
            handleOnPopoverClose();
          }}
        >
          X
        </span>
        {getPopoverContent()}
      </Popover>
      <Button
        style={{ float: "right", marginTop: "-10px", marginRight: "22px" }}
        onClick={handleDownload}
      >
        Download
      </Button>
    </Card>
  );
}
