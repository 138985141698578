import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Card, Popover, TablePagination, Button } from "@mui/material";
import { useState, useEffect } from "react";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import _ from "lodash";
import "../App.css";
import * as XLSX from "xlsx";
import * as images from "../utils/images/images.component";
import { AgentDataDetails } from "./AgentDataDetails";

interface Column {
  id:
    | "agent_names"
    | "call_opening"
    | "effective_probing"
    | "product_benefit"
    | "product_usage"
    | "offers_and_discounts"
    | "pre_medical_history_check"
    | "order_confirmation_accuracy"
    | "call_handling"
    | "prepaid_adherence"
    | "address_adherence"
    | "score_fatal"
    | "score";
  label: string;
  minWidth?: number;
  align?: "right";
}

let arr: any[] = [];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: 10,
    padding: 10,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 10,
    padding: 10,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const columns: readonly Column[] = [
  { id: "agent_names", label: "Agent Names", minWidth: 350 },
  { id: "score", label: "Score", minWidth: 100 },
  { id: "score_fatal", label: "Fatal Score", minWidth: 100 },
  { id: "call_opening", label: "Call Opening", minWidth: 100 },
  { id: "effective_probing", label: "Effective Probing", minWidth: 100 },
  { id: "product_benefit", label: "Product Benefit", minWidth: 120 },
  { id: "product_usage", label: "Product Usage", minWidth: 120 },

  {
    id: "offers_and_discounts",
    label: "Offers and Discounts",
    minWidth: 150,
  },
  {
    id: "pre_medical_history_check",
    label: "Pre Medical History Check",
    minWidth: 150,
  },
  {
    id: "order_confirmation_accuracy",
    label: "Order Confirmation Accuracy",
    minWidth: 120,
  },
  { id: "call_handling", label: "Call Handling", minWidth: 100 },
  {
    id: "prepaid_adherence",
    label: "Prepaid Adherence",
    minWidth: 120,
  },
  { id: "address_adherence", label: "Address Adherence", minWidth: 120 },
];
let metaArr: any[] = [];
interface DataItem {
  [key: string]: string;
}
const getData = () => {
  metaArr = [];
  if (!_.isEmpty(HitsArr)) {
    Object.values(HitsArr).forEach((data) => {
      const obj = JSON.parse(JSON.stringify(data));
      metaArr.push(obj);
    });
  }
  return metaArr;
};

type Props = {
  fetchAudit: any;
};

let HitsArr = {};
let downloadArr: any[] = [];

const axios = require("axios");
axios.defaults.headers.common["user"] = localStorage.getItem("user");

const getAgentCount = (agent: any) => {
  let agentArray = [];
  for (const element of agent) {
    agentArray.push({ label: element, value: element });
  }
  return agentArray;
};

export default function Adret1AuditTable({ fetchAudit }: Props) {
  let agentAggregationObj: any = {};
  let avgDurationObj: any = {};
  let agentCountArr: any[] = [];
  let avgDurationArr: any[] = [];
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [eleRefPoint, setElRefPoint] = useState<any>(null);
  const [responseData, setResponseData] = useState<any>();
  const [page, setPage] = React.useState(0);
  const [downloadData, setDownloadData] = useState<any>();
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [agentCountFinal, setAgentCountFinal] = useState<any>([]);
  const [avgDurationFinal, setAvgDurationFinal] = useState<any>([]);
  const [order, setOrder] = useState("");
  // const [rows, setRows] = useState<Data[]>([]);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState<any>([]);
  const openSearchAuditDataUrl =
    process.env.REACT_APP_BASE_URL + "/adret1/getOpenAuditData";
  const findName = (name: any) => {
    if (fetchAudit[0].aggregations != undefined) {
      for (const element of fetchAudit[0].aggregations) {
        if (element["name"] === name) {
          return element;
        }
      }
    }
  };
  const convertToTitleCase = (str: string) => {
    return str.replace(/_/g, " ").replace(/\w\S*/g, (word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
  };
  const convertKeysToTitleCase = (obj: DataItem) => {
    const newObj: DataItem = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        newObj[convertToTitleCase(key)] = obj[key];
      }
    }
    return newObj;
  };
  const exportToExcel = (data: any) => {
    // Check if data is defined and is an array
    if (!data || !Array.isArray(data) || data.length === 0) {
      console.error("Invalid or empty data array");
      return;
    }
    // Convert keys to camel case for each object in the array
    const camelCaseData = data.map((obj) => convertKeysToTitleCase(obj));
    // Check if camelCaseData is defined and is an array
    if (
      !camelCaseData ||
      !Array.isArray(camelCaseData) ||
      camelCaseData.length === 0
    ) {
      console.error("Invalid or empty camelCaseData array");
      return;
    }
    const worksheet = XLSX.utils.json_to_sheet(camelCaseData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    // Save the workbook as an XLSX file
    XLSX.writeFile(
      workbook,
      String(localStorage.getItem("user")) + "Audit.xlsx"
    );
  };
  interface LanguageMapping {
    [key: string]: string;
  }
  const getLanguageCode = (languageName: string): string => {
    const languageMapping: LanguageMapping = {
      English: "en-IN",
      Tamil: "ta-IN",
      Malayalam: "ml-IN",
      Marathi: "mr-IN",
      Hindi: "hi-IN",
      Telugu: "te-IN",
      Kannada: "kn-IN",
      Gujarati: "gu-IN",
    };
    return languageMapping[languageName] || "0";
  };

  const downloadDataCall = async () => {
    try {
      await axios
        .get(openSearchAuditDataUrl, {
          params: {
            startDateAudit: fetchAudit[2].startDateAudit,
            endDateAudit: fetchAudit[2].endDateAudit,
            campaignAuditVal: fetchAudit[2].campaignAuditVal,
            agentAuditVal: fetchAudit[2].agentAuditVal,
            dispositionAuditVal: fetchAudit[2].dispositionAuditVal,
            languageAuditVal: !_.isEqual(fetchAudit[2].languageAuditVal, "0")
              ? getLanguageCode(fetchAudit[2].languageAuditVal)
              : fetchAudit[2].languageAuditVal,
            skillAuditVal: fetchAudit[2].skillAuditVal,
            scoreInputVal: fetchAudit[2].scoreInputVal,
            scoreAuditVal: fetchAudit[2].scoreAuditVal,
            size: fetchAudit[0]["Total Agents"],
            from: 0,
          },
        })
        .then((response: any) => {
          downloadArr = [];
          if (!_.isEmpty(response.data["Table Agg"])) {
            Object.values(response.data["Table Agg"]).forEach(
              (rowData: any) => {
                const orderedRow: any = {};
                // Iterate through columns to ensure the order
                columns.forEach((column) => {
                  const { id } = column;
                  orderedRow[id] = rowData.hasOwnProperty(id)
                    ? JSON.parse(JSON.stringify(rowData[id]))
                    : undefined;
                });
                downloadArr.push(orderedRow);
              }
            );
          }
          setDownloadData(downloadArr);
          exportToExcel(downloadArr);
        });
    } catch (error) {
      console.log(error);
      downloadArr = [];
      setLoading(false); // Set isLoading to false when done or if an error occurs
      window.alert("Error Calling Api to Download");
    } finally {
      downloadArr = [];
      setLoading(false); // Set isLoading to false when done or if an error occurs
    }
  };

  const handleDownload = (e: any) => {
    downloadDataCall();
  };
  const handleOnPopoverClose = () => {
    setElRefPoint(null);
    setIsPopoverOpen(false);
  };
  const getPopoverContent = () => {
    return (
      <AgentDataDetails
        getAgentData={[responseData, agentCountFinal, avgDurationFinal]}
      ></AgentDataDetails>
    );
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    console.log("page is rows ", page);
    var from = newPage === 0 ? 0 : newPage * rowsPerPage;
    var size = rowsPerPage;
    fetchAudit[1](
      fetchAudit[2].startDateAudit,
      fetchAudit[2].endDateAudit,
      fetchAudit[2].campaignAuditVal,
      fetchAudit[2].agentAuditVal,
      fetchAudit[2].dispositionAuditVal,
      fetchAudit[2].languageAuditVal,
      fetchAudit[2].skillAuditVal,
      fetchAudit[2].scoreInputVal,
      fetchAudit[2].scoreAuditVal,
      size,
      from
    );
    // console.log("page is audit ", from);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    var size = parseInt(event.target.value, 10);
    fetchAudit[1](
      fetchAudit[2].startDateAudit,
      fetchAudit[2].endDateAudit,
      fetchAudit[2].campaignAuditVal,
      fetchAudit[2].agentAuditVal,
      fetchAudit[2].dispositionAuditVal,
      fetchAudit[2].languageAuditVal,
      fetchAudit[2].skillAuditVal,
      fetchAudit[2].scoreInputVal,
      fetchAudit[2].scoreAuditVal,
      parseInt(event.target.value, 10),
      "0"
    );
  };

  if (fetchAudit != undefined && fetchAudit.length != 0) {
    if (
      fetchAudit[0]["Table Agg"] != undefined &&
      !_.isEmpty(fetchAudit[0]["Table Agg"])
    ) {
      // console.log("isOpen valueis ----", fetchAudit[0]["Table Agg"]);
      HitsArr = fetchAudit[0]["Table Agg"];
      // console.log("HITS ARRAY IS", HitsArr);
    } else {
      HitsArr = {};
    }
  }
  const agentAggregation = findName("Agent Count");
  // console.log("Agent fin name", agentAggregation);
  if (agentAggregation != undefined && !_.isEmpty(agentAggregation)) {
    agentAggregation["labels"].forEach((element: any, index: any) => {
      agentAggregationObj[element] = agentAggregation["data"][index];
    });
    Object.entries(agentAggregationObj).forEach((data) => {
      agentCountArr.push({ label: data[0], value: data[1] });
    });
  }

  const avgDurationAggregation = findName("avg_duration");
  if (
    avgDurationAggregation != undefined &&
    !_.isEmpty(avgDurationAggregation)
  ) {
    avgDurationAggregation["labels"].forEach((element: any, index: any) => {
      avgDurationObj[element] = avgDurationAggregation["data"][index];
    });
    Object.entries(avgDurationObj).forEach((data) => {
      avgDurationArr.push({ label: data[0], value: data[1] });
    });
  }
  const agentDataDetails = (event: any) => {
    setElRefPoint({ top: event.clientY, left: event.clientX });
    setIsPopoverOpen(true);
    getPopoverContent();
  };
  let res: never[] = [];
  const getAgentData = async (value: any, e: any) => {
    if (!_.isEmpty(tableData)) {
      tableData.forEach((val: any) => {
        if (val.agent_names == value.value) {
          res = val;
          setResponseData(val);
        }
      });
    }
    if (!_.isEmpty(agentAggregation)) {
      agentCountArr.forEach((val: any) => {
        if (val.label == value.value) {
          setAgentCountFinal(val.value);
        }
      });
    }
    if (!_.isEmpty(avgDurationArr)) {
      avgDurationArr.forEach((val: any) => {
        if (val.label == value.value) {
          let trimVal = val.value.toFixed(1);
          setAvgDurationFinal(trimVal);
        }
      });
    }
    agentDataDetails(e);
    return res;
  };

  useEffect(() => {
    setTableData(getData());
  }, [fetchAudit]);

  return (
    <Card>
      <Paper>
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead sx={{ backgroundColor: "yellow" }}>
              <TableRow>
                {localStorage.getItem("user") === "adret1" ? (
                  <>
                    {columns.map((column, accessor) => {
                      return (
                        <StyledTableCell
                          key={accessor}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                            backgroundColor: "#1976d2",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                        >
                          {column.label}
                          {order === "asc" ? (
                            <img
                              alt="upArrow"
                              style={{ height: "12px", paddingLeft: "5px" }}
                              src={images.sortAsc}
                            />
                          ) : order == "" ? null : (
                            <img
                              alt="downArrow"
                              style={{ height: "12px" }}
                              src={images.sortDsc}
                            />
                          )}
                        </StyledTableCell>
                      );
                    })}
                  </>
                ) : null}
              </TableRow>
            </TableHead>
            <TableBody>
              {localStorage.getItem("user") === "adret1" ? (
                <>
                  {tableData.map((row: any, rowIdx: any) => {
                    return (
                      <StyledTableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={`${row.SNO}_${rowIdx}`} // Combine SNO and rowIdx for a unique key
                      >
                        {columns.map((column, keyId) => {
                          const value = row[column.id];
                          if (column.id === "agent_names") {
                            return (
                              <StyledTableCell
                                className="sub-color"
                                key={column.id + rowIdx + keyId}
                                align={column.align}
                              >
                                <Button
                                  variant="text"
                                  onClick={(e) => getAgentData({ value }, e)}
                                >
                                  {value}
                                </Button>
                              </StyledTableCell>
                            );
                          } else {
                            return (
                              <StyledTableCell
                                key={column.id + rowIdx}
                                align={column.align}
                              >
                                {value}
                              </StyledTableCell>
                            );
                          }
                        })}
                      </StyledTableRow>
                    );
                  })}
                </>
              ) : null}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 75, 125]}
          component="div"
          count={
            fetchAudit[0]["Total Agents"] > 0
              ? fetchAudit[0]["Total Agents"]
              : 0
          } // This is what your request should be returning in addition to the current page of rows.
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Popover
        open={isPopoverOpen}
        onClose={handleOnPopoverClose}
        anchorReference="anchorPosition"
        anchorPosition={eleRefPoint}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        <span
          className="cs-modal-close"
          onClick={(e) => {
            handleOnPopoverClose();
          }}
        >
          X
        </span>
        {getPopoverContent()}
      </Popover>
      <Button
        style={{ float: "right", marginTop: "-10px", marginRight: "22px" }}
        onClick={handleDownload}
      >
        Download
      </Button>
    </Card>
  );
}
