import "./../App_Speech.css";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  PieController,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  BarElement,
  Title,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import Paper from "@mui/material/Paper";
import "react-h5-audio-player/lib/styles.css";
import "react-circular-progressbar/dist/styles.css";
import "./Toolbar.css";
import "./loader.css";
import FormControl from "@mui/material/FormControl";
import moment from "moment";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import ChartDataLabels from "chartjs-plugin-datalabels";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Input from "@mui/material/Input";
import HdbfsV2TranscriptTable from "./HDBFSV2Transcript";
import * as _ from "lodash";
import { Pie } from "react-chartjs-2";
import { MultiSelect } from "react-multi-select-component";
import DropDown from "../assets/svgs/arrow_drop_down.svg";
import DropUp from "../assets/svgs/arrow_drop_up.svg";
import ClearIcon from "../assets/svgs/clear.svg";
import RadioGroup from "@mui/material/RadioGroup";
import { FormControlLabel, Radio } from "@mui/material";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  BarElement,
  Title,
  PieController
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const languageCodeMap = {
  "en-IN": "English",
  "ta-IN": "Tamil",
  "ml-IN": "Malayalam",
  "mr-IN": "Marathi",
  "hi-IN": "Hindi",
  "te-IN": "Telugu",
  "kn-IN": "Kannada",
  "gu-IN": "Gujarati",
  "bn-IN": "Bengali",
  // Add more language codes and names as needed
};
const langVariant = [
  { label: "English", value: "en-IN" },
  { label: "Tamil", value: "ta-IN" },
  { label: "Malayalam", value: "ml-IN" },
  { label: "Marathi", value: "mr-IN" },
  { label: "Hindi", value: "hi-IN" },
  { label: "Telugu", value: "te-IN" },
  { label: "Kannada", value: "kn-IN" },
  { label: "Gujarati", value: "gu-IN" },
];

let variants = [
  {
    id: 1,
    label: "Positive",
    value: "positive",
  },
  {
    id: 2,
    label: "Negative",
    value: "negative",
  },
  {
    id: 3,
    label: "Neutral",
    value: "neutral",
  },
];

const VOCTranscriptUrl = `${process.env.REACT_APP_BASE_URL}/hdbfs_process/vocTranscriptReport`;
const vocDataURL = `${process.env.REACT_APP_BASE_URL}/hdbfs_process/getVOCData`;
const vocWordCluster = `${process.env.REACT_APP_BASE_URL}/api/wordCloud`;
const vocTranscriptFilter = `${process.env.REACT_APP_BASE_URL}/hdbfs_process/vocDashBoardReportFilters`;
const openBillingDataUrl = `${process.env.REACT_APP_BASE_URL}/hdbfs_process/vocBillingReport`;

const axios = require("axios");
axios.defaults.headers.common["user"] = localStorage.getItem("user");

export function logoutUser(ele) {
  localStorage.clear();
  sessionStorage.clear();
  const temp = window;
  temp.location = "/#/login";
}

const HdbfsV2DashBoard = () => {
  const [dashboardVocData, setDashboardVocData] = useState({});
  const [wordClusterData, setWordClusterData] = useState(null);
  const [vocAggrObj, setVocAggrObj] = useState({});
  const [load, setIsload] = useState(true);
  const [jsonVocDataBilling, setJsonVocDataBilling] = useState({});
  const [jsonVocDataTranscript, setJsonVocDataTranscript] = useState({});
  const [vocTopicFilter, setVocTopicFilter] = useState([]);
  const [vocCallTypeFilter, setVocCallTypeFilter] = useState([]);
  const [vocCampaignFilter, setVocCampaignFilter] = useState([]);
  const [vocAgentFilter, setVocAgentFilter] = useState([]);
  const [vocSkillFilter, setVocSkillFilter] = useState([]);
  const [vocDispositionFilter, setVocDispositionFilter] = useState([]);
  const [value, setValue] = useState("1");
  const [audPieVal, setAudPieVal] = useState("pmt");
  const [audGraphVal, setAudGraphVal] = useState("datePayment");

  const [selectedTrxTopicFilter, setSelectedTrxTopicFilter] = useState([]);
  const [selectedTrxSkillFilter, setSelectedTrxSkillFilter] = useState([]);
  const [selectedTrxAgentFilter, setSelectedTrxAgentFilter] = useState([]);
  const [selectedTrxDispositionFilter, setSelectedTrxDispositionFilter] =
    useState([]);

  const [selectedTrxCallTypeFilter, setSelectedTrxCallTypeFilter] = useState(
    []
  );
  const [selectedTrxCampaignFilter, setSelectedTrxCampaignFilter] = useState(
    []
  );
  const [selectedTrxSentimentFilter, setSelectedTrxSentimentFilter] = useState(
    []
  );
  const [selectedSkillFilter, setSelectedSkillFilter] = useState([]);
  const [selectedTopicFilter, setSelectedTopicFilter] = useState([]);
  const [selectedSentimentFilter, setSelectedSentimentFilter] = useState([]);
  const [selectedAgentFilter, setSelectedAgentFilter] = useState([]);
  const [selectedDispositionFilter, setSelectedDispositionFilter] = useState(
    []
  );
  const [checkDatePayment, setCheckDatePayment] = useState(true);
  const [checkPmt, setCheckPmt] = useState(true);
  const [ucidVal, setUcidVal] = useState([]);
  const [selectedCampaignFilter, setSelectedCampaignFilter] = useState([]);
  const [vocFromDate, setVocFromDate] = useState(moment().format("YYYY-MM-DD"));
  const [vocToDate, setVocTODate] = useState(moment().format("YYYY-MM-DD"));

  const handleChangeAudGraph = (event) => {
    if (event.target.value !== "datePayment") setCheckDatePayment(false);
    else setCheckDatePayment(true);
    setAudGraphVal(event.target.value);
  };

  const handleChangeAudPie = (event) => {
    if (event.target.value !== "pmt") setCheckPmt(false);
    else setCheckPmt(true);
    setAudPieVal(event.target.value);
  };

  const handleVocDates = (e, name) => {
    if (name === "startDate") {
      setVocFromDate(String(moment(e).format("YYYY-MM-DD")));
    } else {
      setVocTODate(String(moment(e).format("YYYY-MM-DD")));
    }
  };

  const handleVOCfilter = (e, name) => {
    if (name === "topic") {
      setSelectedTopicFilter(e);
    } else if (name === "campaign") {
      setSelectedCampaignFilter(e);
    } else if (name === "skill") {
      setSelectedSkillFilter(e);
    } else if (name === "agent") {
      setSelectedAgentFilter(e);
    } else if (name === "disposition") {
      setSelectedDispositionFilter(e);
    } else if (name === "sentiment") {
      setSelectedSentimentFilter(e);
    }
  };

  const handleVOCTranscriptFilter = (e, name) => {
    if (name === "topic") {
      setSelectedTrxTopicFilter(e);
    } else if (name === "skill") {
      setSelectedTrxSkillFilter(e);
    } else if (name === "call-type") {
      setSelectedTrxCallTypeFilter(e);
    } else if (name === "campaign") {
      setSelectedTrxCampaignFilter(e);
    } else if (name === "agent") {
      setSelectedTrxAgentFilter(e);
    } else if (name === "disposition") {
      setSelectedTrxDispositionFilter(e);
    } else if (name === "sentiment") {
      setSelectedTrxSentimentFilter(e);
    }
  };

  const handleVOCUcidFilter = (e) => {
    const regex = /^[0-9]*$/;
    if (regex.test(e.target.value) || e.target.value === "") {
      setUcidVal(e.target.value);
    }
  };

  if (window.localStorage.getItem("user") === "hdbfs_process") {
    useEffect(() => {
      vocdownloadFilter();
    }, []);
  }

  const vocdownloadFilter = async () => {
    try {
      const filterResponse = await axios.get(vocTranscriptFilter);
      if (!_.isEmpty(filterResponse)) {
        let formatedTopics = convertToSelectBoxFormat(
          filterResponse.data.aggregations.topics
        );
        if (!_.isEmpty(formatedTopics)) {
          setVocTopicFilter(formatedTopics);
        }
        let formatedCallTypes = convertToSelectBoxFormat(
          filterResponse.data.aggregations.call_types
        );
        if (!_.isEmpty(formatedCallTypes)) {
          setVocCallTypeFilter(formatedCallTypes);
        }
        let formatedCampaigns = convertToSelectBoxFormat(
          filterResponse.data.aggregations.campaigns
        );
        if (!_.isEmpty(formatedCampaigns)) {
          setVocCampaignFilter(formatedCampaigns);
        }
        let formatedSkills = convertToSelectBoxFormat(
          filterResponse.data.aggregations.skill
        );
        if (!_.isEmpty(formatedSkills)) {
          setVocSkillFilter(formatedSkills);
        }
        let formatedAgents = convertToSelectBoxFormat(
          filterResponse.data.aggregations.agent
        );
        if (!_.isEmpty(formatedAgents)) {
          setVocAgentFilter(formatedAgents);
        }
        let formatedDispositions = convertToSelectBoxFormat(
          filterResponse.data.aggregations.disposition
        );
        if (!_.isEmpty(formatedDispositions)) {
          setVocDispositionFilter(formatedDispositions);
        }
      }
    } catch (err) {
      setIsload(false);
      window.alert("Error Calling Api");
    }
  };
  let stopWordsArr = ["summary", "not", "available", "found"];
  const getWordCloudImg = async (sentences) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        sentences,
        width: "1150",
        height: "525",
        background_color: "white",
        stopwords: stopWordsArr,
        // min_font_size: 20,
      }),
      redirect: "follow",
    };

    await fetch(vocWordCluster, requestOptions)
      .then((response) => response.blob())
      .then((result) => {
        console.log(result);
        setWordClusterData(URL.createObjectURL(result));
      })
      .catch((error) => {
        setIsload(false);
        window.alert("Error Calling Api");
        console.error("Error:", error);
      });
  };

  const fetchVocData = async (
    vocFromDate,
    vocToDate,
    selectedTopicFilter,
    selectedSentimentFilter,
    selectedSkillFilter,
    selectedCampaignFilter,
    selectedAgentFilter,
    selectedDispositionFilter,
    size,
    from
  ) => {
    try {
      setIsload(!load);
      if (vocFromDate) {
        const response = await axios.get(vocDataURL, {
          params: {
            startDate: String(vocFromDate),
            endDate: String(vocToDate),
            campaignVal: selectionList(selectedCampaignFilter),
            agentVal: selectionList(selectedAgentFilter),
            dispositionVal: selectionList(selectedDispositionFilter),
            skillVal: selectionList(selectedSkillFilter),
            topicVal: selectionList(selectedTopicFilter),
            sentimentVal: selectionList(selectedSentimentFilter),
            size: size ? size : 10000,
            from: from ? from : 0,
          },
        });
        if (response) {
          setVocAggrObj(
            response &&
              response.data &&
              response.data.Results &&
              response.data.Results.Aggregations &&
              response.data.Results.Aggregations
          );

          let data = [];
          response &&
            response.data &&
            response.data.Results &&
            response.data.Results.WordCloudData &&
            response.data.Results.WordCloudData.map(
              (item) =>
                item &&
                item.summary &&
                item.summary.map((ele) => {
                  data.push(ele);
                })
            );
          const wordCloudSentences = data;
          //   console.log(wordCloudSentences);
          if (!_.isEmpty(wordCloudSentences)) {
            getWordCloudImg(wordCloudSentences);
          }
          //   console.log(vocAggrObj);
          if (!_.isEmpty(vocAggrObj)) {
            setDashboardVocData(getVOCGraphData());
          }
          setIsload(false);
        }
      } else {
        const response = await axios.get(vocDataURL);
        if (response) {
          setVocAggrObj(
            response &&
              response.data &&
              response.data.Results &&
              response.data.Results.Aggregations &&
              response.data.Results.Aggregations
          );

          let data = [];
          response &&
            response.data &&
            response.data.Results &&
            response.data.Results.WordCloudData &&
            response.data.Results.WordCloudData.map(
              (item) => item && item.summary && data.push(item.summary)
            );
          const wordCloudSentences = data;
          console.log(wordCloudSentences);
          if (!_.isEmpty(wordCloudSentences)) {
            getWordCloudImg(wordCloudSentences);
          }
          if (!_.isEmpty(vocAggrObj)) {
            setDashboardVocData(getVOCGraphData());
          }
          setIsload(false);
        }
      }
    } catch (error) {
      setIsload(false);
      console.log(error);
      window.alert("Error Calling Api");
    }
  };

  if (window.localStorage.getItem("user") === "hdbfs_process") {
    useEffect(() => {
      fetchVocTranscriptData(vocFromDate);
    }, []);
  }
  const fetchVocTranscriptData = async (
    vocFromDate,
    vocToDate,
    selectedTrxTopicFilter,
    ucidVal,
    selectedTrxSentimentFilter,
    selectedTrxSkillFilter,
    selectedTrxCallTypeFilter,
    selectedTrxCampaignFilter,
    selectedTrxAgentFilter,
    selectedTrxDispositionFilter,
    size,
    from
  ) => {
    try {
      setIsload(!load);
      if (vocFromDate) {
        const response = await axios.get(VOCTranscriptUrl, {
          params: {
            startDate: String(vocFromDate),
            endDate: String(vocToDate),
            topics: selectionList(selectedTrxTopicFilter),
            ucid: !_.isEmpty(ucidVal) ? String(ucidVal) : "0",
            sentiment: selectionList(selectedTrxSentimentFilter),
            callType: selectionList(selectedTrxCallTypeFilter),
            disposition: selectionList(selectedTrxDispositionFilter),
            campaign: selectionList(selectedTrxCampaignFilter),
            skill: selectionList(selectedTrxSkillFilter),
            agent: selectionList(selectedTrxAgentFilter),
            from: from ? from : 0,
            size: size ? size : 20,
          },
        });
        if (response) {
          setJsonVocDataTranscript(response.data);
          setIsload(false);
        }
      } else {
        const response = await axios.get(VOCTranscriptUrl);
        if (response) {
          setJsonVocDataTranscript(response.data);
          setIsload(false);
        }
      }
    } catch (error) {
      setIsload(false);
      console.log(error);
      window.alert("Error Calling Api");
    }
  };
  if (window.localStorage.getItem("user") === "hdbfs_process") {
    useEffect(() => {}, [jsonVocDataTranscript]);
  }
  const fetchVocTranscriptDataCallBack = async (
    vocFromDate,
    vocToDate,
    selectedTrxTopicFilter,
    ucidVal,
    selectedTrxSentimentFilter,
    selectedTrxSkillFilter,
    selectedTrxCallTypeFilter,
    selectedTrxCampaignFilter,
    selectedTrxAgentFilter,
    selectedTrxDispositionFilter,
    size,
    from
  ) => {
    try {
      setIsload(!load);
      if (vocFromDate) {
        const response = await axios.get(VOCTranscriptUrl, {
          params: {
            startDate: String(vocFromDate),
            endDate: String(vocToDate),
            topics: selectedTrxTopicFilter,
            ucid: !_.isEmpty(ucidVal) ? String(ucidVal) : "0",
            sentiment: selectedTrxSentimentFilter,
            callType: selectedTrxCallTypeFilter,
            disposition: selectedTrxDispositionFilter,
            campaign: selectedTrxCampaignFilter,
            skill: selectedTrxSkillFilter,
            agent: selectedTrxAgentFilter,
            from: from ? from : 0,
            size: size ? size : 20,
          },
        });
        if (response) {
          setJsonVocDataTranscript(response.data);
          setIsload(false);
        }
      } else {
        const response = await axios.get(VOCTranscriptUrl);
        if (response) {
          setJsonVocDataTranscript(response.data);
          setIsload(false);
        }
      }
    } catch (error) {
      window.alert("Error Calling Api");
      setIsload(false);
      console.log(error);
    }
  };

  if (window.localStorage.getItem("user") === "hdbfs_process") {
    useEffect(() => {
      fetchVocBillingData(vocFromDate);
    }, []);
  }

  const fetchVocBillingData = async (vocFromDate, vocToDate) => {
    try {
      setIsload(!load);
      if (vocFromDate) {
        const response = await axios.get(openBillingDataUrl, {
          params: {
            startDate: String(vocFromDate),
            endDate: String(vocToDate),
          },
        });
        if (response) {
          setJsonVocDataBilling(response.data);
          setIsload(false);
        }
      } else {
        const response = await axios.get(openBillingDataUrl);
        if (response) {
          setJsonVocDataBilling(response.data);
          setIsload(false);
        }
      }
    } catch (error) {
      setIsload(false);
      console.log(error);
      window.alert("Error Calling Api");
    }
  };
  if (window.localStorage.getItem("user") === "hdbfs_process") {
    useEffect(() => {}, [jsonVocDataBilling]);
  }

  const ArrowRenderer = ({ expanded }) => (
    <>{expanded ? <img src={DropUp}></img> : <img src={DropDown}></img>}</>
  );

  const CustomClearIcon = () => (
    <div>
      <img src={ClearIcon} className="clear-icon"></img>
    </div>
  );

  const convertToSelectBoxFormat = (arrayElement) => {
    if (arrayElement) {
      arrayElement.map((ele) => {
        ele.label = ele.key;
        ele.value = ele.key;
        delete ele.doc_count;
        delete ele.key;
      });
    }
    return arrayElement;
  };

  if (window.localStorage.getItem("user") === "hdbfs_process") {
    useEffect(() => {
      if (!_.isEmpty(vocAggrObj)) {
        setDashboardVocData(getVOCGraphData());
      }
    }, [vocAggrObj]);
  }

  const findAggr = (name) => {
    for (const element of vocAggrObj) {
      // console.log("Element filter is",element)
      if (element["name"] === name) {
        return element;
      }
    }
  };

  function getLanguageNames(languageCodes) {
    if (!Array.isArray(languageCodes)) {
      throw new Error("Expected an array of language codes");
    }
    return languageCodes.map(
      (code) => languageCodeMap[code] || "Unknown Language"
    );
  }

  function createBarChartConfig(titleText, labels, labelTitle, data) {
    const scoreOptions = {
      plugins: {
        datalabels: {
          display: true,
          color: "black",
          formatter: function (value) {
            return value;
          },
        },
        title: {
          display: true,
          text: titleText,
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
          ticks: {
            callback: function (value) {
              return this.getLabelForValue(value).length > 15
                ? this.getLabelForValue(value).substr(0, 15) + "..."
                : this.getLabelForValue(value);
            },
          },
        },
        y: {
          stacked: true,
        },
      },
    };

    const scoreData = {
      datasets: [
        {
          data: data.slice(0, 12),
          backgroundColor: "#90ED7D",
          label: labelTitle,
          stack: 1,
        },
      ],
      labels: labels.slice(0, 12),
    };

    return { scoreOptions, scoreData };
  }

  function createPieChartConfig(titleText, labels, data) {
    const pieOptions = {
      responsive: true,
      plugins: {
        title: {
          display: true,
          position: "left",
          text: titleText,
        },
        legend: {
          display: true,
          labels: {
            color: "rgb(255, 99, 132)",
          },
          position: "right",
        },
        tooltip: {
          callbacks: {
            label: function (tooltipItem) {
              const label = tooltipItem.label;
              const value = tooltipItem.dataset.data[tooltipItem.dataIndex];
              return `${label} :: ${value}`;
            },
          },
        },
        datalabels: {
          display: true,
        },
      },
    };

    const pieChartData = {
      labels: labels,
      datasets: [
        {
          label: "# of calls",
          data: data,
          backgroundColor: [
            "#1876D1",
            "#9AEAA7",
            "#ECC1C9",
            "#FFBF69",
            "#CCD6EB",
          ], // Customize the colors
          hoverBackgroundColor: ["#1876D1", "#ECC1C9", "#FFBF69"], // Customize the hover colors
          hoverOffset: 4,
        },
      ],
    };
    return { pieOptions, pieChartData };
  }

  const getVOCGraphData = () => {
    console.log("here aggr data", vocAggrObj);

    function processAggregation(type) {
      const aggregation = findAggr(type, vocAggrObj);
      let labels = [];
      let data = [];
      if (!_.isEmpty(aggregation)) {
        aggregation["labels"].forEach((element, index) => {
          labels.push(element);
          data.push(aggregation["data"][index].toFixed(1));
        });
      }
      return { labels, data };
    }

    const topicAggregation = processAggregation("Topics Count");
    const urgencyAggregation = processAggregation("Urgency Score");
    const dateofPaymentAggregation = processAggregation("Date of Payment");
    const eNachPitchingAggregation = processAggregation("eNach Pitching");
    const monetoryLossAggregation = processAggregation("Monetory Loss");
    const loanDetailsCapturedAggregation = processAggregation(
      "Loan Details Captured"
    );
    const moodEnhancementScoreAggregation = processAggregation(
      "Mood Enhancement Score"
    );
    const PTPAggregation = processAggregation("PTP/PU/PMT");
    const rpcConfirmationAggregation = processAggregation("RPC confirmation");
    const intentPayAggregation = processAggregation("Intent to Pay");
    const cibilAggregation = processAggregation("Cibil Impact");
    const modeofPaymentAggregation = processAggregation("Mode of Payment");

    const moodEnhance = createPieChartConfig(
      "Mood Enhancement Score",
      moodEnhancementScoreAggregation.labels,
      moodEnhancementScoreAggregation.data
    );
    let moodEnhancePieOptions = moodEnhance.pieOptions;
    let moodEnhancePieData = moodEnhance.pieChartData;
    const enach = createPieChartConfig(
      "eNach Pitch",
      eNachPitchingAggregation.labels,
      eNachPitchingAggregation.data
    );
    let eNachPieOptions = enach.pieOptions;
    let eNachPieData = enach.pieChartData;
    const pmtPie = createPieChartConfig(
      "PTP/PU/PMT",
      PTPAggregation.labels,
      PTPAggregation.data
    );
    let pmtPieData = pmtPie.pieChartData;
    let pmtPieOptions = pmtPie.pieOptions;
    const monetory = createPieChartConfig(
      "Monetory Loss",
      monetoryLossAggregation.labels,
      monetoryLossAggregation.data
    );
    let monetoryPieOptions = monetory.pieOptions;
    let monetoryPieData = monetory.pieChartData;
    const intentPay = createPieChartConfig(
      "Intent to Pay",
      intentPayAggregation.labels,
      intentPayAggregation.data
    );
    let intentPayPieOptions = intentPay.pieOptions;
    let intentPayPieData = intentPay.pieChartData;

    const rpcPie = createPieChartConfig(
      "RPC Confirmation",
      rpcConfirmationAggregation.labels,
      rpcConfirmationAggregation.data
    );
    let rpcPieOptions = rpcPie.pieOptions;
    let rpcPieData = rpcPie.pieChartData;
    const loanPie = createPieChartConfig(
      "Loan Details Capture",
      loanDetailsCapturedAggregation.labels,
      loanDetailsCapturedAggregation.data
    );
    let loanPieChartData = loanPie.pieChartData;
    let loanPieOptions = loanPie.pieOptions;
    const cibilPie = createPieChartConfig(
      "CIBIL Impact",
      cibilAggregation.labels,
      cibilAggregation.data
    );
    let cibilPieData = cibilPie.pieChartData;
    let cibilPieOptions = cibilPie.pieOptions;
    const urgencyPie = createPieChartConfig(
      "Urgency-Creation Score",
      urgencyAggregation.labels,
      urgencyAggregation.data
    );
    let urgencyPieOptions = urgencyPie.pieOptions;
    let urgencyPieData = urgencyPie.pieChartData;
    const topic = createBarChartConfig(
      "Topic Distribution",
      topicAggregation.labels,
      "Topic Count",
      topicAggregation.data
    );
    let topicScoreData = topic.scoreData;
    let topicScoreOptions = topic.scoreOptions;
    const datePayment = createBarChartConfig(
      "Date of Payments Distribution",
      dateofPaymentAggregation.labels,
      "Date of Payment has #Calls",
      dateofPaymentAggregation.data
    );
    let datePaymentOptions = datePayment.scoreOptions;
    let datePaymentData = datePayment.scoreData;
    const urgencyScore = createBarChartConfig(
      "Urgency-Creation Score Distribution",
      urgencyAggregation.labels,
      "score has #Calls",
      urgencyAggregation.data
    );
    let urgencyScoreOptions = urgencyScore.scoreOptions;
    let urgencyScoreData = urgencyScore.scoreData;
    const modePayment = createBarChartConfig(
      "Mode of Payment Distribution",
      modeofPaymentAggregation.labels,
      "Mode has #Calls",
      modeofPaymentAggregation.data
    );
    let modePaymentOptions = modePayment.scoreOptions;
    let modePaymentData = modePayment.scoreData;
    const moodScore = createBarChartConfig(
      "Mood Enhancement Score Distribution",
      moodEnhancementScoreAggregation.labels,
      "score has #Calls",
      moodEnhancementScoreAggregation.data
    );
    let moodData = moodScore.scoreData;
    let moodOptions = moodScore.scoreOptions;

    return {
      pmtPieOptions: pmtPieOptions,
      pmtPieData: pmtPieData,
      topicScoreOptions: topicScoreOptions,
      topicScoreData: topicScoreData,
      eNachPieOptions: eNachPieOptions,
      eNachPieData: eNachPieData,
      cibilPieOptions: cibilPieOptions,
      cibilPieData: cibilPieData,
      loanPieOptions: loanPieOptions,
      loanPieChartData: loanPieChartData,
      urgencyScoreOptions: urgencyScoreOptions,
      urgencyScoreData: urgencyScoreData,
      intentPayPieData: intentPayPieData,
      intentPayPieOptions: intentPayPieOptions,
      rpcPieData: rpcPieData,
      rpcPieOptions: rpcPieOptions,
      monetoryPieData: monetoryPieData,
      monetoryPieOptions: monetoryPieOptions,
      urgencyPieOptions: urgencyPieOptions,
      urgencyPieData: urgencyPieData,
      moodEnhancePieData: moodEnhancePieData,
      moodEnhancePieOptions: moodEnhancePieOptions,
      datePaymentData: datePaymentData,
      datePaymentOptions: datePaymentOptions,
      moodData: moodData,
      moodOptions: moodOptions,
      modePaymentData: modePaymentData,
      modePaymentOptions: modePaymentOptions,
    };
  };

  const selectionList = (arrOfObj) => {
    let selectionString = "";
    let selectionArr = [];
    if (arrOfObj !== undefined && arrOfObj.length > 0) {
      for (const ele of arrOfObj) {
        selectionArr.push(ele["value"]);
      }
      selectionString = String(selectionArr);
    } else {
      selectionString = "0";
    }
    return selectionString;
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container maxWidth="xl">
      <Grid container spacing={2}>
        <Grid item xs={10}>
          <div className="logo-container">
            <h1>Conversational Intelligence</h1>
          </div>
        </Grid>
        <Grid item xs={2}>
          <span className="loggedInuser font-user">USER:</span>
          <span className="font-user">
            {" "}
            {window.localStorage.getItem("user")} |{" "}
          </span>
          <span className="logout-title font-user" onClick={logoutUser}>
            Logout
          </span>
        </Grid>
        <Box
          sx={{
            width: "100%",
            typography: "body1",
            pointerEvents: load ? "none" : "auto",
          }}
          className={load ? "hidden-cus" : ""}
        >
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                {window.localStorage.getItem("user") === "hdbfs_process" ? (
                  <Tab label="VOC" value="1" />
                ) : null}
                {window.localStorage.getItem("user") === "hdbfs_process" ? (
                  <Tab label="Transcripts" value="2" />
                ) : null}
                {window.localStorage.getItem("user") === "hdbfs_process" ? (
                  <Tab label="Billing" value="3" />
                ) : null}
              </TabList>
            </Box>

            {window.localStorage.getItem("user") !== "mindtree" &&
            window.localStorage.getItem("user") === "hdbfs_process" ? (
              <TabPanel
                value="1"
                style={{ pointerEvents: load ? "none" : "auto" }}
              >
                <div className="voc-filters-search-box-container">
                  <Grid container item spacing={2}>
                    {/* <Grid item xs={1.5}> */}
                    <div className="date-container">&nbsp;</div>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        renderInput={(props) => (
                          <TextField {...props} style={{ width: "124px" }} />
                        )}
                        disableFuture
                        showTodayButton
                        maxDate={moment().toDate()}
                        todayText="now"
                        label="Start Date"
                        value={vocFromDate}
                        onChange={(e) => {
                          handleVocDates(e, "startDate");
                        }}
                      />
                    </LocalizationProvider>
                    <div className="date-container">&nbsp;</div>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        renderInput={(props) => (
                          <TextField {...props} style={{ width: "124px" }} />
                        )}
                        disableFuture
                        showTodayButton
                        maxDate={moment().toDate()}
                        todayText="now"
                        label="End Date"
                        value={vocToDate}
                        onChange={(e) => {
                          handleVocDates(e, "endDate");
                        }}
                      />
                    </LocalizationProvider>
                    <div className="date-container">&nbsp;</div>
                    <FormControl variant="outlined" style={{ width: "125px" }}>
                      <MultiSelect
                        options={vocTopicFilter}
                        value={selectedTopicFilter}
                        onChange={(e) => {
                          handleVOCfilter(e, "topic");
                        }}
                        labelledBy="Topic"
                        hasSelectAll={true}
                        overrideStrings={{ selectSomeItems: "Topics" }}
                        searchable={true} // Enable search functionality
                        ArrowRenderer={ArrowRenderer}
                        ClearIcon={<CustomClearIcon />}
                        ClearSelectedIcon={<CustomClearIcon />}
                      />
                    </FormControl>
                    <div className="date-container">&nbsp;</div>
                    <FormControl variant="outlined" style={{ width: "125px" }}>
                      <MultiSelect
                        options={vocSkillFilter}
                        value={selectedSkillFilter}
                        onChange={(e) => {
                          handleVOCfilter(e, "skill");
                        }}
                        labelledBy="Skill"
                        hasSelectAll={true}
                        overrideStrings={{ selectSomeItems: "Skills" }}
                        searchable={true} // Enable search functionality
                        ArrowRenderer={ArrowRenderer}
                        ClearIcon={<CustomClearIcon />}
                        ClearSelectedIcon={<CustomClearIcon />}
                      />
                    </FormControl>
                    <div className="date-container">&nbsp;</div>
                    <FormControl variant="outlined" style={{ width: "125px" }}>
                      <MultiSelect
                        options={vocAgentFilter}
                        value={selectedAgentFilter}
                        onChange={(e) => {
                          handleVOCfilter(e, "agent");
                        }}
                        labelledBy="Agent"
                        hasSelectAll={true}
                        overrideStrings={{ selectSomeItems: "Agents" }}
                        searchable={true} // Enable search functionality
                        ArrowRenderer={ArrowRenderer}
                        ClearIcon={<CustomClearIcon />}
                        ClearSelectedIcon={<CustomClearIcon />}
                      />
                    </FormControl>
                    <div className="date-container">&nbsp;</div>
                    <FormControl variant="outlined" style={{ width: "125px" }}>
                      <MultiSelect
                        options={vocDispositionFilter}
                        value={selectedDispositionFilter}
                        onChange={(e) => {
                          handleVOCfilter(e, "disposition");
                        }}
                        labelledBy="Disposition"
                        hasSelectAll={true}
                        overrideStrings={{ selectSomeItems: "Dispositions" }}
                        searchable={true} // Enable search functionality
                        ArrowRenderer={ArrowRenderer}
                        ClearIcon={<CustomClearIcon />}
                        ClearSelectedIcon={<CustomClearIcon />}
                      />
                    </FormControl>
                    <div className="date-container">&nbsp;</div>
                    <FormControl variant="outlined" style={{ width: "125px" }}>
                      <MultiSelect
                        options={vocCampaignFilter}
                        value={selectedCampaignFilter}
                        onChange={(e) => {
                          handleVOCfilter(e, "campaign");
                        }}
                        labelledBy="Campaign"
                        hasSelectAll={true}
                        overrideStrings={{ selectSomeItems: "Campaigns" }}
                        searchable={true} // Enable search functionality
                        ArrowRenderer={ArrowRenderer}
                        ClearIcon={<CustomClearIcon />}
                        ClearSelectedIcon={<CustomClearIcon />}
                      />
                    </FormControl>
                    <div className="date-container">&nbsp;</div>
                    <FormControl variant="outlined" style={{ width: "125px" }}>
                      <MultiSelect
                        options={variants}
                        value={selectedSentimentFilter}
                        onChange={(e) => {
                          handleVOCfilter(e, "sentiment");
                        }}
                        labelledBy="sentiment"
                        hasSelectAll={true}
                        overrideStrings={{ selectSomeItems: "Sentiments" }}
                        searchable={true} // Enable search functionality
                        ArrowRenderer={ArrowRenderer}
                        ClearIcon={<CustomClearIcon />}
                        ClearSelectedIcon={<CustomClearIcon />}
                      />
                    </FormControl>
                    <div className="date-container">&nbsp;</div>
                    <Button
                      variant="contained"
                      style={{ padding: "12px 10px", width: "90px" }}
                      onClick={() => {
                        fetchVocData(
                          vocFromDate,
                          vocToDate,
                          selectedTopicFilter,
                          selectedSentimentFilter,
                          selectedSkillFilter,
                          selectedCampaignFilter,
                          selectedAgentFilter,
                          selectedDispositionFilter
                        );
                      }}
                    >
                      Search
                    </Button>
                  </Grid>
                </div>
                <br></br>
                <Grid item xs={1}></Grid>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <Box
                      sx={{
                        flexWrap: "wrap",
                        "& > :not(style)": {
                          m: 1,
                          width: 600,
                          height: 500,
                        },
                      }}
                    >
                      <Paper elevation={3} style={{ marginLeft: "-13px" }}>
                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={audGraphVal}
                            onChange={handleChangeAudGraph}
                          >
                            <FormControlLabel
                              value="datePayment"
                              control={<Radio size="small" />}
                              editable="true"
                              label="Date of Payment"
                              checked={checkDatePayment}
                            />
                            <FormControlLabel
                              value="modePayment"
                              control={<Radio size="small" />}
                              label="Mode of Payment"
                            />
                            <FormControlLabel
                              value="urgencyScore"
                              control={<Radio size="small" />}
                              label="Urgency-Creation score"
                            />
                            <FormControlLabel
                              value="moodScore"
                              control={<Radio size="small" />}
                              label="Mood Enhancement score"
                            />
                          </RadioGroup>
                        </FormControl>
                        {/* </Paper> */}

                        <div style={{ paddingTop: "20px" }}>
                          {dashboardVocData &&
                            Object.keys(dashboardVocData).length !== 0 && (
                              <Grid container item spacing={3}>
                                {audGraphVal === "moodScore" ? (
                                  <Grid item xs={6}>
                                    {" "}
                                    <Paper
                                      elevation={3}
                                      sx={{
                                        width: "580px",
                                        height: "400px",
                                        marginLeft: "10px",
                                      }}
                                    >
                                      <Bar
                                        options={dashboardVocData.moodOptions}
                                        data={dashboardVocData.moodData}
                                        plugins={[ChartDataLabels]}
                                      />
                                    </Paper>
                                  </Grid>
                                ) : audGraphVal === "urgencyScore" ? (
                                  <Grid item xs={6}>
                                    {" "}
                                    <Paper
                                      elevation={3}
                                      sx={{
                                        width: "580px",
                                        height: "400px",
                                        marginLeft: "10px",
                                      }}
                                    >
                                      <Bar
                                        options={
                                          dashboardVocData.urgencyScoreOptions
                                        }
                                        data={dashboardVocData.urgencyScoreData}
                                        plugins={[ChartDataLabels]}
                                      />
                                    </Paper>
                                  </Grid>
                                ) : audGraphVal === "modePayment" ? (
                                  <Grid item xs={6}>
                                    {" "}
                                    <Paper
                                      elevation={3}
                                      sx={{
                                        width: "580px",
                                        height: "400px",
                                        marginLeft: "10px",
                                      }}
                                    >
                                      <Bar
                                        options={
                                          dashboardVocData.modePaymentOptions
                                        }
                                        data={dashboardVocData.modePaymentData}
                                        plugins={[ChartDataLabels]}
                                      />
                                    </Paper>
                                  </Grid>
                                ) : (
                                  <Grid item xs={6}>
                                    {" "}
                                    <Paper
                                      elevation={3}
                                      sx={{
                                        width: "580px",
                                        height: "400px",
                                        marginLeft: "10px",
                                      }}
                                    >
                                      <Bar
                                        options={
                                          dashboardVocData.datePaymentOptions
                                        }
                                        data={dashboardVocData.datePaymentData}
                                        plugins={[ChartDataLabels]}
                                      />
                                    </Paper>
                                  </Grid>
                                )}
                              </Grid>
                            )}
                        </div>
                      </Paper>
                    </Box>
                  </div>
                  <br></br>
                  <div>
                    <Box
                      sx={{
                        flexWrap: "wrap",
                        "& > :not(style)": {
                          m: 1,
                          width: 600,
                          height: 500,
                        },
                      }}
                    >
                      <Paper elevation={3} style={{ marginLeft: "-13px" }}>
                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={audPieVal}
                            onChange={handleChangeAudPie}
                          >
                            <FormControlLabel
                              value="pmt"
                              control={<Radio size="small" />}
                              editable="true"
                              label="PTP/PU/PMT"
                              checked={checkPmt}
                            />
                            <FormControlLabel
                              value="intentPay"
                              control={<Radio size="small" />}
                              label="Intent to Pay"
                            />
                            <FormControlLabel
                              value="rpc"
                              control={<Radio size="small" />}
                              label="RPC Confirmation"
                            />
                            <FormControlLabel
                              value="monetory"
                              control={<Radio size="small" />}
                              label="Monetory Loss"
                            />
                            <FormControlLabel
                              value="enach"
                              control={<Radio size="small" />}
                              label="eNach Pitch"
                            />
                            <FormControlLabel
                              value="loan"
                              control={<Radio size="small" />}
                              label="Loan Details Captured"
                            />
                          </RadioGroup>
                        </FormControl>
                        <div style={{ paddingTop: "20px" }}>
                          {dashboardVocData &&
                          Object.keys(dashboardVocData).length !== 0 ? (
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                {audPieVal === "rpc" ? (
                                  <Grid item xs={6}>
                                    <Paper
                                      elevation={3}
                                      sx={{
                                        width: "560px",
                                        height: "400px",
                                        marginLeft: "10px",
                                      }}
                                    >
                                      <div
                                        style={{
                                          marginLeft: "10px",
                                          width: "425px",
                                        }}
                                      >
                                        <Pie
                                          options={
                                            dashboardVocData.rpcPieOptions
                                          }
                                          data={dashboardVocData.rpcPieData}
                                          plugins={[ChartDataLabels]}
                                        />
                                      </div>
                                    </Paper>
                                  </Grid>
                                ) : audPieVal === "intentPay" ? (
                                  <Grid item xs={6}>
                                    <Paper
                                      elevation={3}
                                      sx={{
                                        width: "560px",
                                        height: "400px",
                                        marginLeft: "10px",
                                      }}
                                    >
                                      <div
                                        style={{
                                          marginLeft: "10px",
                                          width: "425px",
                                        }}
                                      >
                                        <Pie
                                          options={
                                            dashboardVocData.intentPayPieOptions
                                          }
                                          data={
                                            dashboardVocData.intentPayPieData
                                          }
                                          plugins={[ChartDataLabels]}
                                        />
                                      </div>
                                    </Paper>
                                  </Grid>
                                ) : audPieVal === "monetory" ? (
                                  <Grid item xs={6}>
                                    <Paper
                                      elevation={3}
                                      sx={{
                                        width: "560px",
                                        height: "400px",
                                        marginLeft: "10px",
                                      }}
                                    >
                                      <div
                                        style={{
                                          marginLeft: "10px",
                                          width: "425px",
                                        }}
                                      >
                                        <Pie
                                          options={
                                            dashboardVocData.monetoryPieOptions
                                          }
                                          data={
                                            dashboardVocData.monetoryPieData
                                          }
                                          plugins={[ChartDataLabels]}
                                        />
                                      </div>
                                    </Paper>
                                  </Grid>
                                ) : audPieVal === "enach" ? (
                                  <Grid item xs={6}>
                                    <Paper
                                      elevation={3}
                                      sx={{
                                        width: "560px",
                                        height: "400px",
                                        marginLeft: "10px",
                                      }}
                                    >
                                      <div
                                        style={{
                                          marginLeft: "10px",
                                          width: "425px",
                                        }}
                                      >
                                        <Pie
                                          options={
                                            dashboardVocData.eNachPieOptions
                                          }
                                          data={dashboardVocData.eNachPieData}
                                          plugins={[ChartDataLabels]}
                                        />
                                      </div>
                                    </Paper>
                                  </Grid>
                                ) : audPieVal === "loan" ? (
                                  <Grid item xs={6}>
                                    <Paper
                                      elevation={3}
                                      sx={{
                                        width: "560px",
                                        height: "400px",
                                        marginLeft: "10px",
                                      }}
                                    >
                                      <div
                                        style={{
                                          marginLeft: "10px",
                                          width: "425px",
                                        }}
                                      >
                                        <Pie
                                          options={
                                            dashboardVocData.loanPieOptions
                                          }
                                          data={
                                            dashboardVocData.loanPieChartData
                                          }
                                          plugins={[ChartDataLabels]}
                                        />
                                      </div>
                                    </Paper>
                                  </Grid>
                                ) : (
                                  <Grid item xs={6}>
                                    <Paper
                                      elevation={3}
                                      sx={{
                                        width: "560px",
                                        height: "400px",
                                        marginLeft: "10px",
                                      }}
                                    >
                                      <div
                                        style={{
                                          marginLeft: "10px",
                                          width: "425px",
                                        }}
                                      >
                                        <Pie
                                          options={
                                            dashboardVocData.pmtPieOptions
                                          }
                                          data={dashboardVocData.pmtPieData}
                                          plugins={[ChartDataLabels]}
                                        />
                                      </div>
                                    </Paper>
                                  </Grid>
                                )}
                              </Grid>
                            </Grid>
                          ) : (
                            <Grid container item spacing={3}>
                              <label
                                style={{
                                  marginLeft: "30px",
                                  width: "410px",
                                }}
                              >
                                No Data For Analysis
                              </label>
                            </Grid>
                          )}
                        </div>
                      </Paper>
                    </Box>
                  </div>
                  <br></br>
                </div>
                {console.log("Graph obpjetc::", dashboardVocData)}
                {dashboardVocData &&
                Object.keys(dashboardVocData).length !== 0 ? (
                  <Grid container spacing={3}>
                    <Grid container item spacing={3}>
                      <Grid item xs={12}>
                        <Paper elevation={3}>
                          <div className="voc-bar-graph">
                            <label className="voc-graphs-header-names">
                              Topic Overview Distribution
                            </label>
                            {dashboardVocData &&
                              Object.keys(dashboardVocData).length !== 0 && (
                                <Bar
                                  options={dashboardVocData.topicScoreOptions}
                                  data={dashboardVocData.topicScoreData}
                                  plugins={[ChartDataLabels]}
                                />
                              )}
                          </div>
                        </Paper>
                      </Grid>
                    </Grid>
                    <Grid container item spacing={3}>
                      <Grid item xs={12}>
                        <div className="voc-word-cloud-container">
                          <Paper>
                            <label className="voc-graphs-header-names">
                              Word Cloud over Summary
                            </label>
                            <img
                              src={wordClusterData}
                              className="word-cloud-properties"
                              alt="logo"
                            />
                          </Paper>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <div className="voc-no-data-found-container">
                    No Data found ...
                  </div>
                )}
              </TabPanel>
            ) : null}

            {window.localStorage.getItem("user") !== "mindtree" &&
            window.localStorage.getItem("user") === "hdbfs_process" ? (
              <TabPanel
                value="2"
                style={{ pointerEvents: load ? "none" : "auto" }}
              >
                <div className="voc-filters-search-box-container">
                  <Grid container item spacing={2}>
                    {/* <Grid item xs={1.5}> */}
                    <div className="date-container">&nbsp;</div>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        renderInput={(props) => (
                          <TextField {...props} style={{ width: "124px" }} />
                        )}
                        disableFuture
                        showTodayButton
                        maxDate={moment().toDate()}
                        todayText="now"
                        label="Start Date"
                        value={vocFromDate}
                        onChange={(e) => {
                          handleVocDates(e, "startDate");
                        }}
                      />
                    </LocalizationProvider>
                    <div className="date-container">&nbsp;</div>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        renderInput={(props) => (
                          <TextField {...props} style={{ width: "124px" }} />
                        )}
                        disableFuture
                        showTodayButton
                        maxDate={moment().toDate()}
                        todayText="now"
                        label="Start Date Time"
                        value={vocToDate}
                        onChange={(e) => {
                          handleVocDates(e, "endDate");
                        }}
                      />
                    </LocalizationProvider>
                    <div className="date-container">&nbsp;</div>
                    <FormControl variant="outlined" style={{ width: "125px" }}>
                      <MultiSelect
                        options={vocTopicFilter}
                        value={selectedTrxTopicFilter}
                        onChange={(e) => {
                          handleVOCTranscriptFilter(e, "topic");
                        }}
                        labelledBy="Topic"
                        hasSelectAll={true}
                        overrideStrings={{ selectSomeItems: "Topics" }}
                        searchable={true} // Enable search functionality
                        ArrowRenderer={ArrowRenderer}
                        ClearIcon={<CustomClearIcon />}
                        ClearSelectedIcon={<CustomClearIcon />}
                      />
                    </FormControl>
                    <div className="date-container">&nbsp;</div>
                    <FormControl variant="outlined" style={{ width: "125px" }}>
                      <MultiSelect
                        options={variants}
                        value={selectedTrxSentimentFilter}
                        onChange={(e) => {
                          handleVOCTranscriptFilter(e, "sentiment");
                        }}
                        labelledBy="sentiment"
                        hasSelectAll={true}
                        overrideStrings={{ selectSomeItems: "Sentiments" }}
                        searchable={true} // Enable search functionality
                        ArrowRenderer={ArrowRenderer}
                        ClearIcon={<CustomClearIcon />}
                        ClearSelectedIcon={<CustomClearIcon />}
                      />
                    </FormControl>
                    <div className="date-container">&nbsp;</div>
                    <FormControl variant="outlined" style={{ width: "125px" }}>
                      <MultiSelect
                        options={vocCallTypeFilter}
                        value={selectedTrxCallTypeFilter}
                        onChange={(e) => {
                          handleVOCTranscriptFilter(e, "call-type");
                        }}
                        labelledBy="CallType"
                        hasSelectAll={true}
                        overrideStrings={{ selectSomeItems: "CallType" }}
                        searchable={true} // Enable search functionality
                        ArrowRenderer={ArrowRenderer}
                        ClearIcon={<CustomClearIcon />}
                        ClearSelectedIcon={<CustomClearIcon />}
                      />
                    </FormControl>
                    <div className="date-container">&nbsp;</div>
                    <FormControl variant="outlined" style={{ width: "125px" }}>
                      <MultiSelect
                        options={vocSkillFilter}
                        value={selectedTrxSkillFilter}
                        onChange={(e) => {
                          handleVOCTranscriptFilter(e, "skill");
                        }}
                        labelledBy="Skill"
                        hasSelectAll={true}
                        overrideStrings={{ selectSomeItems: "Skills" }}
                        searchable={true} // Enable search functionality
                        ArrowRenderer={ArrowRenderer}
                        ClearIcon={<CustomClearIcon />}
                        ClearSelectedIcon={<CustomClearIcon />}
                      />
                    </FormControl>
                    <div className="date-container">&nbsp;</div>
                    <FormControl variant="outlined" style={{ width: "125px" }}>
                      <MultiSelect
                        options={vocDispositionFilter}
                        value={selectedTrxDispositionFilter}
                        onChange={(e) => {
                          handleVOCTranscriptFilter(e, "disposition");
                        }}
                        labelledBy="Disposition"
                        hasSelectAll={true}
                        overrideStrings={{ selectSomeItems: "Dispositions" }}
                        searchable={true} // Enable search functionality
                        ArrowRenderer={ArrowRenderer}
                        ClearIcon={<CustomClearIcon />}
                        ClearSelectedIcon={<CustomClearIcon />}
                      />
                    </FormControl>
                    <div className="date-container">&nbsp;</div>
                    <FormControl variant="outlined" style={{ width: "125px" }}>
                      <MultiSelect
                        options={vocAgentFilter}
                        value={selectedTrxAgentFilter}
                        onChange={(e) => {
                          handleVOCTranscriptFilter(e, "agent");
                        }}
                        labelledBy="Agent"
                        hasSelectAll={true}
                        overrideStrings={{ selectSomeItems: "Agents" }}
                        searchable={true} // Enable search functionality
                        ArrowRenderer={ArrowRenderer}
                        ClearIcon={<CustomClearIcon />}
                        ClearSelectedIcon={<CustomClearIcon />}
                      />
                    </FormControl>
                    <div className="date-container">&nbsp;</div>
                    <FormControl variant="outlined" style={{ width: "125px" }}>
                      <MultiSelect
                        options={vocCampaignFilter}
                        value={selectedTrxCampaignFilter}
                        onChange={(e) => {
                          handleVOCTranscriptFilter(e, "campaign");
                        }}
                        labelledBy="Campaign"
                        hasSelectAll={true}
                        overrideStrings={{ selectSomeItems: "Campaigns" }}
                        searchable={true} // Enable search functionality
                        ArrowRenderer={ArrowRenderer}
                        ClearIcon={<CustomClearIcon />}
                        ClearSelectedIcon={<CustomClearIcon />}
                      />
                    </FormControl>
                    <div className="date-container">&nbsp;</div>
                    <FormControl style={{ width: "125px" }}>
                      <div>
                        <Box
                          component="form"
                          sx={{
                            "& > :not(style)": { m: 1, width: 98 },
                          }}
                          noValidate
                          autoComplete="off"
                        >
                          <Input
                            style={{ fontSize: "0.8rem" }}
                            placeholder="Enter UCID"
                            value={ucidVal}
                            onChange={handleVOCUcidFilter}
                            inputProps={{ pattern: "^[0-9]*$" }} // Only allow numbers
                          />
                        </Box>
                      </div>
                    </FormControl>
                    <div className="date-container">&nbsp;</div>{" "}
                    <FormControl>
                      <Button
                        variant="contained"
                        style={{ padding: "12px 10px", width: "90px" }}
                        onClick={() => {
                          fetchVocTranscriptData(
                            vocFromDate,
                            vocToDate,
                            selectedTrxTopicFilter,
                            ucidVal,
                            selectedTrxSentimentFilter,
                            selectedTrxSkillFilter,
                            selectedTrxCallTypeFilter,
                            selectedTrxCampaignFilter,
                            selectedTrxAgentFilter,
                            selectedTrxDispositionFilter
                          );
                        }}
                      >
                        Search
                      </Button>
                    </FormControl>
                    {load ? (
                      <div className="loader_digital" id="loader_digital"></div>
                    ) : null}
                    <Grid item xs={12}>
                      <HdbfsV2TranscriptTable
                        fetchVocTranscriptData={jsonVocDataTranscript}
                        fetchVOCTranscriptFunction={
                          fetchVocTranscriptDataCallBack
                        }
                        fetchData={{
                          fetchVocTranscriptDataCallBack,
                          startDate: String(vocFromDate),
                          endDate: String(vocToDate),
                          topics: selectionList(selectedTrxTopicFilter),
                          ucid: !_.isEmpty(ucidVal) ? String(ucidVal) : "0",
                          sentiment: selectionList(selectedTrxSentimentFilter),
                          callType: selectionList(selectedTrxCallTypeFilter),
                          disposition: selectionList(
                            selectedTrxDispositionFilter
                          ),
                          campaign: selectionList(selectedTrxCampaignFilter),
                          skill: selectionList(selectedTrxSkillFilter),
                          agent: selectionList(selectedTrxAgentFilter),
                        }}
                      ></HdbfsV2TranscriptTable>
                    </Grid>
                  </Grid>
                </div>
              </TabPanel>
            ) : null}

            {window.localStorage.getItem("user") !== "mindtree" &&
            window.localStorage.getItem("user") === "hdbfs_process" ? (
              <TabPanel
                value="3"
                style={{ pointerEvents: load ? "none" : "auto" }}
              >
                <div className="voc-filters-search-box-container">
                  <Grid container item spacing={2}>
                    <div className="date-container">&nbsp;</div>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        renderInput={(props) => (
                          <TextField {...props} style={{ width: "124px" }} />
                        )}
                        disableFuture
                        showTodayButton
                        maxDate={moment().toDate()}
                        todayText="now"
                        label="Start Date"
                        value={vocFromDate}
                        onChange={(e) => {
                          handleVocDates(e, "startDate");
                        }}
                      />
                    </LocalizationProvider>
                    <div className="date-container">&nbsp;</div>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        renderInput={(props) => (
                          <TextField {...props} style={{ width: "124px" }} />
                        )}
                        disableFuture
                        showTodayButton
                        maxDate={moment().toDate()}
                        todayText="now"
                        label="End Date"
                        value={vocToDate}
                        onChange={(e) => {
                          handleVocDates(e, "endDate");
                        }}
                      />
                    </LocalizationProvider>
                    <div className="date-container">&nbsp;</div>
                    <Button
                      variant="contained"
                      style={{ padding: "12px 10px", width: "90px" }}
                      onClick={() => {
                        fetchVocBillingData(vocFromDate, vocToDate);
                      }}
                    >
                      Search
                    </Button>
                  </Grid>
                </div>
                <br></br>
                {!_.isEmpty(jsonVocDataBilling) ? (
                  <div className="col-md-8">
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        "& > :not(style)": {
                          m: 0.5,
                          width: 245,
                          height: 120,
                        },
                      }}
                    >
                      <Paper
                        className="aupage"
                        style={{
                          marginLeft: "-10px",
                          marginTop: "5px",
                        }}
                      >
                        <b>Total Duration(in Hours) </b>
                        <br></br>
                        <br></br>
                        <ul
                          style={{
                            paddingLeft: "10px",
                            textAlign: "center",
                            fontSize: "18px",
                            fontWeight: "500",
                          }}
                        >
                          {jsonVocDataBilling &&
                            jsonVocDataBilling.total_duration_hours}
                        </ul>
                      </Paper>
                      <Paper className="aupage">
                        <b>Total Duration in Seconds</b>
                        <br></br>
                        <br></br>
                        <ul
                          style={{
                            paddingLeft: "10px",
                            textAlign: "center",
                            fontSize: "18px",
                            fontWeight: "500",
                          }}
                        >
                          {jsonVocDataBilling &&
                            jsonVocDataBilling.total_duration_in_sec}
                        </ul>
                      </Paper>
                      <Paper className="aupage">
                        <b>Call Count</b>
                        <br></br>
                        <br></br>
                        <ul
                          style={{
                            paddingLeft: "10px",
                            textAlign: "center",
                            fontSize: "18px",
                            fontWeight: "500",
                          }}
                        >
                          {jsonVocDataBilling && jsonVocDataBilling.call_count}
                        </ul>
                      </Paper>
                    </Box>
                  </div>
                ) : (
                  <div className="voc-no-data-found-container">
                    No Data found ...
                  </div>
                )}
              </TabPanel>
            ) : null}
          </TabContext>
        </Box>
      </Grid>
    </Container>
  );
};
export default HdbfsV2DashBoard;
