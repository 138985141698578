import "./../App_Speech.css";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  PieController,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  BarElement,
  Title,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import Paper from "@mui/material/Paper";
import "react-h5-audio-player/lib/styles.css";
import "react-circular-progressbar/dist/styles.css";
import "./Toolbar.css";
import "./loader.css";
import FormControl from "@mui/material/FormControl";
import moment from "moment";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import ChartDataLabels from "chartjs-plugin-datalabels";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Input from "@mui/material/Input";

import LarsentoubroTranscriptTable from "./LarsenTranscript";
import * as _ from "lodash";
import { Pie } from "react-chartjs-2";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  BarElement,
  Title,
  PieController
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const languageCodeMap = {
  "en-IN": "English",
  "ta-IN": "Tamil",
  "ml-IN": "Malayalam",
  "mr-IN": "Marathi",
  "hi-IN": "Hindi",
  "te-IN": "Telugu",
  "kn-IN": "Kannada",
  "gu-IN": "Gujarati",
  // Add more language codes and names as needed
};
const langVariant = [
  { label: "English", value: "en-IN" },
  { label: "Tamil", value: "ta-IN" },
  { label: "Malayalam", value: "ml-IN" },
  { label: "Marathi", value: "mr-IN" },
  { label: "Hindi", value: "hi-IN" },
  { label: "Telugu", value: "te-IN" },
  { label: "Kannada", value: "kn-IN" },
  { label: "Gujarati", value: "gu-IN" },
];

let variants = [
  {
    id: 1,
    label: "Positive",
    value: "positive",
  },
  {
    id: 2,
    label: "Negative",
    value: "negative",
  },
  {
    id: 3,
    label: "Neutral",
    value: "neutral",
  },
];
const scores = ["None", ">", "<"];
const VOCTranscriptUrl = `${process.env.REACT_APP_BASE_URL}/larsentoubro/vocTranscriptReport`;
const vocDataURL = `${process.env.REACT_APP_BASE_URL}/larsentoubro/getVOCData`;
const vocWordCluster = `${process.env.REACT_APP_BASE_URL}/api/wordCloud`;
const vocTranscriptFilter = `${process.env.REACT_APP_BASE_URL}/larsentoubro/vocDashBoardReportFilters`;

const axios = require("axios");
axios.defaults.headers.common["user"] = localStorage.getItem("user");
var objFilter = {};
export function logoutUser(ele) {
  localStorage.clear();
  sessionStorage.clear();
  const temp = window;
  temp.location = "/#/login";
}

const LarsentoubroDashboard = () => {
  const [dashboardVocData, setDashboardVocData] = useState({});
  const [wordClusterData, setWordClusterData] = useState(null);
  const [vocAggrObj, setVocAggrObj] = useState({});
  const [load, setIsload] = useState(true);

  const [value, setValue] = useState("1");

  const [jsonVocDataTranscript, setJsonVocDataTranscript] = useState({});
  const [vocTopicFilter, setVocTopicFilter] = useState([]);
  const [vocCallTypeFilter, setVocCallTypeFilter] = useState([]);
  const [vocCampaignFilter, setVocCampaignFilter] = useState([]);
  const [vocAgentFilter, setVocAgentFilter] = useState([]);
  const [vocSkillFilter, setVocSkillFilter] = useState([]);
  const [vocDispositionFilter, setVocDispositionFilter] = useState([]);

  const [selectedTrxTopicFilter, setSelectedTrxTopicFilter] = useState([]);
  const [selectedTrxSkillFilter, setSelectedTrxSkillFilter] = useState([]);
  const [selectedTrxAgentFilter, setSelectedTrxAgentFilter] = useState([]);
  const [selectedTrxDispositionFilter, setSelectedTrxDispositionFilter] =
    useState([]);

  const [selectedTrxCallTypeFilter, setSelectedTrxCallTypeFilter] = useState(
    []
  );
  const [selectedTrxCampaignFilter, setSelectedTrxCampaignFilter] = useState(
    []
  );
  const [selectedTrxSentimentFilter, setSelectedTrxSentimentFilter] = useState(
    []
  );
  const [selectedSkillFilter, setSelectedSkillFilter] = useState([]);
  const [selectedTopicFilter, setSelectedTopicFilter] = useState([]);
  const [selectedSentimentFilter, setSelectedSentimentFilter] = useState([]);
  const [selectedAgentFilter, setSelectedAgentFilter] = useState([]);
  const [selectedDispositionFilter, setSelectedDispositionFilter] = useState(
    []
  );
  const [selectedCampaignFilter, setSelectedCampaignFilter] = useState([]);
  const [vocFromDate, setVocFromDate] = useState(moment().format("YYYY-MM-DD"));
  const [vocToDate, setVocTODate] = useState(moment().format("YYYY-MM-DD"));
  const [ucidVal, setUcidVal] = useState([]);

  const handleFilters = (event) => {
    const {
      target: { value },
    } = event;
    let map = {};
    for (let list of value) {
      map[Object.values(list).join("")] = list;
    }
    let duplicateRemoved = [];
    value.forEach((item) => {
      if (duplicateRemoved.findIndex((o) => o.id === item.id) > 0) {
        duplicateRemoved = duplicateRemoved.filter((x) => x.id === item.id);
      } else {
        duplicateRemoved.push(item);
      }
    });
    return duplicateRemoved;
  };

  const handleVocDates = (e, name) => {
    if (name === "startDate") {
      setVocFromDate(String(moment(e).format("YYYY-MM-DD")));
    } else {
      setVocTODate(String(moment(e).format("YYYY-MM-DD")));
    }
  };

  const handleVOCfilter = (e, name) => {
    if (name === "topic") {
      setSelectedTopicFilter(e.target.value);
    } else if (name === "campaign") {
      setSelectedCampaignFilter(e.target.value);
    } else if (name === "skill") {
      setSelectedSkillFilter(e.target.value);
    } else if (name === "agent") {
      setSelectedAgentFilter(e.target.value);
    } else if (name === "disposition") {
      setSelectedDispositionFilter(e.target.value);
    } else {
      setSelectedSentimentFilter(e.target.value);
    }
  };

  const handleVOCTranscriptFilter = (e, name) => {
    if (name === "topic") {
      setSelectedTrxTopicFilter(e.target.value);
    } else if (name === "sentiment") {
      setSelectedTrxSentimentFilter(e.target.value);
    } else if (name === "skill") {
      setSelectedTrxSkillFilter(e.target.value);
    } else if (name === "call-type") {
      setSelectedTrxCallTypeFilter(e.target.value);
    } else if (name === "campaign") {
      setSelectedTrxCampaignFilter(e.target.value);
    } else if (name === "agent") {
      setSelectedTrxAgentFilter(e.target.value);
    } else if (name === "disposition") {
      setSelectedTrxDispositionFilter(e.target.value);
    }
  };

  const handleVOCUcidFilter = (e) => {
    const regex = /^[0-9]*$/;
    if (regex.test(e.target.value) || e.target.value === "") {
      setUcidVal(e.target.value);
    }
  };

  if (window.localStorage.getItem("user") === "larsentoubro") {
    useEffect(() => {
      vocdownloadFilter();
    }, []);
  }

  const vocdownloadFilter = async () => {
    try {
      const filterResponse = await axios.get(vocTranscriptFilter);
      if (!_.isEmpty(filterResponse)) {
        let formatedTopics = convertToSelectBoxFormat(
          filterResponse.data.aggregations.topics
        );
        if (!_.isEmpty(formatedTopics)) {
          setVocTopicFilter(formatedTopics);
        }
        let formatedCallTypes = convertToSelectBoxFormat(
          filterResponse.data.aggregations.call_types
        );
        if (!_.isEmpty(formatedCallTypes)) {
          setVocCallTypeFilter(formatedCallTypes);
        }
        let formatedCampaigns = convertToSelectBoxFormat(
          filterResponse.data.aggregations.campaigns
        );
        if (!_.isEmpty(formatedCampaigns)) {
          setVocCampaignFilter(formatedCampaigns);
        }
        let formatedSkills = convertToSelectBoxFormat(
          filterResponse.data.aggregations.skill
        );
        if (!_.isEmpty(formatedSkills)) {
          setVocSkillFilter(formatedSkills);
        }
        let formatedAgents = convertToSelectBoxFormat(
          filterResponse.data.aggregations.agent
        );
        if (!_.isEmpty(formatedAgents)) {
          setVocAgentFilter(formatedAgents);
        }
        let formatedDispositions = convertToSelectBoxFormat(
          filterResponse.data.aggregations.disposition
        );
        if (!_.isEmpty(formatedDispositions)) {
          setVocDispositionFilter(formatedDispositions);
        }
      }
    } catch (err) {
      setIsload(false);
      window.alert("Error Calling Api");
    }
  };
  let stopWordsArr = ["summary", "not", "available"];
  const getWordCloudImg = async (sentences) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        sentences,
        width: "1150",
        height: "525",
        background_color: "white",
        stopwords: stopWordsArr,
        // min_font_size: 20,
      }),
      redirect: "follow",
    };

    await fetch(vocWordCluster, requestOptions)
      .then((response) => response.blob())
      .then((result) => {
        console.log(result);
        setWordClusterData(URL.createObjectURL(result));
      })
      .catch((error) => {
        setIsload(false);
        window.alert("Error Calling Api");
        console.error("Error:", error);
      });
  };

  const fetchVocData = async (
    vocFromDate,
    vocToDate,
    selectedTopicFilter,
    selectedSentimentFilter,
    selectedSkillFilter,
    selectedCampaignFilter,
    selectedAgentFilter,
    selectedDispositionFilter,
    size,
    from
  ) => {
    try {
      setIsload(!load);
      if (vocFromDate) {
        const response = await axios.get(vocDataURL, {
          params: {
            startDate: String(vocFromDate),
            endDate: String(vocToDate),
            campaignVal: String(selectedCampaignFilter),
            agentVal: String(selectedAgentFilter),
            dispositionVal: String(selectedDispositionFilter),
            skillVal: String(selectedSkillFilter),
            topicVal: String(selectedTopicFilter),
            sentimentVal: String(selectedSentimentFilter),
            size: size ? size : 10000,
            from: from ? from : 0,
          },
        });
        if (response) {
          setVocAggrObj(
            response &&
              response.data &&
              response.data.Results &&
              response.data.Results.Aggregations &&
              response.data.Results.Aggregations
          );

          let data = [];
          response &&
            response.data &&
            response.data.Results &&
            response.data.Results.WordCloudData &&
            response.data.Results.WordCloudData.map(
              (item) => item && item.summary && data.push(item.summary)
            );
          const wordCloudSentences = data;
          if (!_.isEmpty(wordCloudSentences)) {
            getWordCloudImg(wordCloudSentences);
          }
          console.log(vocAggrObj);
          if (!_.isEmpty(vocAggrObj)) {
            setDashboardVocData(getVOCGraphData());
          }
          setIsload(false);
        }
      } else {
        const response = await axios.get(vocDataURL);
        if (response) {
          setVocAggrObj(
            response &&
              response.data &&
              response.data.Results &&
              response.data.Results.Aggregations &&
              response.data.Results.Aggregations
          );

          let data = [];
          response &&
            response.data &&
            response.data.Results &&
            response.data.Results.WordCloudData &&
            response.data.Results.WordCloudData.map(
              (item) => item && item.summary && data.push(item.summary)
            );
          const wordCloudSentences = data;
          console.log(wordCloudSentences);
          if (!_.isEmpty(wordCloudSentences)) {
            getWordCloudImg(wordCloudSentences);
          }
          if (!_.isEmpty(vocAggrObj)) {
            setDashboardVocData(getVOCGraphData());
          }
          setIsload(false);
        }
      }
    } catch (error) {
      setIsload(false);
      console.log(error);
      window.alert("Error Calling Api");
    }
  };

  if (window.localStorage.getItem("user") === "larsentoubro") {
    useEffect(() => {
      fetchVocTranscriptData(vocFromDate);
    }, []);
  }
  const fetchVocTranscriptData = async (
    vocFromDate,
    vocToDate,
    selectedTrxTopicFilter,
    ucidVal,
    selectedTrxSentimentFilter,
    selectedTrxSkillFilter,
    selectedTrxCallTypeFilter,
    selectedTrxCampaignFilter,
    selectedTrxAgentFilter,
    selectedTrxDispositionFilter,
    size,
    from
  ) => {
    try {
      setIsload(!load);
      if (vocFromDate) {
        const response = await axios.get(VOCTranscriptUrl, {
          params: {
            startDate: String(vocFromDate),
            endDate: String(vocToDate),
            topics: String(selectedTrxTopicFilter),
            ucid: String(ucidVal),
            sentiment: String(selectedTrxSentimentFilter),
            callType: String(selectedTrxCallTypeFilter),
            disposition: String(selectedTrxDispositionFilter),
            campaign: String(selectedTrxCampaignFilter),
            skill: String(selectedTrxSkillFilter),
            agent: String(selectedTrxAgentFilter),
            from: from ? from : 0,
            size: size ? size : 20,
          },
        });
        if (response) {
          setJsonVocDataTranscript(response.data);
          setIsload(false);
        }
      } else {
        const response = await axios.get(VOCTranscriptUrl);
        if (response) {
          setJsonVocDataTranscript(response.data);
          setIsload(false);
        }
      }
    } catch (error) {
      setIsload(false);
      console.log(error);
      window.alert("Error Calling Api");
    }
  };
  if (window.localStorage.getItem("user") === "larsentoubro") {
    useEffect(() => {}, [jsonVocDataTranscript]);
  }
  const fetchVocTranscriptDataCallBack = async (
    vocFromDate,
    vocToDate,
    selectedTrxTopicFilter,
    ucidVal,
    selectedTrxSentimentFilter,
    selectedTrxSkillFilter,
    selectedTrxCallTypeFilter,
    selectedTrxCampaignFilter,
    selectedTrxAgentFilter,
    selectedTrxDispositionFilter,
    size,
    from
  ) => {
    try {
      setIsload(!load);
      if (vocFromDate) {
        const response = await axios.get(VOCTranscriptUrl, {
          params: {
            startDate: String(vocFromDate),
            endDate: String(vocToDate),
            topics: String(selectedTrxTopicFilter),
            ucid: String(ucidVal),
            sentiment: String(selectedTrxSentimentFilter),
            callType: String(selectedTrxCallTypeFilter),
            disposition: String(selectedTrxDispositionFilter),
            campaign: String(selectedTrxCampaignFilter),
            skill: String(selectedTrxSkillFilter),
            agent: String(selectedTrxAgentFilter),
            from: from ? from : 0,
            size: size ? size : 20,
          },
        });
        if (response) {
          setJsonVocDataTranscript(response.data);
          setIsload(false);
        }
      } else {
        const response = await axios.get(VOCTranscriptUrl);
        if (response) {
          setJsonVocDataTranscript(response.data);
          setIsload(false);
        }
      }
    } catch (error) {
      window.alert("Error Calling Api");
      setIsload(false);
      console.log(error);
    }
  };

  const convertToSelectBoxFormat = (arrayElement) => {
    if (arrayElement) {
      arrayElement.map((ele) => {
        ele.label = ele.doc_count;
        ele.value = ele.key;
        delete ele.doc_count;
        delete ele.key;
      });
    }
    return arrayElement;
  };

  if (window.localStorage.getItem("user") === "larsentoubro") {
    useEffect(() => {
      if (!_.isEmpty(vocAggrObj)) {
        setDashboardVocData(getVOCGraphData());
      }
    }, [vocAggrObj]);
  }

  const findFilters = (name) => {
    for (const element of objFilter) {
      // console.log("Element filter is",element)
      if (element["name"] === name) {
        return element;
      }
    }
  };
  const findAggr = (name) => {
    for (const element of vocAggrObj) {
      // console.log("Element filter is",element)
      if (element["name"] === name) {
        return element;
      }
    }
  };
  function getLanguageNames(languageCodes) {
    return languageCodes.map(
      (code) => languageCodeMap[code] || "Unknown Language"
    );
  }

  const getVOCGraphData = () => {
    const topicAggregation = findAggr("Topics Count", vocAggrObj);
    let topicAggregationLabelPer = [];
    let topicAggregationDataPer = [];
    if (!_.isEmpty(topicAggregation)) {
      topicAggregation["labels"].forEach((element, index) => {
        const label = element;
        const value = topicAggregation["data"][index].toFixed(1);
        topicAggregationLabelPer.push(label);
        topicAggregationDataPer.push(value);
      });
    }

    const sentimentAggregation = findAggr("Sentiment Count", vocAggrObj);
    let sentimentAggregationLabelPer = [];
    let sentimentAggregationDataPer = [];
    if (!_.isEmpty(sentimentAggregation)) {
      sentimentAggregation["labels"].forEach((element, index) => {
        const label = element;
        const value = sentimentAggregation["data"][index].toFixed(1);
        sentimentAggregationLabelPer.push(label);
        sentimentAggregationDataPer.push(value);
      });
    }

    const topicScoreOptions = {
      plugins: {
        datalabels: {
          display: true,
          color: "black",
          formatter: function (value) {
            return value;
          },
        },
        title: {
          display: true,
          text: "Topic Distribution",
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
          ticks: {
            callback: function (value) {
              return this.getLabelForValue(value).length > 15
                ? this.getLabelForValue(value).substr(0, 15) + "..."
                : this.getLabelForValue(value);
            },
          },
        },
        y: {
          stacked: true,
        },
      },
    };

    const topicScoreData = {
      datasets: [
        {
          data: topicAggregationDataPer.slice(0, 12),
          backgroundColor: "#90ED7D",
          label: "Topic Count",
          stack: 1,
        },
      ],
      labels: topicAggregationLabelPer.slice(0, 12),
    };

    const vocPieOptions = {
      responsive: true,
      plugins: {
        title: {
          display: true,
          position: "top",
          align: true,
          text: "Sentiment Analysis",
        },
        legend: {
          symbolWidth: "4px",
          symbolHeight: "5px",
          display: true,
          labels: {
            color: "rgb(255, 99, 132)",
          },
          position: "right",
          align: true,
        },
        tooltip: {
          callbacks: {
            label: function (tooltipItem) {
              const label = tooltipItem.label;
              const value = tooltipItem.dataset.data[tooltipItem.dataIndex];
              return `${label} :: ${value}`;
            },
          },
        },
        datalabels: {
          display: true,
        },
      },
    };

    const vocPieChartData = {
      labels: sentimentAggregationLabelPer,
      datasets: [
        {
          label: "# of calls",
          data: sentimentAggregationDataPer,
          backgroundColor: [
            "#1876D1",
            "#9AEAA7",
            "#ECC1C9",
            "#FFBF69",
            "#CCD6EB",
          ], // Customize the colors
          hoverBackgroundColor: ["#1876D1", "#ECC1C9", "#FFBF69"], // Customize the hover colors
          hoverOffset: 4,
        },
      ],
    };

    return {
      vocPieOptions: vocPieOptions,
      topicScoreOptions: topicScoreOptions,
      topicScoreData: topicScoreData,
      vocPieChartData: vocPieChartData,
    };
  };

  const getIntentData = (intent) => {
    let intentArray = [];
    for (const element of intent) {
      intentArray.push({ label: element, value: element });
    }
    return intentArray;
  };

  const selectionList = (arrOfObj) => {
    let selectionString = "";
    let selectionArr = [];
    if (arrOfObj !== undefined && arrOfObj.length > 0) {
      for (const ele of arrOfObj) {
        selectionArr.push(ele["value"]);
      }
      selectionString = String(selectionArr);
    } else {
      selectionString = "0";
    }
    return selectionString;
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function transformArray(arr) {
    if (arr.length !== 0) {
      return arr.map((item) => {
        // Split the string by underscores
        const words = item.split("_");
        // Capitalize the first letter of each word
        const capitalizedWords = words.map((word) => {
          return word.charAt(0).toUpperCase() + word.slice(1);
        });
        // Join the words with spaces and return the transformed string
        return capitalizedWords && capitalizedWords.join(" ");
      });
    } else {
      return [];
    }
  }

  function replaceLanguageNames(inputArray) {
    // Create an output array by mapping over the input array
    if (
      _.isArray(inputArray) &&
      !_.isEmpty(inputArray) &&
      !_.isNull(inputArray) &&
      !_.isUndefined(inputArray)
    ) {
      const outputArray = inputArray
        .map((inputItem) => {
          const matchingLang = langVariant.find(
            (langItem) => langItem.label === inputItem.value
          );
          if (matchingLang) {
            return { label: matchingLang.value, value: matchingLang.value };
          } else {
            return null; // Handle cases where no match is found
          }
        })
        .filter((item) => item !== null);
      return outputArray;
    } else {
      return [];
    }
  }

  return (
    <Container maxWidth="xl">
      <Grid container spacing={2}>
        <Grid item xs={10}>
          <div className="logo-container">
            <h1>Conversational Intelligence</h1>
          </div>
        </Grid>
        <Grid item xs={2}>
          <span className="loggedInuser font-user">USER:</span>
          <span className="font-user">
            {" "}
            {window.localStorage.getItem("user")} |{" "}
          </span>
          <span className="logout-title font-user" onClick={logoutUser}>
            Logout
          </span>
        </Grid>
        <Box
          sx={{
            width: "100%",
            typography: "body1",
            pointerEvents: load ? "none" : "auto",
          }}
          className={load ? "hidden-cus" : ""}
        >
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                {window.localStorage.getItem("user") === "larsentoubro" ? (
                  <Tab label="VOC" value="1" />
                ) : null}
                {window.localStorage.getItem("user") === "larsentoubro" ? (
                  <Tab label="Transcripts" value="2" />
                ) : null}
              </TabList>
            </Box>

            {window.localStorage.getItem("user") !== "mindtree" &&
            window.localStorage.getItem("user") === "larsentoubro" ? (
              <TabPanel
                value="1"
                style={{ pointerEvents: load ? "none" : "auto" }}
              >
                <div className="voc-filters-search-box-container">
                  <Grid container item spacing={2}>
                    {/* <Grid item xs={1.5}> */}
                    <div className="date-container">&nbsp;</div>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        renderInput={(props) => (
                          <TextField {...props} style={{ width: "124px" }} />
                        )}
                        disableFuture
                        showTodayButton
                        maxDate={moment().toDate()}
                        todayText="now"
                        label="Start Date"
                        value={vocFromDate}
                        onChange={(e) => {
                          handleVocDates(e, "startDate");
                        }}
                      />
                    </LocalizationProvider>
                    <div className="date-container">&nbsp;</div>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        renderInput={(props) => (
                          <TextField {...props} style={{ width: "124px" }} />
                        )}
                        disableFuture
                        showTodayButton
                        maxDate={moment().toDate()}
                        todayText="now"
                        label="End Date"
                        value={vocToDate}
                        onChange={(e) => {
                          handleVocDates(e, "endDate");
                        }}
                      />
                    </LocalizationProvider>
                    <div className="date-container">&nbsp;</div>
                    <FormControl style={{ width: "125px" }}>
                      <InputLabel id="demo-multiple-checkbox">
                        Topics
                      </InputLabel>
                      <Select
                        value={selectedTopicFilter}
                        onChange={(e) => {
                          handleVOCfilter(e, "topic");
                        }}
                        labelId="demo-multiple-checkbox"
                        id="demo-multiple-checkbox"
                        multiple
                        input={<OutlinedInput label="Topic" />}
                        renderValue={(selected) => selected.join(", ")}
                      >
                        {vocTopicFilter.map((option) => (
                          <MenuItem key={option.id} value={option.value}>
                            <Checkbox
                              checked={selectedTopicFilter.includes(
                                option.value
                              )}
                            />
                            <ListItemText primary={option.value} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <div className="date-container">&nbsp;</div>
                    <FormControl style={{ width: "125px" }}>
                      <InputLabel id="demo-multiple-agent">Skills</InputLabel>
                      <Select
                        value={selectedSkillFilter}
                        onChange={(e) => {
                          handleVOCfilter(e, "skill");
                        }}
                        labelId="demo-multiple-agent"
                        id="demo-multiple-checkbox"
                        multiple
                        input={<OutlinedInput label="Skill" />}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                      >
                        {vocSkillFilter.map((value) => (
                          <MenuItem key={value.id} value={value.value}>
                            <Checkbox
                              checked={selectedSkillFilter.includes(
                                value.value
                              )}
                            />
                            <ListItemText primary={value.value} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <div className="date-container">&nbsp;</div>
                    <FormControl style={{ width: "125px" }}>
                      <InputLabel id="demo-multiple-agent">Agents</InputLabel>
                      <Select
                        value={selectedAgentFilter}
                        onChange={(e) => {
                          handleVOCfilter(e, "agent");
                        }}
                        labelId="demo-multiple-agent"
                        id="demo-multiple-checkbox"
                        multiple
                        input={<OutlinedInput label="Agent" />}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                      >
                        {vocAgentFilter.map((value) => (
                          <MenuItem key={value.id} value={value.value}>
                            <Checkbox
                              checked={selectedAgentFilter.includes(
                                value.value
                              )}
                            />
                            <ListItemText primary={value.value} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <div className="date-container">&nbsp;</div>
                    <FormControl style={{ width: "125px" }}>
                      <InputLabel id="demo-multiple-agent">
                        Dispositions
                      </InputLabel>
                      <Select
                        value={selectedDispositionFilter}
                        onChange={(e) => {
                          handleVOCfilter(e, "disposition");
                        }}
                        labelId="demo-multiple-agent"
                        id="demo-multiple-checkbox"
                        multiple
                        input={<OutlinedInput label="Disposition" />}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                      >
                        {vocDispositionFilter.map((value) => (
                          <MenuItem key={value.id} value={value.value}>
                            <Checkbox
                              checked={selectedDispositionFilter.includes(
                                value.value
                              )}
                            />
                            <ListItemText primary={value.value} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <div className="date-container">&nbsp;</div>
                    <FormControl style={{ width: "125px" }}>
                      <InputLabel id="demo-multiple-checkbox">
                        Campaign
                      </InputLabel>
                      <Select
                        value={selectedCampaignFilter}
                        onChange={(e) => {
                          handleVOCfilter(e, "campaign");
                        }}
                        labelId="demo-multiple-checkbox"
                        id="demo-multiple-checkbox"
                        multiple
                        input={<OutlinedInput label="Campaign" />}
                        renderValue={(selected) => selected.join(", ")}
                      >
                        {vocCampaignFilter.map((option) => (
                          <MenuItem key={option.id} value={option.value}>
                            <Checkbox
                              checked={selectedCampaignFilter.includes(
                                option.value
                              )}
                            />
                            <ListItemText primary={option.value} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <div className="date-container">&nbsp;</div>
                    <FormControl style={{ width: "125px" }}>
                      <InputLabel id="demo-multiple-agent">
                        Sentiment
                      </InputLabel>
                      <Select
                        value={selectedSentimentFilter}
                        onChange={(e) => {
                          handleVOCfilter(e, "sentiment");
                        }}
                        labelId="demo-multiple-agent"
                        id="demo-multiple-checkbox"
                        multiple
                        input={<OutlinedInput label="Agent" />}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                      >
                        {variants.map((value) => (
                          <MenuItem key={value.id} value={value.value}>
                            <Checkbox
                              checked={selectedSentimentFilter.includes(
                                value.value
                              )}
                            />
                            <ListItemText primary={value.value} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <div className="date-container">&nbsp;</div>
                    <Button
                      variant="contained"
                      style={{ padding: "12px 10px", width: "90px" }}
                      onClick={() => {
                        fetchVocData(
                          vocFromDate,
                          vocToDate,
                          selectedTopicFilter,
                          selectedSentimentFilter,
                          selectedSkillFilter,
                          selectedCampaignFilter,
                          selectedAgentFilter,
                          selectedDispositionFilter
                        );
                      }}
                    >
                      Search
                    </Button>
                  </Grid>
                </div>
                <br></br>
                <Grid item xs={1}></Grid>
                {Object.keys(dashboardVocData).length !== 0 ? (
                  <Grid container spacing={3}>
                    <Grid container item spacing={3}>
                      <Grid item xs={12}>
                        <Paper elevation={3}>
                          <div className="voc-bar-graph">
                            <label className="voc-graphs-header-names">
                              Topic Overview Distribution
                            </label>
                            {Object.keys(dashboardVocData).length !== 0 && (
                              <Bar
                                options={dashboardVocData["topicScoreOptions"]}
                                data={dashboardVocData["topicScoreData"]}
                                plugins={[ChartDataLabels]}
                              />
                            )}
                          </div>
                        </Paper>
                      </Grid>
                    </Grid>

                    <Grid item xs={6}>
                      <Paper
                        elevation={3}
                        sx={{
                          width: "520px",
                          height: "400px",
                        }}
                      >
                        <div
                          style={{
                            marginLeft: "30px",
                            width: "410px",
                          }}
                        >
                          {Object.keys(dashboardVocData).length !== 0 && (
                            <Pie
                              options={dashboardVocData["vocPieOptions"]}
                              data={dashboardVocData["vocPieChartData"]}
                              plugins={[ChartDataLabels]}
                            />
                          )}
                        </div>
                      </Paper>
                    </Grid>

                    <Grid container item spacing={3}>
                      <Grid item xs={12}>
                        <div className="voc-word-cloud-container">
                          <Paper>
                            <label className="voc-graphs-header-names">
                              Word Cloud over Summary
                            </label>
                            <img
                              src={wordClusterData}
                              className="word-cloud-properties"
                              alt="logo"
                            />
                          </Paper>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <div className="voc-no-data-found-container">
                    No Data found ...
                  </div>
                )}
              </TabPanel>
            ) : null}

            {window.localStorage.getItem("user") !== "mindtree" &&
            window.localStorage.getItem("user") === "larsentoubro" ? (
              <TabPanel
                value="2"
                style={{ pointerEvents: load ? "none" : "auto" }}
              >
                <div className="voc-filters-search-box-container">
                  <Grid container item spacing={2}>
                    {/* <Grid item xs={1.5}> */}
                    <div className="date-container">&nbsp;</div>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        renderInput={(props) => (
                          <TextField {...props} style={{ width: "124px" }} />
                        )}
                        disableFuture
                        showTodayButton
                        maxDate={moment().toDate()}
                        todayText="now"
                        label="Start Date"
                        value={vocFromDate}
                        onChange={(e) => {
                          handleVocDates(e, "startDate");
                        }}
                      />
                    </LocalizationProvider>
                    <div className="date-container">&nbsp;</div>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        renderInput={(props) => (
                          <TextField {...props} style={{ width: "124px" }} />
                        )}
                        disableFuture
                        showTodayButton
                        maxDate={moment().toDate()}
                        todayText="now"
                        label="Start Date Time"
                        value={vocToDate}
                        onChange={(e) => {
                          handleVocDates(e, "endDate");
                        }}
                      />
                    </LocalizationProvider>
                    <div className="date-container">&nbsp;</div>
                    <FormControl style={{ width: "125px" }}>
                      <InputLabel id="demo-multiple-checkbox">
                        Topics
                      </InputLabel>
                      <Select
                        value={selectedTrxTopicFilter}
                        onChange={(e) => {
                          handleVOCTranscriptFilter(e, "topic");
                        }}
                        labelId="demo-multiple-checkbox"
                        id="demo-multiple-checkbox"
                        multiple
                        input={<OutlinedInput label="Topic" />}
                        renderValue={(selected) => selected.join(", ")}
                      >
                        {vocTopicFilter.map((option) => (
                          <MenuItem key={option.id} value={option.value}>
                            <Checkbox
                              checked={selectedTrxTopicFilter.includes(
                                option.value
                              )}
                            />
                            <ListItemText primary={option.value} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <div className="date-container">&nbsp;</div>
                    <FormControl style={{ width: "125px" }}>
                      <InputLabel id="demo-multiple-checkbox">
                        Sentiment
                      </InputLabel>
                      <Select
                        value={selectedTrxSentimentFilter}
                        onChange={(e) => {
                          handleVOCTranscriptFilter(e, "sentiment");
                        }}
                        labelId="demo-multiple-checkbox"
                        id="demo-multiple-checkbox"
                        multiple
                        input={<OutlinedInput label="Sentiment" />}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                      >
                        {variants.map((value) => (
                          <MenuItem key={value.id} value={value.value}>
                            <Checkbox
                              checked={selectedTrxSentimentFilter.includes(
                                value.value
                              )}
                            />
                            <ListItemText primary={value.value} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <div className="date-container">&nbsp;</div>
                    <FormControl style={{ width: "125px" }}>
                      <InputLabel id="demo-multiple-checkbox">
                        CallTypes
                      </InputLabel>
                      <Select
                        value={selectedTrxCallTypeFilter}
                        onChange={(e) => {
                          handleVOCTranscriptFilter(e, "call-type");
                        }}
                        labelId="demo-multiple-checkbox"
                        id="demo-multiple-checkbox"
                        multiple
                        input={<OutlinedInput label="CallType" />}
                        renderValue={(selected) => selected.join(", ")}
                      >
                        {vocCallTypeFilter.map((option) => (
                          <MenuItem key={option.id} value={option.value}>
                            <Checkbox
                              checked={selectedTrxCallTypeFilter.includes(
                                option.value
                              )}
                            />
                            <ListItemText primary={option.value} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <div className="date-container">&nbsp;</div>
                    <FormControl style={{ width: "125px" }}>
                      <InputLabel id="demo-multiple-checkbox">
                        Skills
                      </InputLabel>
                      <Select
                        value={selectedTrxSkillFilter}
                        onChange={(e) => {
                          handleVOCTranscriptFilter(e, "skill");
                        }}
                        labelId="demo-multiple-checkbox"
                        id="demo-multiple-checkbox"
                        multiple
                        input={<OutlinedInput label="Skill" />}
                        renderValue={(selected) => selected.join(", ")}
                      >
                        {vocSkillFilter.map((option) => (
                          <MenuItem key={option.id} value={option.value}>
                            <Checkbox
                              checked={selectedTrxSkillFilter.includes(
                                option.value
                              )}
                            />
                            <ListItemText primary={option.value} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <div className="date-container">&nbsp;</div>
                    <FormControl style={{ width: "125px" }}>
                      <InputLabel id="demo-multiple-checkbox">
                        Dispositions
                      </InputLabel>
                      <Select
                        value={selectedTrxDispositionFilter}
                        onChange={(e) => {
                          handleVOCTranscriptFilter(e, "disposition");
                        }}
                        labelId="demo-multiple-checkbox"
                        id="demo-multiple-checkbox"
                        multiple
                        input={<OutlinedInput label="Disposition" />}
                        renderValue={(selected) => selected.join(", ")}
                      >
                        {vocDispositionFilter.map((option) => (
                          <MenuItem key={option.id} value={option.value}>
                            <Checkbox
                              checked={selectedTrxDispositionFilter.includes(
                                option.value
                              )}
                            />
                            <ListItemText primary={option.value} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <div className="date-container">&nbsp;</div>
                    <FormControl style={{ width: "125px" }}>
                      <InputLabel id="demo-multiple-checkbox">
                        Agents
                      </InputLabel>
                      <Select
                        value={selectedTrxAgentFilter}
                        onChange={(e) => {
                          handleVOCTranscriptFilter(e, "agent");
                        }}
                        labelId="demo-multiple-checkbox"
                        id="demo-multiple-checkbox"
                        multiple
                        input={<OutlinedInput label="Agent" />}
                        renderValue={(selected) => selected.join(", ")}
                      >
                        {vocAgentFilter.map((option) => (
                          <MenuItem key={option.id} value={option.value}>
                            <Checkbox
                              checked={selectedTrxAgentFilter.includes(
                                option.value
                              )}
                            />
                            <ListItemText primary={option.value} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <div className="date-container">&nbsp;</div>
                    <FormControl style={{ width: "125px" }}>
                      <InputLabel id="demo-multiple-checkbox">
                        Campaigns
                      </InputLabel>
                      <Select
                        value={selectedTrxCampaignFilter}
                        onChange={(e) => {
                          handleVOCTranscriptFilter(e, "campaign");
                        }}
                        labelId="demo-multiple-checkbox"
                        id="demo-multiple-checkbox"
                        multiple
                        input={<OutlinedInput label="Campaign" />}
                        renderValue={(selected) => selected.join(", ")}
                      >
                        {vocCampaignFilter.map((option) => (
                          <MenuItem key={option.id} value={option.value}>
                            <Checkbox
                              checked={selectedTrxCampaignFilter.includes(
                                option.value
                              )}
                            />
                            <ListItemText primary={option.value} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <div className="date-container">&nbsp;</div>
                    <FormControl style={{ width: "125px" }}>
                      <div>
                        <Box
                          component="form"
                          sx={{
                            "& > :not(style)": { m: 1, width: 98 },
                          }}
                          noValidate
                          autoComplete="off"
                        >
                          <Input
                            style={{ fontSize: "0.8rem" }}
                            placeholder="Enter UCID"
                            value={ucidVal}
                            onChange={handleVOCUcidFilter}
                            inputProps={{ pattern: "^[0-9]*$" }} // Only allow numbers
                          />
                        </Box>
                      </div>
                    </FormControl>
                    <div className="date-container">&nbsp;</div>
                    <FormControl>
                      <Button
                        variant="contained"
                        style={{ padding: "12px 10px", width: "90px" }}
                        onClick={() => {
                          fetchVocTranscriptData(
                            vocFromDate,
                            vocToDate,
                            selectedTrxTopicFilter,
                            ucidVal,
                            selectedTrxSentimentFilter,
                            selectedTrxSkillFilter,
                            selectedTrxCallTypeFilter,
                            selectedTrxCampaignFilter,
                            selectedTrxAgentFilter,
                            selectedTrxDispositionFilter
                          );
                        }}
                      >
                        Search
                      </Button>
                    </FormControl>
                    {load ? (
                      <div className="loader_digital" id="loader_digital"></div>
                    ) : null}
                    <Grid item xs={12}>
                      <LarsentoubroTranscriptTable
                        fetchVocTranscriptData={jsonVocDataTranscript}
                        fetchVOCTranscriptFunction={
                          fetchVocTranscriptDataCallBack
                        }
                        fetchData={{
                          fetchVocTranscriptDataCallBack,
                          startDate: String(vocFromDate),
                          endDate: String(vocToDate),
                          topics: String(selectedTrxTopicFilter),
                          ucid: String(ucidVal),
                          sentiment: String(selectedTrxSentimentFilter),
                          callType: String(selectedTrxCallTypeFilter),
                          disposition: String(selectedTrxDispositionFilter),
                          campaign: String(selectedTrxCampaignFilter),
                          skill: String(selectedTrxSkillFilter),
                          agent: String(selectedTrxAgentFilter),
                        }}
                      ></LarsentoubroTranscriptTable>
                    </Grid>
                  </Grid>
                </div>
              </TabPanel>
            ) : null}
          </TabContext>
        </Box>
      </Grid>
    </Container>
  );
};
export default LarsentoubroDashboard;
