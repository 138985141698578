import { any } from "prop-types";
import React, { Component } from "react";

export default class ErrorBoundary extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      hasError: false,
      error: any,
      errorInfo: any,
    };
  }
  static getDerivedStateFromError(error: any) {
    return { hasError: true, error };
  }
  componentDidCatch(error: any, errorInfo: any) {
    this.setState({
      hasError: true,
      error: error,
      errorInfo: errorInfo,
    });
  }
  render() {
    if (this.state.hasError) {
      return (
        <div>
          <p>We're sorry - something's went wrong.</p>
        </div>
      );
    } else {
      return this.props.children;
    }
  }
}
